import { Box, CardMedia, CardContent, Card, CardActionAream, Button, createTheme, Stack, ThemeProvider, Typography, Grid, CardActionArea } from "@mui/material";
import {useState} from 'react';

export default function GameCard({image="https://animals.sandiegozoo.org/sites/default/files/2016-11/animals_hero_lizards.jpg", header, text, completed, onClick}) {
  return (
    <Card onClick={onClick} sx={{ maxWidth: 345, backgroundColor: completed ? "pink" : "white" }}>
        <CardActionArea sx={{opacity: completed ? 0.25 : 1}}>
            <CardMedia
            component="img"
            height="140"
            image={image}
            alt={header}
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {header}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {text}
            </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
  );
}
