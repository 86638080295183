import { Box, CardMedia, CardContent, Card, CardActionAream, Button, createTheme, Stack, ThemeProvider, Typography, Grid, CardActionArea } from "@mui/material";
import React from 'react'

export default function ProgressBar ({bgcolor,progress,height}) {
	const Parentdiv = {
		height: height,
		width: '100%',
		backgroundColor: 'whitesmoke',
		borderRadius: 40,
		marginTop: 4,
	}
	
	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: bgcolor,
	    borderRadius:40,
        textAlign: 'right'
	}
	
	const progresstext = {
        padding:10,
		color: 'black',
		fontWeight: 900,
        margin: 'auto'
	}
	
	return (
        <Box sx={Parentdiv}>
            <Box sx={Childdiv}><span style={progresstext}>Loading...</span></Box>
        </Box>

	)
}

