import { Box, CardMedia, CardContent, Card, CardActionAream, Button, createTheme, Stack, Typography} from "@mui/material";
import {useState} from 'react';
import Lobby from "./Lobby.js"
import ConfettiExplosion from 'react-confetti-explosion';

export default function Trivia({change_page}) {
  const [isExploding, setIsExploding] = useState(false);
  let curr_question = 0
  let questions = [
    {"Q": "When was our first kiss?", "A": ["11/27/2021", "12/15/2021", "12/02/2021", "11/25/2021"], "C": 0},
    {
      "Q": "if you are naking iceceam and change the traditional recipe to a modified recipe with less sugar how would that affect the freezing point and the melting point respectively", 
      "A": ["The freezing point would decrease, and the melting point would increase.", "if reduced sugar, the freezing point will be higher and the melting point will be higher compared to the full sugar icecream.", "The freezing point would increase, and the melting point would decrease.", "Both the freezing point and melting point would decrease."], "C": 1},
    {"Q": "What was the first message you sent me?", "A": ["yes plz", "yeaaaa", "I love you, you are my everything", "yea lol"], "C": 3},
    {"Q": "What animal have you fucked on camera?", "A": ["Dog", "Chicken", "Pig", "Penguin"], "C": 3},
  ]
  const [question, setQuestion] = useState(<QuestionBlock text={questions[curr_question]["Q"]}/>)
  const [answers, setAnswers] = useState(questions[curr_question]["A"].map((v,i,a) => (<AnswerBlock text={v} id={i} key={i}/>)))

  function back() {
    change_page(<Lobby change_page={change_page}/>)
  }
  function QuestionBlock({text}) {
    return (
        <Card>
            <Typography variant="h3">{text}</Typography>
        </Card>
    )
  }
  function AnswerBlock({text, id}) {
    return (
        <Button variant="contained" id={id} onClick={handleAnswerPress}>
            <Typography id={id}>{text}</Typography>
        </Button>
    )
  }
  function handleAnswerPress(e) {
    console.log(e.target.id + " and " + questions[curr_question]["C"])
    if (questions[curr_question]["C"] == e.target.id) {
      curr_question += 1
      if (curr_question >= questions.length) {
        setIsExploding(true)
        setTimeout(() => {setIsExploding(false)}, 2000)
        localStorage.setItem('triviaCompleted', true)
      }
      else {
        setQuestion(<QuestionBlock text={questions[curr_question]["Q"]}/>)
        setAnswers(questions[curr_question]["A"].map((v,i,a) => (<AnswerBlock text={v} id={i} key={i}/>)))
      }
    }
    console.log(curr_question)
  }
  
  return (
    <Box justifyContent="center" justifyItems="center" sx={{ margin: "auto", maxWidth: "fit-content", mt: "10%"}}>
        <Stack spacing={2} sx={{ margin: "auto", maxWidth: "fit-content"}}>
            {question}
            {answers}
        </Stack>
        <Button variant="contained" onClick={back} sx={{mt:1}}>
          {isExploding && <ConfettiExplosion />}
          <Typography>
            Back
          </Typography>
        </Button>
    </Box>
  );
}
