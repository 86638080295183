import { Box, CardMedia, CardContent, Card, CardActionArea, Button, createTheme, Stack, Typography} from "@mui/material";
import { useState } from 'react'
import Lobby from "./Lobby.js"
import ConfettiExplosion from 'react-confetti-explosion';
import FinalChallenge from "../media/FinalChallenge.png"


export default function FinalGame({change_page}) {
  const [isExploding, setIsExploding] = useState(false);
  function back() {
    change_page(<Lobby change_page={change_page}/>)
    localStorage.setItem('finalCompleted', true)
  }

  
  return (
    <div style={{  
      width: "100%",
      height: "100%",
      backgroundImage: "url(" + FinalChallenge + ")",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      <Box alignContent="center" alignItems="center" sx={{ width: "100%", height: "100%", margin: "auto", width: "100%"}}>
        <Stack alignContent="center" alignItems="center" sx={{marginTop: "10%"}} spacing={2}>
        <img src="https://static-00.iconduck.com/assets.00/lichess-icon-1024x1024-9jog5jap.png" style={{width: "20%", height:"20%", margin: "auto"}}/>
            <Button variant="contained" onClick={back} sx={{maxHeight:150, m: "auto"}}>
                <Typography>
                I won
                </Typography>
            </Button>
        </Stack>
      </Box>
    </div>
  );
}
