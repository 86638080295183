import { Box, CardMedia, CardContent, Card, CardActionArea, Button, createTheme, Stack, Typography} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { generateMaze, solve } from "./util";
import "./styles.css";
import ConfettiExplosion from 'react-confetti-explosion';
import Lobby from "./Lobby.js"

export default function MazeGame({change_page}) {
  const games = [
    {
      'size': 10,
      'script': "It's a sunny day on Ardennes Ave. Get grandma to the park!",
    },
    {
      'size': 20,
      'script': "Uh oh, that wasn't the park, that was the gas station. Get grandma back on track!",
    },
    {
      'size': 30,
      'script': "The police caught grandma lacking. Escape prison and get to the park.",
    },
  ]
  const [isExploding, setIsExploding] = useState(false);
  const [gameId, setGameId] = useState(1);
  const [status, setStatus] = useState("playing");

  const [size, setSize] = useState(games[0]['size']);
  const [script, setScript] = useState(games[0]['script'])
  const [cheatMode, setCheatMode] = useState(false);

  const [userPosition, setUserPosition] = useState([0, 0]);

  function back() {
    change_page(<Lobby change_page={change_page}/>)
  }

  function toggleCheatMode() {
    setCheatMode(!cheatMode)
  }

  const handleRestartGame = () => {
    setCheatMode(false);
    setSize(games[gameId]['size']);
    setScript(games[gameId]['script']);
    setUserPosition([0, 0]);
    setStatus("playing");
    setGameId(gameId + 1);
  };

  const maze = useMemo(() => generateMaze(size, size), [size, gameId]);
  const solution = useMemo(() => {
    const s = new Set();
    const solutionPath = solve(maze, userPosition[0], userPosition[1]);
    solutionPath.forEach((path) => {
      const [x, y] = path;
      s.add(String(x) + "-" + String(y));
    });
    return s;
  }, [size, userPosition[0], userPosition[1], gameId]);

  useEffect(() => {
    const lastRowIndex = maze.length - 1;
    const lastColIndex = maze[0].length - 1;
    if (userPosition[0] === lastRowIndex && userPosition[1] === lastColIndex) {
      if (gameId >= games.length) {
        setStatus("won")
        setIsExploding(true)
        setTimeout(() => {setIsExploding(false)}, 2000)
        localStorage.setItem('mazeCompleted', true)
      }
      else {
        handleRestartGame();
      }
    }
  }, [userPosition[0], userPosition[1]]);

  const makeClassName = (i, j) => {
    const rows = maze.length;
    const cols = maze[0].length;
    let arr = [];
    if (maze[i][j][0] === 0) {
      arr.push("topWall");
    }
    if (maze[i][j][1] === 0) {
      arr.push("rightWall");
    }
    if (maze[i][j][2] === 0) {
      arr.push("bottomWall");
    }
    if (maze[i][j][3] === 0) {
      arr.push("leftWall");
    }
    if (i === rows - 1 && j === cols - 1) {
      arr.push("destination");
    }
    if (i === userPosition[0] && j === userPosition[1]) {
      arr.push("currentPosition");
    }

    if (cheatMode && solution.has(String(i) + "-" + String(j))) {
      arr.push("sol");
    }
    return arr.join(" ");
  };

  const handleMove = (e) => {
    e.preventDefault();
    if (status !== "playing") {
      return;
    }
    const key = e.code;

    const [i, j] = userPosition;
    if ((key === "ArrowUp" || key === "KeyW") && maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
    if ((key === "ArrowRight" || key === "KeyD") && maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
    if ((key === "ArrowDown" || key === "KeyS") && maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
    if ((key === "ArrowLeft" || key === "KeyA") && maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  return (
    <div className="App" onKeyDown={handleMove} tabIndex={-1}>
      <Typography variant="h6">{script}</Typography>
      <Stack direction="row">
        <table id="maze">
          <tbody>
            {maze.map((row, i) => (
              <tr key={`row-${i}`}>
                {row.map((cell, j) => (
                  <td key={`cell-${i}-${j}`} className={makeClassName(i, j)}>
                    <div />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Button variant="contained" onClick={toggleCheatMode} sx={{maxHeight:150, m: "auto"}}>
          <Typography>
            Cheat
          </Typography>
        </Button>
      </Stack>
      <Button variant="contained" onClick={back} sx={{maxHeight:150, m: "auto"}}>
        {isExploding && <ConfettiExplosion />}
        <Typography>
          Back
        </Typography>
      </Button>
    </div>
  );
}
