import { Box, Button, createTheme, Stack, ThemeProvider, Typography, CssBaseline } from "@mui/material";
import {useState} from 'react';
import Home from './Components/Home'
import Lobby from './Components/Lobby'

function App() {
  const [page, setPage] = useState(<Home change_page={start_game}/>);

  let theme = createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      primary: {
        main: '#ff52cc',
      },
      secondary: {
        main: '#ff0000',
      },
      dark: {
        main: '#777777'
      }
    },
  });

  function start_game() {
    setPage(<Lobby change_page={setPage}/>)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme/>
      {page}
    </ThemeProvider>
  );
}

export default App;
