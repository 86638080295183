import { Box, CardMedia, CardContent, Card, CardActionArea, Button, createTheme, Stack, Typography} from "@mui/material";
import { useState } from 'react'
import Lobby from "./Lobby.js"
import Puzzle from 'react-dnd-image-puzzle';
import PuzzleImg from '../media/puzzle.jpg'
import ConfettiExplosion from 'react-confetti-explosion';

export default function PuzzleGame({change_page}) {
  const [isExploding, setIsExploding] = useState(false);
  const onComplete = () => {
    setIsExploding(true)
    setTimeout(() => {setIsExploding(false)}, 2000)
    localStorage.setItem('puzzleCompleted', true)
  };
  function back() {
    change_page(<Lobby change_page={change_page}/>)
  }
  
  return (
    <Box justifyContent="center" justifyItems="center" sx={{ margin: "auto", maxWidth: "fit-content"}}>
      <Stack direction="row">
        <Puzzle
          image={PuzzleImg}
          width={410}
          height={550}
          pieces={5}
          onComplete={onComplete}
        />
        <Button variant="contained" onClick={back} sx={{maxHeight:150, m: "auto"}}>
          {isExploding && <ConfettiExplosion />}
          <Typography>
            Back
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
