import { Box, CardMedia, CardContent, Card, CardActionArea, Button, createTheme, Stack, Typography} from "@mui/material";
import { useRef, useState } from 'react'
import Lobby from "./Lobby.js"
import ConfettiExplosion from 'react-confetti-explosion';
import Crossword from '@jaredreisinger/react-crossword';

const data = {
    across: {
      5: {
        clue: 'Our first date we watched ____.',
        answer: 'HALLOWEEN',
        row: 2,
        col: 4,
      },
      6: {
        clue: 'Jodie\'s favorite Valorant Agent.',
        answer: 'PHOENIX',
        row: 4,
        col: 2,
      },
      7: {
        clue: 'Jodie\'s synonym for sexy.',
        answer: 'SNAZZY',
        row: 5,
        col: 9,
      },
      9: {
        clue: 'Jodie\'s favorite game.',
        answer: 'MINECRAFT',
        row: 7,
        col: 0,
      },
      10: {
        clue: 'Jodie\'s favorite artist.',
        answer: 'THEWEEKND',
        row: 8,
        col: 11,
      },
      12: {
        clue: 'Jodie\'s favorite OW character',
        answer: 'REINHARDT',
        row: 11,
        col: 0,
      },
    },
    down: {
      1: {
        clue: 'Jodie\'s favorite show.',
        answer: 'GREYSANATOMY',
        row: 0,
        col: 11,
      },
      2: {
        clue: 'Our first trip together.',
        answer: 'WILLIAMSBURG',
        row: 1,
        col: 0,
      },
      3: {
        clue: 'Jodie\'s favorite food.',
        answer: 'DUMPLINGS',
        row: 1,
        col: 2,
      },
      4: {
        clue: 'My favorite gift you got me.',
        answer: 'RACECAR',
        row: 1,
        col: 5,
      },
      8: {
        clue: 'Jodie\'s favorite song.',
        answer: 'ETA',
        row: 6,
        col: 8,
      },
      11: {
        clue: 'Where we met.',
        answer: 'DISCORD',
        row: 8,
        col: 19,
      },
    },
  }

export default function WordGame({change_page}) {
  const [isExploding, setIsExploding] = useState(false);
  const crossword = useRef();

  const handleCrosswordChange = () => {
    setTimeout(() => {
      if (crossword.current.isCrosswordCorrect()) {
          setIsExploding(true)
          setTimeout(() => {setIsExploding(false)}, 2000)
          localStorage.setItem('wordCompleted', true)
      }
    }, 100)
  };
  function back() {
    change_page(<Lobby change_page={change_page}/>)
  }

  return (
    <Box justifyContent="center" justifyItems="center" sx={{ margin: "auto", maxWidth: "fit-content"}}>
        <Box sx={{ width: '50em', display: 'flex' }}>
          <Crossword data={data} onCellChange={handleCrosswordChange} ref={crossword} onLoadedCorrect={()=>{}}/>
        </Box>
        <Button variant="contained" onClick={back} sx={{maxHeight:150, m: "auto"}}>
          {isExploding && <ConfettiExplosion />}
          <Typography>
            Back
          </Typography>
        </Button>
    </Box>
  )
}