import { Box, CardMedia, CardContent, Card, CardActionAream, Button, createTheme, Stack, ThemeProvider, Typography, Grid, CardActionArea } from "@mui/material";
import GameCard from './GameCard.js'
import Puzzle from './PuzzleGame.js'
import Trivia from './Trivia.js'
import Color from './ColorGame.js'
import Word from './WordGame.js'
import Maze from './MazeGame.js'
import Final from './FinalGame.js'
import Completed from './CompletedPage.js'
import ProgressBar from "./ProgressBar.js";

export default function Lobby({change_page}) {
  let gamesComplete = 0;
  let games = ['puzzleCompleted', 'triviaCompleted','colorCompleted','wordCompleted','mazeCompleted','finalCompleted']
  for (let i = 0; i < games.length; i++) {
    if (localStorage.getItem(games[i]) === "true") {
      gamesComplete += 1
    }
  }
  function startPuzzle() {
    change_page(<Puzzle change_page={change_page}/>)
  }
  function startTrivia() {
    change_page(<Trivia change_page={change_page}/>)
  }
  function startColor() {
    change_page(<Color change_page={change_page}/>)
  }
  function startWordle() {
    change_page(<Word change_page={change_page}/>)
  }
  function startMaze() {
    change_page(<Maze change_page={change_page}/>)
  }
  function startFinal() {
    change_page(<Final change_page={change_page}/>)
  }
  function endGame() {
    change_page(<Completed/>)
  }

  return (
    <Box justifyContent="center" justifyItems="center" sx={{ margin: "auto", maxWidth: "fit-content"}}>
        <Stack>
          <Grid container spacing={2} justifyContent="center">
              <Grid item xs={4}>
                  <GameCard 
                  image="https://static.vecteezy.com/system/resources/previews/013/941/056/non_2x/cute-puzzle-piece-couple-valentine-s-day-greeting-card-template-funny-romantic-love-characters-kawaii-face-set-dating-concept-vector.jpg" 
                  header="Puzzle" 
                  text="Play this puzzle"
                  completed={localStorage.getItem('puzzleCompleted') === "true"}
                  onClick={startPuzzle}
                  />
              </Grid>
              <Grid item xs={4}>
                  <GameCard 
                  image="https://hips.hearstapps.com/hmg-prod/images/too-many-questions-royalty-free-image-487063216-1545936032.jpg" 
                  header="Trivia" 
                  text="relationship trivia"
                  completed={localStorage.getItem('triviaCompleted') === "true"}
                  onClick={startTrivia}
                  />
              </Grid>
              <Grid item xs={4}>
                  <GameCard 
                  image="https://i.etsystatic.com/25839518/r/il/d3a32c/4033896721/il_1588xN.4033896721_r772.jpg" 
                  header="Coloring" 
                  text="Show them art skills"
                  completed={localStorage.getItem('colorCompleted') === "true"}
                  onClick={startColor}
                  />
              </Grid>
              <Grid item xs={4}>
                  <GameCard 
                  image="https://previews.123rf.com/images/kahovsky/kahovsky2008/kahovsky200800226/154187806-human-anatomy-crossword-puzzle-game-cute-smiling-happy-human-healthy-strong-organs-set-vector.jpg" 
                  header="Crossword" 
                  text="the hardest game"
                  completed={localStorage.getItem('wordCompleted') === "true"}
                  onClick={startWordle}
                  />
              </Grid>
              <Grid item xs={4}>
                  <GameCard 
                  image="https://www.creativefabrica.com/wp-content/uploads/2022/02/13/1644744623/Asian-Grandma-580x386.jpg" 
                  header="Maze" 
                  text="Get grandma to the park"
                  completed={localStorage.getItem('mazeCompleted') === "true"}
                  onClick={startMaze}
                  />
              </Grid>
              <Grid item xs={4}>
                  <GameCard 
                  image="https://i.shgcdn.com/b613badd-3986-4479-9176-6322a7d9192f/-/format/auto/-/preview/3000x3000/-/quality/lighter/" 
                  header="The Final Boss" 
                  text="The real hardest game"
                  completed={localStorage.getItem('finalCompleted') === "true"}
                  onClick={startFinal}
                  />
              </Grid>
          </Grid>
          {
            gamesComplete === games.length ? <Button variant="contained" color="dark" onClick={endGame}>
            <Typography>
              I've finished all Donny's games
            </Typography>
          </Button> : <ProgressBar bgcolor='#f6c901' progress={(gamesComplete / games.length)*100} height={20}/>
          }
        </Stack>
    </Box>
  );
}
