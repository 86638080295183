import { Box, CardMedia, CardContent, Card, CardActionArea, Button, createTheme, Stack, Typography} from "@mui/material";
import { useState } from 'react'
import Lobby from "./Lobby.js"
import Color from "./color.js"
import Hint1 from "../media/Hint1.png"
import Hint2 from "../media/Hint2.png"
import ConfettiExplosion from 'react-confetti-explosion';


export default function PuzzleGame({change_page}) {
  const SVG_SIZE = 134;
  const hints = [Hint1, Hint2]
  const [isExploding, setIsExploding] = useState(false);
  const [blankSpots, setBlankSpots] = useState(SVG_SIZE)
  const [fillColors, setFillColors] = useState(Array(SVG_SIZE).fill('white'))
  const [currentColor, setColor] = useState('#B64E33')
  const [hintsLeft, setHintsLeft] = useState(hints.length)
  const [hintImage, setHintImage] = useState(<></>)
  let palette = ["#B64E33", "#C5BB38", "#331F14", "#A60C0A", "#7A6F2D", "#E6DF39", "#A39A35", "#C78458", "#59401F", "#E5CF7A"]

  const onFillColor = (i) => {
    console.log(blankSpots)
    if (fillColors[i] == 'white') {
      if (blankSpots <= 22) {
        setIsExploding(true)
        setTimeout(() => {setIsExploding(false)}, 2000)
        localStorage.setItem('colorCompleted', true)
      }
      setBlankSpots(blankSpots-1)
    }
    
    let newFillColors = fillColors.slice(0)
    newFillColors[i] = currentColor
    setFillColors(newFillColors)
  }

  function back() {
    change_page(<Lobby change_page={change_page}/>)
  }

  function onHintPress() {
    if (hintsLeft === 0) {
      return
    }
    setHintImage(<Box
      component="img"
      sx={{
        height: 300,
        width: 300,
      }}
      src={hints[hints.length-hintsLeft]}
    />)
    setHintsLeft(hintsLeft-1)
  }
  
  return (
    <Box justifyContent="center" justifyItems="center" sx={{ margin: "auto", maxWidth: "fit-content"}}>
      <Stack sx={{w:"100%", h:"100%"}}>
        <Stack direction="row">
          <Color fillColors={fillColors} onFill={onFillColor}/>
          <Stack>
            <Button variant="contained" onClick={back} sx={{maxHeight:150, m: "auto"}}>
              {isExploding && <ConfettiExplosion />}
              <Typography>
                Back
              </Typography>
            </Button>
            <Button variant="contained" onClick={onHintPress} sx={{maxHeight:150, m: "auto"}}>
              <Typography>
                Hints {hintsLeft}/{hints.length}
              </Typography>
            </Button>
          </Stack>
          {hintImage}
        </Stack>
        <Stack direction="row" sx={{w:"100%", h:"100%"}}>
          {palette.map((v,i,a) => (<Box sx={{backgroundColor: v, width: 50, height: 50}} onClick={() => (setColor(v))}/>))}
        </Stack>
      </Stack>
    </Box>
  );
}
