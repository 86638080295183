import { Box, Typography, Stack } from "@mui/material";
import Confetti from 'react-confetti';
import Collage from '../media/collage.jpg'

export default function Completed() {
  function drawHeartShape(ctx) {
    const scale = 0.2; // Adjust this scale factor to change the size of the hearts
    ctx.save(); // Save the current transformation state
    ctx.scale(scale, scale); // Scale down the drawing
    ctx.beginPath();
    ctx.moveTo(75, 40);
    ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
    ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
    ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
    ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
    ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
    ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
    ctx.fill();
    ctx.closePath();
    ctx.restore(); // Restore the transformation state to prevent scaling other drawings
  }

  return (
    <div style={{  
      width: "100%",
      height: "100%",
      backgroundImage: "url(" + Collage + ")",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}><Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack alignItems="center">
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={800}
          drawShape={ctx => drawHeartShape(ctx)}
          colors={["#87cefa", "#000000", "#018fc7"]}
        />
        <Typography variant="h1" color="#f6c901" sx={{ zIndex: 5, fontWeight: "bold" }}>20岁生日快乐</Typography>
      </Stack>
    </Box>
    </div>
    
  );
}
