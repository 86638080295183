import React from 'react'

export default class Color extends React.Component {
    render () {
        return (
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="100%" viewBox="0 0 1170 1168" enable-background="new 0 0 1170 1168">
			<path onClick={() => this.props.onFill(0)} fill={this.props.fillColors[0]} opacity="1.000000" stroke="black" 
				d="
			M690.756348,1.240700 
				C698.020935,1.000000 705.041931,1.000000 712.531433,1.000000 
				C714.883545,2.606550 715.486023,4.803705 716.059448,7.110399 
				C719.827393,22.268368 722.503601,37.706326 727.746094,52.489201 
				C728.973816,55.951366 730.271729,59.438473 732.015381,62.595516 
				C737.757751,72.992432 737.777588,83.809898 735.666199,95.067986 
				C733.942871,104.257294 733.210083,113.445236 736.198364,122.694359 
				C739.305481,132.311676 745.904358,136.676758 755.868408,135.160431 
				C762.342346,134.175262 768.469238,132.178024 774.502441,129.756165 
				C784.989624,125.546371 795.159851,125.316658 804.967834,131.856567 
				C815.189331,138.672119 826.353760,144.092438 835.360840,152.696396 
				C841.325439,158.394089 846.043152,164.927612 849.783203,172.291733 
				C855.442322,183.434662 863.423462,192.332886 875.359680,197.067627 
				C877.064453,197.743866 878.801208,198.308945 880.613831,198.642426 
				C884.571655,199.370529 887.144104,198.126007 888.919006,194.212677 
				C891.413757,188.712097 891.867859,183.308060 891.350037,177.281860 
				C890.429382,166.568039 889.029968,155.791245 890.900208,144.984161 
				C893.270020,131.290726 891.343384,117.689331 888.480286,104.395744 
				C886.495789,95.181450 886.393616,86.032860 886.067627,76.795990 
				C886.018738,75.408920 886.585938,74.221687 887.203735,73.022507 
				C892.825073,62.110786 892.523743,51.615635 884.751099,41.787861 
				C880.679382,36.639500 881.446045,30.643272 882.157410,24.992910 
				C883.026550,18.089188 885.305359,11.396560 887.020996,4.623949 
				C887.373901,3.230853 888.286743,2.320386 889.701782,1.271738 
				C931.687561,1.000000 973.375122,1.000000 1015.531372,1.000000 
				C1018.845337,5.893075 1017.106873,11.030696 1016.553894,16.000191 
				C1015.165649,28.474480 1014.207397,40.834740 1019.194153,52.849098 
				C1022.742798,61.398521 1028.570312,63.520622 1036.748657,58.959976 
				C1040.695923,56.758827 1044.004395,53.668255 1047.517212,50.874653 
				C1055.347900,44.647141 1064.830688,44.095604 1072.978638,49.749359 
				C1076.934082,52.493980 1081.049316,55.017620 1084.887451,57.909161 
				C1091.222534,62.681778 1098.188354,63.526081 1105.682983,61.926300 
				C1116.744385,59.565197 1127.268677,55.234413 1138.513184,53.413334 
				C1147.261353,51.996540 1155.057495,53.642616 1162.325684,58.319736 
				C1168.345825,62.193783 1170.678467,71.005486 1167.489990,77.939735 
				C1164.151611,85.200378 1160.433228,92.280075 1157.165161,99.582352 
				C1154.348022,105.876480 1154.432373,112.111046 1156.634766,118.447693 
				C1159.589233,126.948387 1162.126831,135.431076 1161.701172,144.673981 
				C1161.097290,157.781845 1146.921021,172.794571 1134.567261,173.176620 
				C1127.767456,173.386871 1123.579956,170.265198 1120.943604,164.355621 
				C1118.840454,159.640991 1117.307373,154.703918 1115.127197,150.011612 
				C1112.409302,144.161697 1109.251953,142.169083 1102.739014,141.957260 
				C1093.286011,141.649841 1084.256836,144.027771 1075.153809,145.917282 
				C1062.955811,148.449203 1050.795288,151.160889 1038.522095,153.317200 
				C1022.200562,156.184738 1012.325195,166.832352 1012.407959,183.691406 
				C1012.489258,200.260071 1008.702820,216.439621 1008.521362,232.856400 
				C1008.363586,247.128647 1006.176636,260.945892 1003.384399,274.827057 
				C1001.484436,284.272858 1000.564697,293.859314 1001.443970,303.605804 
				C1002.330627,313.434052 1004.790649,322.911896 1006.711304,332.521454 
				C1009.458923,346.267975 1001.708923,359.219147 988.982605,361.679077 
				C982.086426,363.012085 975.057495,362.345612 968.176636,360.674225 
				C956.227539,357.771729 944.863342,358.907593 933.865479,364.848053 
				C929.259888,367.335754 924.211731,369.339905 918.654602,369.113312 
				C913.352966,368.897095 910.569885,367.099792 909.034973,362.082245 
				C907.574158,357.307190 905.753479,352.646271 904.586243,347.775635 
				C904.083069,345.676178 903.271851,343.657837 902.304565,341.705322 
				C898.882996,334.799225 893.024536,333.168152 887.020508,338.020142 
				C877.231384,345.930969 870.508057,356.141541 865.940369,367.822998 
				C865.007507,370.208588 866.177917,371.636475 867.947998,372.852264 
				C871.516113,375.302917 875.181274,377.612640 878.681091,380.162140 
				C885.409607,385.063599 890.046509,391.723755 894.404602,398.668823 
				C901.834717,410.509216 909.203308,422.385468 919.610168,432.019714 
				C928.245850,440.014160 938.495972,445.628815 948.210510,452.031097 
				C959.779663,459.655609 970.618835,468.126526 980.550232,477.826385 
				C990.274048,487.323608 994.347107,498.800720 995.292419,512.195129 
				C996.572388,530.331116 993.366028,547.921814 990.817322,565.666687 
				C989.567078,574.371277 988.707397,583.191895 989.206665,592.023254 
				C990.546692,615.729370 986.116638,638.381042 977.199097,660.280945 
				C970.585205,676.523254 966.673523,693.584351 962.158691,710.466309 
				C959.532654,720.285522 957.217896,730.163452 955.868958,740.227600 
				C953.636658,756.882141 945.000488,770.851746 937.242432,785.159729 
				C930.296448,797.970032 923.910828,810.970825 922.558044,825.697510 
				C921.777161,834.198669 923.026917,842.748596 925.660278,850.806335 
				C933.003235,873.275269 935.181152,896.237671 934.316284,919.752563 
				C933.831726,932.924805 936.136963,945.923584 940.097412,958.567200 
				C941.804321,964.016418 944.158936,969.224670 947.206177,973.956421 
				C955.040466,986.121887 958.907043,999.512939 961.203674,1013.634705 
				C962.846497,1023.736206 966.735596,1033.176147 972.661316,1041.593384 
				C975.777222,1046.019409 980.058472,1049.290894 985.097900,1051.348877 
				C988.963013,1052.927246 992.679504,1054.788818 996.394409,1056.665283 
				C1009.540039,1063.305786 1013.628967,1070.771606 1010.757507,1084.986816 
				C1008.763245,1094.859375 1007.834778,1104.718750 1008.180725,1114.734253 
				C1008.421204,1121.695068 1005.967529,1127.764893 1002.187500,1133.402832 
				C1001.343201,1134.662109 1000.575012,1135.883057 1000.237732,1137.395996 
				C999.026733,1142.826904 1001.671631,1145.330078 1007.140503,1143.930786 
				C1011.805420,1142.736938 1015.205688,1139.340576 1019.237549,1137.049438 
				C1024.322510,1134.159912 1029.441772,1131.584229 1035.239990,1130.299683 
				C1043.144409,1128.548584 1053.798950,1132.745483 1053.265747,1144.590088 
				C1052.958374,1151.417969 1052.992920,1158.218018 1051.228394,1164.885010 
				C1050.881592,1166.195435 1050.620972,1167.474121 1049.278564,1168.706299 
				C1019.645752,1169.000000 990.291565,1169.000000 960.468628,1169.000000 
				C959.437378,1168.388062 958.625366,1167.876099 958.406006,1167.100586 
				C954.565552,1153.523560 946.736328,1141.730713 941.326111,1128.854248 
				C937.857727,1120.599365 936.255127,1112.045654 935.815735,1103.159180 
				C935.290588,1092.535400 934.252808,1081.930542 932.676270,1071.427246 
				C931.063965,1060.686035 932.283997,1050.135498 934.119934,1039.704224 
				C937.709351,1019.310791 937.344910,999.491028 929.549988,979.927246 
				C923.351746,964.370972 920.992676,947.914185 919.654785,931.232849 
				C918.838989,921.061523 919.638977,910.981140 919.492310,900.884155 
				C919.299561,887.615173 916.959412,874.419434 913.229187,861.589905 
				C909.974731,850.396423 906.931030,839.138794 906.127319,827.478821 
				C905.373230,816.537415 908.015076,806.004578 911.213806,795.639587 
				C915.447998,781.919495 921.857544,769.086853 927.505737,755.940125 
				C930.736572,748.419922 932.766418,740.575012 932.542114,732.236633 
				C932.438599,728.389221 933.403687,724.609192 935.096069,721.127502 
				C942.140259,706.636597 945.368225,690.944641 949.583496,675.559387 
				C951.959045,666.888672 955.047363,658.459229 958.101868,650.003296 
				C963.254517,635.738892 965.503845,621.045471 963.145874,605.846313 
				C962.611267,602.400818 962.641846,598.880493 961.244385,595.583496 
				C960.452332,593.714844 959.465332,593.253296 957.935059,594.750061 
				C956.506897,596.146912 954.939758,597.464539 954.028076,599.270874 
				C950.465759,606.329041 945.120178,612.096924 940.382019,618.304565 
				C935.024780,625.323242 930.992859,633.135559 926.724976,640.821167 
				C919.406372,654.000610 909.133484,663.314148 893.444519,665.001892 
				C886.933960,665.702271 881.894531,660.607300 882.803711,653.795959 
				C883.728638,646.866760 885.790466,640.137756 888.816528,633.812988 
				C892.173462,626.796387 893.959961,619.134766 894.469910,611.725098 
				C895.511047,596.597778 901.493591,583.795349 909.867554,571.794006 
				C916.337219,562.521912 922.878174,553.297363 929.364258,544.035156 
				C930.541687,542.353699 931.203064,540.504944 931.790710,538.594360 
				C933.222839,533.937561 935.253601,529.585510 938.508179,525.908691 
				C944.624023,518.999390 947.882141,510.318817 952.832764,502.675446 
				C955.145813,499.104401 956.929932,494.317474 951.525330,490.931427 
				C950.715210,490.423859 950.360657,489.306946 950.142029,488.322296 
				C947.979187,478.580658 941.137451,472.125153 933.984070,466.135010 
				C921.873413,455.993805 908.772339,446.975830 898.393677,434.896271 
				C890.685974,425.925446 882.795532,417.147522 874.289124,408.922119 
				C866.325256,401.221252 860.530334,401.559052 853.359009,410.006958 
				C847.918030,416.416473 843.945984,423.724060 840.053955,431.069397 
				C835.145508,440.333038 830.911316,449.949768 824.781006,458.551453 
				C822.448914,461.823669 819.594055,464.633118 817.245544,467.853668 
				C812.407471,474.488312 808.673462,481.720520 806.035645,489.510101 
				C802.789673,499.095490 798.192139,508.115753 791.064392,515.213257 
				C777.700195,528.520752 767.382446,543.924561 758.069519,560.120239 
				C756.474182,562.894592 754.368042,565.240479 752.537781,567.810486 
				C744.923828,578.501953 742.744019,590.187744 745.886536,602.871094 
				C750.785217,622.642761 758.952148,641.288818 765.753113,660.407227 
				C772.681458,679.883667 780.938293,698.802429 789.929321,717.376099 
				C796.004578,729.926514 800.816223,742.832581 802.591858,756.603210 
				C803.675903,765.009888 804.068970,773.454651 806.744263,781.606689 
				C809.943481,791.355225 812.200806,801.463501 819.769226,809.193054 
				C822.780151,812.267944 822.393188,816.171265 819.941650,819.291321 
				C813.484680,827.509094 808.565369,836.871643 801.184753,844.387939 
				C799.315796,846.291260 797.260437,847.887512 794.950500,849.308228 
				C781.336365,857.680603 767.445068,865.977722 756.879578,878.089294 
				C747.911011,888.370361 739.036438,898.684631 729.452087,908.408386 
				C724.575317,913.356018 725.552368,919.894043 726.439697,925.983154 
				C728.445557,939.747742 726.497864,952.762512 720.419739,965.296143 
				C716.302063,973.787231 710.843933,981.751160 709.700134,991.506714 
				C709.447693,993.660034 709.175171,995.795837 709.173828,997.957092 
				C709.158447,1022.926453 707.385986,1047.780396 704.328918,1072.552246 
				C704.042786,1074.870361 704.236816,1077.216675 704.071045,1079.543579 
				C703.913574,1081.753662 703.693604,1083.956665 700.497925,1084.414185 
				C697.824341,1083.018311 697.785217,1080.758057 697.855713,1078.620361 
				C698.315918,1064.672607 697.030334,1050.774292 696.684753,1036.850098 
				C696.339478,1022.938232 695.350403,1009.048035 692.813904,995.311462 
				C691.907043,990.400024 689.856384,985.808044 689.018555,980.449951 
				C687.984497,975.103210 690.854309,971.497253 693.440735,967.387085 
				C700.149475,958.600342 705.535522,949.805298 702.532349,938.416504 
				C700.868652,932.107483 698.191406,926.176880 695.485718,920.289307 
				C689.195923,906.602600 694.492859,896.588989 703.857056,889.996521 
				C724.482056,875.476196 742.483093,857.928162 761.555603,841.622498 
				C774.138611,830.864746 782.680786,817.681824 783.631958,800.609680 
				C784.133728,791.603638 782.392883,782.736938 781.341797,773.841064 
				C777.689148,742.925720 765.882751,714.417725 755.228333,685.561218 
				C746.235596,661.205322 738.767822,636.339844 730.812134,611.637146 
				C728.368469,604.049683 726.495850,596.314941 724.055237,588.346436 
				C722.736511,585.709473 722.782654,583.142761 722.422119,580.706665 
				C721.324890,573.293396 718.328308,567.088318 711.841064,562.387573 
				C700.213867,550.684631 689.246338,538.769897 673.622009,532.821838 
				C668.885559,531.018738 664.072327,529.947632 659.033813,529.818726 
				C647.503296,529.523682 639.464111,535.039307 635.238159,546.022827 
				C631.726074,555.151001 630.503540,564.666260 630.138916,574.363342 
				C629.820740,582.826111 631.067017,591.342957 629.111389,599.752991 
				C626.827087,609.576660 620.953308,615.798523 611.194702,618.543640 
				C591.269470,624.148743 572.112000,623.654114 554.064087,612.198242 
				C550.707581,610.067688 547.055176,608.367493 543.608154,606.344788 
				C534.040588,600.730591 525.109619,602.270630 516.790588,608.942383 
				C510.633850,613.880066 503.960876,617.671448 496.411224,620.128845 
				C485.314972,623.740662 476.028900,630.613281 467.073120,637.794312 
				C457.218445,645.696228 445.554993,649.626404 433.969116,653.760864 
				C431.125275,654.775757 428.374207,655.780701 425.539368,657.692993 
				C414.391602,660.341675 403.881226,661.269653 394.824951,652.264893 
				C391.335449,645.725891 386.763153,640.402283 382.748688,634.757751 
				C375.273560,624.247375 374.175049,611.787598 384.165649,601.704834 
				C386.468140,599.381165 388.449982,596.654175 390.555328,594.093079 
				C399.818298,582.825195 402.420807,569.646484 400.536224,555.644958 
				C398.961578,543.946289 400.009521,532.487610 401.305450,520.907776 
				C402.436035,510.805664 402.385254,500.584167 401.381439,490.485046 
				C399.764191,474.214630 399.858337,457.963074 400.327362,441.659607 
				C400.566528,433.346771 398.923126,425.137970 397.398102,416.965546 
				C396.179413,410.434753 394.379425,404.011475 393.862000,397.348846 
				C392.931976,385.372803 394.750977,373.843872 398.850586,362.556274 
				C403.853058,348.782806 409.347076,335.217163 415.094849,321.743591 
				C422.093964,305.336731 433.772064,292.809082 447.688477,282.128021 
				C450.199310,280.200897 452.698914,278.214233 454.994324,276.096741 
				C465.235718,266.649139 476.801361,266.135651 489.465729,269.839874 
				C500.938934,273.195648 512.414001,276.795349 524.694031,275.855042 
				C532.186340,275.281342 539.463379,273.513397 546.857300,272.414337 
				C556.449524,270.988495 564.589539,273.435883 571.637939,280.013794 
				C579.308716,287.172577 585.803467,295.137787 588.199158,305.607574 
				C589.846252,312.805878 590.230042,320.169769 587.291199,327.180267 
				C584.415833,334.039368 579.398987,336.025208 572.320862,333.793945 
				C568.972717,332.738525 565.858154,331.154785 562.586609,329.944000 
				C554.105103,326.805023 547.427490,328.713440 541.690796,335.663361 
				C538.887024,339.060120 536.759399,342.838043 535.364441,346.933624 
				C531.265198,358.968811 525.903320,370.404968 519.346436,381.265411 
				C509.901825,396.908783 504.093109,413.828674 502.245605,431.924164 
				C501.002625,444.098663 502.078979,456.186920 508.451233,467.571838 
				C508.863586,468.081543 509.020203,468.289764 509.369690,468.832886 
				C510.053162,470.544434 510.434235,471.975403 511.697693,473.373596 
				C516.252197,481.303253 520.066040,489.220367 524.438416,496.833344 
				C528.905640,504.611481 532.719666,506.019836 541.307556,502.998779 
				C545.992798,501.350616 550.553467,499.353729 555.702881,497.851562 
				C564.902466,496.889465 572.889160,493.500031 580.160950,488.754608 
				C586.393066,484.687714 588.276917,477.589996 590.283264,470.888245 
				C592.281189,464.214752 593.365662,457.272278 594.313782,450.431030 
				C596.505127,434.619598 605.918945,426.061859 620.504944,422.032532 
				C629.498962,419.547974 638.786743,418.546570 647.978027,417.240784 
				C660.336243,415.485138 672.375000,412.194397 684.720276,410.389709 
				C700.694580,408.054504 720.367798,414.027435 727.577942,432.042694 
				C730.666565,439.760040 734.002686,447.361145 734.587341,455.821625 
				C734.759094,458.307312 736.464905,460.441193 736.695435,463.404144 
				C735.426331,478.537262 733.680542,493.212616 734.062683,508.419708 
				C731.278503,523.334106 729.147705,537.952332 726.446411,552.471619 
				C725.957153,555.101257 726.002502,557.768127 726.008911,560.431274 
				C726.014160,562.622681 725.823425,565.219055 727.972717,566.254456 
				C730.450134,567.447815 733.178833,566.729858 735.473633,565.087769 
				C740.516663,561.479004 744.904541,557.221558 748.321594,552.006775 
				C756.378479,539.711365 762.828979,526.649658 767.235107,512.600464 
				C767.729797,511.023224 768.178223,509.404358 769.297485,507.677551 
				C770.146484,504.740417 771.589478,502.535675 773.098328,500.353058 
				C776.977417,494.741791 779.875916,488.662170 782.132812,482.214874 
				C784.316223,475.977509 786.686768,469.769348 791.784424,464.686890 
				C803.668701,456.821625 814.572937,448.529358 821.402344,435.967072 
				C830.858337,418.573303 840.655396,401.363800 849.958679,383.888977 
				C854.892700,374.621246 854.564697,373.947754 844.844971,369.502411 
				C832.954712,364.064392 821.820129,357.621338 812.537292,348.209778 
				C806.966858,342.562103 802.032471,336.314056 799.224854,329.060913 
				C793.143921,313.351379 780.764282,308.845978 765.548889,307.366455 
				C751.231445,305.974304 736.903809,304.422424 722.798340,301.137299 
				C707.919373,297.672028 698.739075,289.342712 695.857361,274.037140 
				C693.891357,263.595032 690.088989,253.851532 681.838074,246.489334 
				C680.843811,245.602158 679.863403,244.714493 678.769592,243.938324 
				C668.784790,236.853531 662.781677,237.924347 655.709290,247.904648 
				C651.503601,253.839600 647.961365,260.358459 640.880737,263.719116 
				C636.160583,265.959412 631.311157,266.640076 626.303223,265.806305 
				C620.100952,264.773743 614.763489,258.878052 614.069336,252.231873 
				C612.911133,241.142548 616.976990,232.083267 625.324951,224.816620 
				C631.592346,219.360992 639.098145,215.622314 645.029053,209.572739 
				C651.412720,203.061401 656.044800,195.409409 661.159973,188.063568 
				C666.421631,180.507553 672.514038,173.737671 679.046387,167.303772 
				C682.119995,164.276459 685.015442,161.153946 687.453064,157.480606 
				C691.640137,151.170898 691.884216,144.555054 689.629028,137.890182 
				C686.374878,128.273285 682.262939,118.948738 677.313538,110.073753 
				C671.473816,99.602242 669.786987,88.386536 671.294434,76.776840 
				C673.392456,60.618549 673.472656,44.703274 669.208740,28.813282 
				C667.234741,21.456942 665.243652,14.028458 664.750793,6.339592 
				C664.644470,4.681012 664.220337,3.010060 665.747986,1.291969 
				C672.687622,1.000000 679.375305,1.000000 686.531494,1.000000 
				C688.045654,2.074557 689.149597,2.723580 690.756348,1.240700 
			z"/>
			<path onClick={() => this.props.onFill(1)} fill={this.props.fillColors[1]} opacity="1.000000" stroke="black" 
				d="
			M700.566162,1084.357910 
				C703.191040,1078.529297 702.142822,1072.605347 702.782715,1066.894775 
				C704.646729,1050.257935 705.734436,1033.528687 706.868774,1016.818237 
				C707.284607,1010.693298 707.008484,1004.519165 706.992065,998.367554 
				C706.971680,990.753845 708.576477,983.588257 712.063293,976.750000 
				C717.117432,966.838013 722.624817,957.146790 724.371460,945.813843 
				C725.690674,937.254028 724.548401,928.922241 723.635864,920.517334 
				C723.109436,915.668213 723.870728,910.974243 727.208801,907.491821 
				C736.519531,897.778748 745.545898,887.869873 754.290527,877.611572 
				C765.451904,864.518372 780.306702,855.648621 794.948608,846.712646 
				C802.005066,842.406067 806.650024,835.311829 810.554260,828.267334 
				C812.714355,824.369751 816.340637,821.968567 818.283997,818.118591 
				C819.593994,815.523438 820.629883,813.252930 818.258667,810.530396 
				C805.995544,796.450562 802.703796,779.157288 801.010132,761.350891 
				C799.751526,748.118713 796.060425,735.478088 790.479004,723.568848 
				C782.007263,705.492249 773.324158,687.528992 766.451233,668.761963 
				C759.700195,650.327637 752.789856,631.947266 746.378906,613.394775 
				C743.120667,603.965820 741.044312,594.226379 742.906189,584.038147 
				C743.980591,578.159180 746.237915,572.750366 749.700256,568.115662 
				C756.590820,558.891846 762.128174,548.846558 768.195557,539.128967 
				C773.265442,531.008911 779.781982,524.151733 786.287720,517.286743 
				C794.200073,508.937469 800.169128,499.499969 803.984863,488.640472 
				C807.429871,478.836060 812.229797,469.874268 819.269897,461.997131 
				C824.007080,456.696808 827.739075,450.486633 830.979858,444.068237 
				C837.198975,431.751617 842.778137,419.052704 851.790955,408.368652 
				C859.571533,399.145172 867.389038,398.528076 875.370911,406.748138 
				C883.947327,415.580566 892.458191,424.438690 900.422485,433.842072 
				C910.056763,445.217041 922.320435,453.691193 933.729980,463.087891 
				C945.300232,472.616974 948.436890,476.850342 953.187500,490.520966 
				C954.703003,490.329895 953.423279,488.305420 955.109436,488.015045 
				C958.394958,494.738403 957.322388,501.134216 953.203552,507.069336 
				C950.176270,511.431549 947.197571,515.743958 945.453308,520.825012 
				C945.047607,522.006836 944.081726,523.117188 943.114319,523.964233 
				C938.987915,527.577209 935.996094,532.237854 934.492126,537.186768 
				C931.138977,548.220886 923.306641,556.259827 917.038452,565.303040 
				C908.585938,577.497925 899.875000,589.568970 897.530701,604.588074 
				C896.593262,610.593750 895.692383,616.609314 894.685120,622.615234 
				C893.127258,631.903381 888.154541,639.973938 885.800537,649.003540 
				C884.428101,654.267883 884.562256,658.417236 888.343140,662.406311 
				C889.737366,663.877319 891.156189,663.120300 892.509216,662.949768 
				C908.434387,660.942444 918.516724,651.808289 925.565491,637.645142 
				C930.765503,627.196777 937.423950,617.394165 945.121521,608.464111 
				C950.510498,602.212219 953.496948,594.161804 960.507751,588.748840 
				C963.041931,591.788574 964.210327,595.812500 964.823669,599.798950 
				C967.678040,618.352051 966.169128,636.410583 958.965454,653.998779 
				C953.855164,666.475952 950.433472,679.570923 947.375793,692.690674 
				C944.832764,703.602112 940.758667,713.906433 936.335876,724.107361 
				C935.232727,726.651672 934.671997,729.261780 934.775330,731.860657 
				C935.233459,743.380859 931.320740,753.722046 926.562256,763.859558 
				C920.190063,777.435059 914.577454,791.251160 910.731262,805.820496 
				C907.000000,819.954407 907.937317,833.846680 911.820007,847.568115 
				C916.628479,864.560730 920.410950,881.693970 921.973816,899.303528 
				C922.290344,902.870728 921.036133,906.224365 921.028564,909.704773 
				C920.972717,935.321594 924.074829,960.353516 933.509460,984.460632 
				C938.073669,996.122925 939.010986,1008.441162 938.438049,1021.095642 
				C937.872864,1033.577148 934.662231,1045.636841 934.034180,1058.056396 
				C933.652100,1065.611694 935.430359,1072.915039 936.013000,1080.351807 
				C936.751404,1089.777100 937.676941,1099.188354 938.372681,1108.616577 
				C939.227722,1120.204590 944.406067,1130.278198 949.232300,1140.495239 
				C952.430298,1147.265381 956.393555,1153.673340 958.809448,1160.815552 
				C959.617859,1163.205322 960.181396,1165.613770 960.013794,1168.576416 
				C874.979126,1169.000000 789.958191,1169.000000 704.468628,1169.000000 
				C702.671631,1167.867188 701.906311,1166.462280 701.895142,1164.684570 
				C701.869141,1160.535156 700.850220,1156.422241 700.918274,1152.322144 
				C701.140991,1138.893311 699.209412,1125.532959 699.775513,1112.114502 
				C700.070374,1105.125366 698.380981,1098.263184 698.778687,1091.292969 
				C698.908386,1089.020874 698.436829,1086.597412 700.566162,1084.357910 
			z"/>
			<path onClick={() => this.props.onFill(2)} fill={this.props.fillColors[2]} opacity="1.000000" stroke="black" 
				d="
			M1.240925,826.239990 
				C1.000000,722.312439 1.000000,618.624878 1.000000,514.468628 
				C2.926250,511.519165 5.705372,511.906403 8.342146,511.818268 
				C33.724525,510.969391 58.697556,514.269348 83.750397,518.063232 
				C114.979240,522.792419 145.943726,529.052185 177.204590,533.470947 
				C190.176331,535.304504 203.452164,535.019043 216.290359,537.917297 
				C230.395660,541.101562 244.466370,544.451904 257.276276,551.722229 
				C264.276184,555.695007 269.612976,561.419312 274.612823,567.331787 
				C280.521606,574.319092 286.656067,581.105774 292.776306,587.882446 
				C301.289581,597.308777 306.327240,608.247314 308.955170,620.461731 
				C309.983551,625.241638 311.703644,629.690735 314.310272,633.812439 
				C321.711273,645.515198 327.586151,658.018616 333.208893,670.620911 
				C336.335663,677.628845 337.760773,685.252625 338.910767,692.799744 
				C340.120728,700.740540 341.921417,708.544067 344.732727,715.970215 
				C349.627930,728.900757 348.729401,741.383240 343.577942,753.857727 
				C337.489777,768.600586 334.365387,783.957397 332.919281,799.834167 
				C332.192413,807.814026 332.516724,816.199768 328.020081,823.865356 
				C324.995422,825.493347 322.890045,823.969604 320.829681,822.571655 
				C306.871674,813.101013 291.303253,807.992798 274.644226,809.447388 
				C259.333099,810.784180 245.003525,806.820862 230.268250,804.778259 
				C221.866318,803.613525 213.380600,803.277710 205.105804,806.195801 
				C199.836227,808.054077 196.149857,811.631348 194.527176,816.740784 
				C192.673141,822.578796 191.501114,828.681152 193.074402,834.875061 
				C194.780563,841.591919 198.527740,846.551331 205.330292,848.812988 
				C211.194000,850.762451 217.315323,851.571045 223.327927,852.854187 
				C224.796402,853.167603 226.245529,853.535889 227.678589,853.947144 
				C235.307114,856.136841 238.819504,861.488098 236.632919,869.149109 
				C233.092041,881.555176 234.140381,893.644531 236.634613,906.097168 
				C239.733307,921.567749 233.961823,934.274902 218.810959,940.404663 
				C213.708511,942.468994 209.060944,945.317078 206.869080,951.032532 
				C205.541153,954.495117 204.916672,957.562988 206.847107,961.022400 
				C208.485001,963.957520 208.582092,967.433716 209.007828,970.705750 
				C209.581650,975.115845 211.598328,977.815369 215.924438,979.348389 
				C224.344238,982.332092 231.245392,994.794983 225.312912,1002.430115 
				C222.006897,1006.684998 218.451843,1007.299744 214.304459,1003.783386 
				C210.612549,1000.653381 208.822403,996.401123 207.010178,992.058228 
				C202.759796,981.872314 197.154846,972.475830 188.605255,965.303833 
				C182.614365,960.278198 179.378036,954.207520 178.845016,946.438477 
				C178.283844,938.259277 177.010330,930.233398 172.087830,923.274536 
				C169.365601,919.426147 165.885345,917.043762 161.186966,916.018555 
				C152.848022,914.199097 144.332199,914.236389 135.936829,913.132141 
				C123.135445,911.448425 110.397285,912.491028 97.621956,913.411377 
				C91.095192,913.881531 85.359871,912.024170 80.361961,907.223389 
				C74.014053,901.125977 66.591728,897.394226 57.249489,900.060791 
				C48.671528,902.509155 46.738651,907.733276 52.064602,914.771606 
				C58.177589,922.849915 57.942020,928.895264 50.559311,935.903259 
				C39.697350,946.213928 27.898930,955.467163 16.041515,964.572327 
				C12.113260,967.588745 7.573708,971.328491 1.300540,969.249268 
				C1.000000,925.645752 1.000000,882.291565 1.000000,838.468628 
				C2.681218,834.223572 5.818126,830.508057 1.240925,826.239990 
			z"/>
			<path onClick={() => this.props.onFill(3)} fill={this.props.fillColors[3]} opacity="1.000000" stroke="black" 
				d="
			M1.289067,206.252960 
				C1.000000,150.312439 1.000000,94.624878 1.000000,38.468658 
				C12.906843,30.537830 22.377296,25.993122 36.449532,39.631596 
				C44.864571,47.787251 52.804958,55.964466 56.750652,67.443825 
				C58.889553,73.666618 59.075214,80.133484 60.641239,86.420288 
				C65.014740,103.977661 79.052101,109.980545 94.775101,107.543556 
				C100.071861,106.722580 104.926704,104.436531 110.111061,103.295822 
				C112.694641,102.727364 115.301506,101.958389 118.284546,103.235527 
				C122.128227,106.840378 121.360207,111.136536 120.677895,115.179558 
				C118.869690,125.893929 112.492325,133.507736 103.540688,139.188751 
				C97.920082,142.755783 92.235199,146.229446 87.026718,150.395279 
				C79.729622,156.231659 76.745903,163.575943 79.041733,172.896225 
				C80.451805,178.620667 83.245415,183.756165 85.599854,189.060974 
				C89.249237,197.283478 89.998108,205.699280 88.412514,214.430588 
				C87.699814,218.355164 86.827797,222.272003 86.415977,226.223862 
				C85.722420,232.879257 85.638252,239.601288 88.519547,245.862106 
				C93.243423,256.126709 96.828621,266.921326 102.796326,276.610657 
				C103.586296,277.893311 104.428635,279.145782 105.397903,280.270630 
				C110.067665,285.690033 115.303032,284.955872 118.141945,278.413757 
				C120.584778,272.784424 121.890709,266.727783 125.137878,261.365082 
				C127.483002,257.492065 129.971863,253.735565 133.040085,250.497299 
				C138.638733,244.588333 139.799240,237.860046 138.182114,230.172821 
				C136.477875,222.071457 133.482513,214.247208 133.815292,205.707230 
				C134.360031,191.728882 144.968002,182.780884 158.421371,186.496277 
				C172.368088,190.347946 185.969330,195.607132 198.304443,203.301971 
				C206.816574,208.611954 211.115097,216.735641 209.644440,227.111938 
				C207.323074,243.490570 211.989151,258.137146 220.906281,271.557739 
				C226.276428,279.639984 234.211746,283.302948 244.018570,282.837677 
				C247.496613,282.672607 250.031708,281.290253 251.825287,278.320068 
				C253.545395,275.471558 255.555389,272.809906 257.413727,270.051270 
				C261.829895,263.495697 267.767548,258.587585 274.938019,255.692642 
				C285.519653,251.420456 289.418518,242.821518 291.974457,232.737534 
				C294.328674,223.449432 295.832184,213.761200 302.792816,206.260941 
				C304.844482,204.050232 306.959595,201.932114 309.299469,200.092407 
				C317.728424,193.465195 324.574585,185.278091 331.884033,177.543488 
				C338.804169,170.220871 347.236328,165.250168 356.876221,162.630890 
				C365.392120,160.317017 373.056641,156.576569 379.850250,151.029053 
				C387.867920,144.481964 397.412750,140.928574 406.858429,137.241562 
				C412.869019,134.895416 418.192596,131.772034 422.511078,126.901550 
				C427.458649,121.321533 433.588318,117.276382 440.517609,114.667793 
				C452.117676,110.300865 459.478424,102.142349 463.272766,90.506187 
				C464.615784,86.387589 466.472076,82.461586 468.161194,78.484657 
				C471.048584,71.686409 475.676117,66.387207 482.538391,63.210003 
				C489.182922,60.133617 495.979797,57.398758 502.560730,54.181240 
				C506.193207,52.405296 509.387054,50.069736 512.189819,47.155411 
				C517.770264,41.352955 516.027344,34.454617 508.373840,32.216484 
				C497.150879,28.934534 485.714386,28.145594 474.423859,31.122650 
				C460.882080,34.693310 446.833893,35.331234 433.320557,38.963886 
				C425.277039,41.126144 418.106323,44.726173 411.882538,50.210762 
				C408.936188,52.807186 407.581299,56.518669 405.723541,59.821194 
				C401.851776,66.703934 396.296814,71.950623 389.854126,76.137993 
				C378.955963,83.221130 363.464966,77.080742 359.585602,64.692307 
				C358.327545,60.674828 357.552979,56.572956 357.154877,52.494892 
				C355.949310,40.146008 349.129181,30.840210 341.341736,22.047554 
				C337.597321,17.819803 333.159058,14.195434 330.066772,9.397058 
				C328.552948,7.048063 326.758850,4.640344 328.752747,1.294234 
				C410.354218,1.000000 491.708466,1.000000 573.531372,1.000000 
				C577.195801,3.713731 575.750916,7.438581 576.142578,10.768625 
				C576.525085,14.021105 574.785034,18.124090 579.121887,19.914127 
				C583.707214,21.806725 585.493591,17.543793 588.010315,15.244324 
				C590.593567,12.884077 592.214172,9.743208 593.690674,6.596664 
				C594.621887,4.612339 595.806824,2.875025 597.753662,1.246971 
				C616.354248,1.000000 634.708435,1.000000 653.531372,1.000000 
				C654.776367,1.859933 655.769409,2.586926 656.062439,3.774633 
				C659.401062,17.303156 664.276245,30.490492 665.123718,44.573013 
				C665.776489,55.420288 663.456604,65.747345 659.524475,75.754250 
				C657.877808,79.944954 656.550964,84.233994 655.084290,88.480446 
				C651.384705,99.191521 653.403015,108.611931 660.768066,117.287331 
				C665.975220,123.420937 669.825439,130.326370 670.185486,138.816879 
				C670.541016,147.200455 667.630859,154.434235 662.116943,160.194031 
				C653.385315,169.315079 646.362000,179.411697 640.788879,190.705307 
				C637.420837,197.530350 631.709290,202.670013 625.832336,207.275772 
				C618.905701,212.704178 611.526917,217.581070 603.601990,221.540726 
				C596.949097,224.864807 588.648682,223.421249 583.454407,217.980789 
				C580.416992,214.799454 578.539368,210.899918 576.471558,207.095016 
				C570.145569,195.454880 562.471619,193.205978 550.874939,199.673721 
				C545.629883,202.599030 541.009155,206.453323 536.352051,210.216858 
				C530.870422,214.646744 524.894775,217.998276 518.201050,220.410629 
				C511.740021,222.739105 506.833282,220.612106 502.190002,216.636566 
				C499.520355,214.350815 497.566193,211.392181 495.684784,208.458237 
				C491.536285,201.989075 485.878265,196.722351 481.538116,190.397705 
				C479.692688,187.708466 477.790009,188.188248 476.101685,191.720810 
				C473.544891,197.070572 470.095001,201.595016 465.446045,205.331345 
				C459.387146,210.200928 452.109192,211.090332 444.859009,211.977448 
				C441.888428,212.340912 438.937653,212.836411 435.977173,213.241089 
				C431.587555,213.841141 430.615295,215.725632 432.521423,219.826218 
				C435.551544,226.344818 440.080322,231.962173 443.811096,238.064438 
				C453.146729,253.334259 451.292389,266.185974 439.404083,278.252289 
				C432.602264,285.155945 424.604736,290.603699 417.264374,296.848846 
				C410.127014,302.921265 403.837860,309.749420 398.474274,317.423798 
				C392.881897,325.425476 391.955475,334.715057 391.390442,344.045593 
				C390.776001,354.193420 389.313080,364.226654 387.016357,374.096466 
				C384.062347,386.790863 385.978363,399.190155 388.484406,411.602081 
				C388.917419,413.746826 389.158752,415.861572 389.004822,418.428223 
				C388.089630,422.129639 389.457062,425.366119 389.192444,428.672668 
				C389.018646,430.844299 388.756317,432.929871 387.436371,435.184143 
				C383.596375,437.465912 383.411469,434.367737 382.849396,432.380402 
				C380.760406,424.994507 377.083923,418.466492 372.224182,412.122986 
				C370.831512,410.801117 369.934509,409.547821 369.661285,408.056091 
				C367.986328,398.910797 365.958679,389.768768 367.361725,380.387177 
				C368.421539,373.300720 369.072571,366.181305 369.101501,359.033203 
				C369.115875,355.484528 368.228027,351.905151 364.632355,350.145050 
				C361.156311,348.443512 357.984192,349.399963 354.859375,351.651001 
				C350.020172,355.137085 347.395477,360.164642 345.191895,365.322052 
				C342.524902,371.564178 338.585083,376.855896 334.345490,381.983826 
				C331.316101,385.648010 326.972046,387.191833 322.487000,387.822876 
				C312.051239,389.291199 301.992401,391.921234 292.259949,396.001343 
				C284.709778,399.166656 277.007294,399.018982 269.417847,395.866730 
				C266.662476,394.722351 264.446320,392.986725 264.216400,389.754822 
				C263.990173,386.574738 266.206757,384.929749 268.670349,383.736755 
				C276.433807,379.977295 282.062469,374.174622 286.349731,366.712250 
				C290.386078,359.686615 296.242737,354.691010 304.476013,352.915527 
				C306.738220,352.427704 308.959045,351.650757 311.206879,351.057129 
				C319.103241,348.971893 325.612396,344.702515 330.102509,337.982056 
				C334.777771,330.984497 340.952515,325.775421 348.047394,321.690643 
				C359.301453,315.211212 365.605682,304.765686 371.482391,293.867859 
				C373.157379,290.761688 372.550842,288.423828 369.704437,286.076172 
				C362.606750,280.222107 354.119873,277.467010 345.434418,276.428864 
				C332.252899,274.853363 318.900787,274.825714 305.622162,274.122833 
				C299.233398,273.784668 293.331635,275.408966 287.977539,278.636017 
				C280.657135,283.048218 272.846558,286.267761 264.891632,289.256439 
				C258.815186,291.539368 254.198441,295.279297 250.692261,301.104156 
				C245.408081,309.882782 240.270187,318.940948 231.527252,325.113617 
				C227.829865,327.724121 224.132172,330.262482 219.910202,331.849915 
				C209.860657,335.628448 200.343018,340.272339 192.450485,347.752350 
				C182.445786,357.234070 171.707794,365.940582 162.379898,376.136932 
				C157.337433,381.648804 154.789841,388.623688 151.476913,395.130951 
				C147.382004,403.174133 143.109024,411.057159 136.326385,417.237854 
				C132.037689,421.146027 127.115417,423.741241 120.884476,424.341309 
				C113.888809,422.253906 108.339409,418.324127 102.792870,414.410553 
				C93.487053,407.844513 84.026001,405.921326 73.559761,412.138519 
				C62.934174,418.450348 51.483646,421.100494 38.849983,420.574219 
				C27.952654,420.120239 17.045086,419.641327 6.143478,419.130768 
				C4.461223,419.051971 2.807528,418.947083 1.294509,417.277283 
				C1.000000,414.958344 1.000000,412.916656 1.000000,410.437500 
				C5.541363,406.155548 10.889492,407.847961 15.838361,408.225250 
				C27.265812,409.096558 38.666538,409.984985 50.103645,408.702118 
				C56.501816,407.984436 62.382431,405.389069 67.897774,402.391602 
				C78.511795,396.623108 89.182632,396.808197 100.224205,400.523926 
				C103.220451,401.532196 106.109688,402.908752 109.193512,403.586761 
				C118.908623,405.722687 125.222382,402.534393 131.271530,392.264526 
				C136.754669,382.955597 141.611588,373.286285 148.860855,365.084656 
				C152.519180,360.945709 156.520660,357.257141 160.646439,353.627533 
				C169.990936,345.406891 177.335022,335.520721 183.533325,324.813873 
				C187.383682,318.162750 192.729843,313.608032 199.831177,310.819611 
				C206.822021,308.074554 208.046204,306.127472 205.561493,299.011993 
				C202.721893,290.880219 200.001572,282.608215 195.109268,275.343445 
				C192.278870,271.140503 188.372040,268.857819 183.282593,269.247467 
				C173.205627,270.018982 163.699219,272.888916 154.773239,277.624451 
				C149.270111,280.544037 146.138428,285.193451 144.793610,291.242096 
				C144.253372,293.671875 143.785736,296.121185 143.154953,298.539246 
				C139.468613,312.670654 129.633682,318.386566 115.536423,314.275391 
				C98.082687,309.185364 86.862755,297.165588 80.209785,280.844177 
				C75.981758,270.471771 71.217842,260.455139 65.296486,250.981949 
				C59.779865,242.156296 57.433754,232.218506 61.096226,222.786346 
				C67.536507,206.200302 63.155663,191.831635 54.980183,177.682327 
				C52.641293,173.634384 51.383465,169.104843 48.750881,165.185852 
				C46.911781,162.448074 44.921844,161.461472 41.654068,162.147873 
				C30.779123,164.432236 22.590633,172.942825 21.184925,183.986557 
				C20.676497,187.980942 19.563171,191.821609 18.450411,195.624954 
				C16.682810,201.666519 12.278852,205.338303 6.299594,207.131531 
				C4.649803,207.626312 3.016005,207.737213 1.289067,206.252960 
			z"/>
			<path onClick={() => this.props.onFill(4)} fill={this.props.fillColors[4]} opacity="1.000000" stroke="black" 
				d="
			M1.000000,969.468628 
				C9.593619,967.266174 16.233046,962.042114 22.747938,956.674927 
				C30.691702,950.130554 38.525368,943.452393 46.389282,936.811523 
				C47.658844,935.739441 48.913254,934.630737 50.046848,933.419312 
				C55.580940,927.505676 55.723484,922.933533 50.639835,916.670654 
				C43.322159,907.655579 45.756691,900.723267 56.990192,897.742554 
				C65.745720,895.419312 73.852646,897.536926 79.500435,903.661133 
				C87.129349,911.933533 96.227859,912.045166 105.626518,910.789551 
				C118.829536,909.025818 131.803711,910.760010 144.853287,911.778564 
				C149.810059,912.165405 154.737274,912.916504 159.688095,913.397156 
				C169.004974,914.301697 174.186539,920.324951 177.458939,928.231140 
				C179.681198,933.600159 180.792023,939.352722 181.015549,945.232727 
				C181.315033,953.110962 184.002838,959.499817 191.090714,964.040161 
				C194.294327,966.092346 196.564804,969.774353 198.886337,972.986023 
				C201.702179,976.881531 203.934814,981.200439 206.775299,985.075623 
				C209.179947,988.356262 208.836029,992.573425 211.080215,995.888794 
				C211.984238,997.224304 212.332870,999.021912 213.651550,1000.084778 
				C215.929489,1001.921143 217.441757,1005.874268 221.332657,1003.903748 
				C224.682388,1002.207214 226.155075,996.082397 224.986984,992.356262 
				C223.470779,987.519653 219.842865,984.455811 216.379700,981.210510 
				C213.948410,978.932129 214.493439,983.176575 212.618027,982.819580 
				C211.992249,980.576965 210.844406,978.751221 208.651901,977.169617 
				C205.688629,975.032043 207.523514,970.863403 206.247375,967.789062 
				C205.756744,966.607056 206.313034,964.874634 205.648590,963.900085 
				C202.251572,958.917480 202.624542,954.076111 205.167221,948.859802 
				C207.865936,943.323425 212.643661,940.264038 217.919098,938.318726 
				C225.326904,935.587219 229.947525,930.545959 233.381531,923.722839 
				C236.137680,918.246704 235.741867,912.392395 234.563431,907.140869 
				C231.592255,893.900269 230.770950,880.988831 234.692719,867.797119 
				C236.523865,861.637634 233.605713,857.619629 227.114212,856.058594 
				C220.817520,854.544373 214.407715,853.500916 208.110077,851.990173 
				C194.802887,848.798035 188.413986,838.709900 190.679901,825.168152 
				C191.335144,821.252258 191.194519,817.238342 193.235321,813.591553 
				C197.798080,805.437988 205.217178,802.656799 213.979156,801.973572 
				C226.783417,800.975159 239.084976,803.999573 251.478119,806.352539 
				C257.854004,807.562988 264.156128,807.676575 270.708832,807.184265 
				C291.196106,805.644958 309.869720,811.141785 326.612183,823.835266 
				C329.930969,826.023743 329.856415,829.332031 330.073944,832.335083 
				C330.428131,837.224915 330.360779,842.143250 329.990662,847.472046 
				C329.431976,850.094238 327.669037,851.489502 326.426422,853.191223 
				C322.086548,859.134644 319.227448,865.652954 317.718506,872.867126 
				C316.954895,876.517883 316.129028,880.433838 311.298004,881.714722 
				C307.648132,881.133789 306.284576,878.057556 303.976715,876.239380 
				C300.644958,873.614502 297.044098,872.444824 292.969269,872.174072 
				C287.947815,871.840332 282.981049,872.356384 278.092102,873.388550 
				C268.195221,875.477966 261.750977,885.200256 263.357513,896.758362 
				C264.636444,905.959473 266.602417,915.112488 271.227142,923.415588 
				C273.857635,928.138367 277.237488,932.105164 281.578247,935.390381 
				C287.556366,939.914734 293.722076,944.223145 300.097107,948.070068 
				C308.022186,952.852417 316.895569,952.378662 325.777069,950.535950 
				C332.897766,949.058655 338.632904,946.092407 341.360077,938.880737 
				C341.712219,937.949463 342.460327,937.221252 343.618591,936.421997 
				C347.504547,935.213867 349.759094,937.729370 351.815125,939.653198 
				C358.734070,946.127502 365.465027,952.779114 370.027283,961.319031 
				C373.309753,967.463440 373.998596,973.895569 373.541718,980.647278 
				C372.668121,993.557068 370.020081,1006.229492 368.461914,1019.440674 
				C365.422241,1031.397095 363.072968,1043.103516 361.105194,1054.887085 
				C360.637817,1057.685791 360.081482,1060.453125 358.999176,1063.073975 
				C356.539276,1069.030273 352.598724,1072.834961 345.714386,1073.537842 
				C339.906647,1074.130981 334.118469,1072.725952 328.327576,1074.068481 
				C314.624451,1077.245239 304.318359,1085.306885 296.531097,1096.572632 
				C292.146362,1102.916016 294.886139,1111.335693 302.100433,1115.882568 
				C306.925323,1118.923462 312.284729,1120.770630 317.775635,1122.246216 
				C319.651123,1122.750244 321.329956,1122.417236 323.083862,1121.635620 
				C327.199402,1119.801514 331.588715,1118.770874 335.920166,1117.593994 
				C340.368866,1116.385376 344.505493,1116.816895 348.255066,1120.501343 
				C351.488617,1127.817627 350.214325,1135.024780 349.465210,1142.197021 
				C349.364166,1143.164551 348.995483,1144.118774 348.660461,1145.474854 
				C345.362152,1150.925293 340.585083,1153.660156 334.831573,1153.764526 
				C324.965546,1153.943848 317.889618,1157.715698 314.503052,1167.338501 
				C314.348236,1167.778320 313.864868,1168.096069 313.259277,1168.728027 
				C283.312439,1169.000000 253.624878,1169.000000 223.468658,1169.000000 
				C218.569672,1167.506592 219.336365,1162.655396 216.949051,1159.579590 
				C213.485184,1161.607300 214.226044,1166.171753 211.240204,1168.757812 
				C156.979111,1169.000000 102.958214,1169.000000 48.468658,1169.000000 
				C47.063427,1168.062134 45.989075,1167.177734 45.516457,1165.929077 
				C42.363644,1157.600342 35.387405,1155.770630 27.590923,1155.194580 
				C20.445492,1154.666748 13.416621,1156.131958 6.314285,1156.318604 
				C4.665016,1156.361816 3.001071,1156.793579 1.299306,1155.257080 
				C1.000000,1093.312500 1.000000,1031.624878 1.000000,969.468628 
			z"/>
			<path onClick={() => this.props.onFill(5)} fill={this.props.fillColors[5]} opacity="1.000000" stroke="black" 
				d="
			M1170.756470,340.751526 
				C1171.000000,345.024536 1171.000000,349.049103 1171.000000,353.536804 
				C1168.555176,356.915985 1165.196167,356.302643 1162.151123,355.928741 
				C1155.710327,355.137848 1149.342896,353.822662 1143.359985,351.169281 
				C1133.594849,346.838470 1123.487793,346.623657 1113.026733,347.736908 
				C1103.600830,348.739990 1094.077515,348.343140 1084.590088,347.693542 
				C1068.684570,346.604553 1054.197876,350.113983 1042.253296,361.367340 
				C1034.571289,368.604828 1028.734009,377.341919 1022.694763,385.879395 
				C1019.802185,389.968384 1016.646362,393.932800 1013.203186,397.377502 
				C1007.847351,402.735687 1000.414612,403.616913 993.147705,402.760651 
				C985.556396,401.866180 978.098816,399.985443 970.860718,397.468048 
				C964.637756,395.303741 959.041260,396.426819 953.889282,400.212097 
				C950.386292,402.785828 947.346069,405.923676 944.667114,409.314453 
				C941.048767,413.894257 941.386719,418.676758 945.383606,422.823975 
				C946.995483,424.496521 948.526184,426.364075 950.542297,427.527222 
				C965.041992,435.892609 977.301575,447.194824 990.280029,457.564178 
				C995.513611,461.745575 1001.386475,464.925293 1008.037048,466.340363 
				C1015.220520,467.868805 1019.969421,465.519379 1022.975708,458.749634 
				C1025.356567,453.388397 1026.814697,447.737518 1026.813965,441.827148 
				C1026.813599,438.460083 1027.272827,435.186646 1028.169556,431.972809 
				C1030.533325,423.501343 1038.893311,420.244293 1046.228516,425.172424 
				C1052.157349,429.155701 1056.060791,434.671661 1056.464600,442.056091 
				C1056.700317,446.367157 1057.303345,450.650513 1057.201172,454.992157 
				C1057.040527,461.828979 1059.592529,467.664276 1064.283813,472.580292 
				C1067.964111,476.436829 1071.656006,480.299713 1075.602051,483.871887 
				C1079.766968,487.642120 1082.141113,492.225403 1082.959595,497.663116 
				C1084.424072,507.393616 1089.111938,514.883057 1097.186768,520.717468 
				C1106.758789,527.633606 1116.397217,534.456055 1126.197144,541.025208 
				C1135.533447,547.283569 1145.974121,550.221130 1157.369385,549.947388 
				C1161.706177,549.843323 1166.379028,548.488159 1170.714600,551.734253 
				C1171.000000,561.020874 1171.000000,570.041809 1171.000000,579.531372 
				C1168.767090,582.015564 1166.310791,581.159180 1163.924194,580.470581 
				C1158.631958,578.943970 1153.693604,576.486389 1148.568237,574.528198 
				C1130.440186,567.602173 1112.743408,567.762939 1095.994751,578.607056 
				C1084.193604,586.247742 1070.662964,588.361084 1057.297729,590.979431 
				C1054.879639,591.453064 1052.370850,591.733643 1049.911621,592.107300 
				C1040.012207,593.611023 1032.128662,589.637695 1024.848755,583.492188 
				C1019.835388,579.260010 1016.730774,580.653015 1015.209045,587.075562 
				C1013.743652,593.260376 1014.554871,599.458008 1015.515808,605.401733 
				C1017.581055,618.176819 1014.848083,629.800903 1009.450806,641.190125 
				C1004.527893,651.578186 998.576904,661.413879 993.451111,671.693970 
				C985.253540,688.134644 981.586548,705.864014 978.099731,723.673706 
				C976.932007,729.638123 977.043945,735.535583 978.154236,741.509521 
				C979.568054,749.117249 983.380249,754.225647 991.275818,755.975220 
				C995.660278,756.946838 1000.121704,757.511597 1004.465759,758.659485 
				C1011.093506,760.410645 1017.471985,762.800354 1023.450806,766.177551 
				C1038.521606,774.690613 1049.405273,771.234436 1060.608765,760.609497 
				C1068.196899,753.413269 1076.361450,748.175415 1086.358765,745.147583 
				C1098.922607,741.342346 1098.699951,741.013062 1098.880615,727.614624 
				C1098.962769,721.528259 1098.074097,715.422668 1100.679565,709.406616 
				C1106.681885,695.547485 1117.022461,687.001160 1131.375732,683.114807 
				C1141.966675,680.247253 1152.636841,677.674744 1163.359009,675.333374 
				C1165.833130,674.793091 1168.324951,674.464233 1170.709351,676.729858 
				C1171.000000,689.354248 1171.000000,701.708435 1171.000000,714.531372 
				C1169.162720,717.314941 1166.383911,717.449219 1163.830566,717.989197 
				C1153.503540,720.173279 1145.703857,725.927307 1140.212158,734.805176 
				C1136.674561,740.524170 1137.049316,745.442200 1141.899292,750.135986 
				C1148.697266,756.714905 1155.913696,762.850952 1165.016357,766.196594 
				C1167.044189,766.941895 1169.046997,767.776550 1170.748169,769.751587 
				C1171.000000,775.354919 1171.000000,780.709900 1171.000000,786.532410 
				C1165.547729,789.698669 1160.251221,787.127563 1154.916260,786.460693 
				C1151.962280,786.091492 1149.121460,784.937683 1146.053589,785.293152 
				C1142.396362,785.716980 1140.295288,787.442322 1140.192505,791.378845 
				C1140.086792,795.422119 1141.070190,799.298950 1141.626831,803.241516 
				C1143.048950,813.315552 1142.284668,822.810547 1135.078857,830.890991 
				C1129.422485,837.234009 1121.252197,838.684570 1113.874390,834.516052 
				C1106.386719,830.285400 1102.909180,823.178528 1100.058350,815.608215 
				C1098.007202,810.161011 1096.162476,804.637878 1094.185181,799.162781 
				C1091.862549,792.731262 1084.753784,787.857727 1077.243530,788.948364 
				C1071.042603,789.848877 1064.999146,791.273254 1059.724976,794.404114 
				C1047.977417,801.377930 1035.515625,805.863953 1022.035095,808.100098 
				C1005.633362,810.820679 1005.265442,827.686768 1012.817810,836.037659 
				C1017.160645,840.839539 1021.486145,845.669250 1026.417236,849.939758 
				C1037.646606,859.664917 1045.810791,871.778503 1052.872681,884.710022 
				C1058.459595,894.940552 1063.726196,905.320740 1067.712646,916.298828 
				C1070.925903,925.147644 1077.415894,928.913208 1086.588135,928.862732 
				C1099.679688,928.790710 1112.495239,926.798035 1124.722900,922.262573 
				C1129.963257,920.318848 1134.621948,916.963623 1140.505981,916.553589 
				C1142.981323,916.381104 1145.166382,916.160767 1146.810181,918.312866 
				C1148.654907,920.728333 1148.414185,923.370789 1147.135254,925.866394 
				C1144.774658,930.472900 1141.559326,934.533752 1138.556152,938.726746 
				C1131.678345,948.329895 1124.079590,956.955872 1112.090698,960.428223 
				C1107.450562,961.772156 1102.607300,962.127625 1098.060181,963.885925 
				C1091.396240,966.462830 1089.473511,969.357422 1091.511963,976.130005 
				C1094.347046,985.549866 1100.312378,992.788879 1108.551270,998.112854 
				C1111.888062,1000.269165 1115.142944,999.917114 1118.235352,997.392761 
				C1121.071777,995.077576 1124.114624,993.053223 1127.213013,991.090515 
				C1135.005615,986.154358 1143.791992,988.037720 1148.910522,995.788757 
				C1150.481323,998.167603 1151.470825,1000.824707 1152.861938,1003.289673 
				C1156.059326,1008.955139 1160.391235,1013.412903 1166.157593,1016.434937 
				C1167.977173,1017.388550 1169.469849,1018.643188 1170.744873,1020.718323 
				C1171.000000,1035.687622 1171.000000,1050.375122 1171.000000,1065.531372 
				C1169.026123,1068.219971 1166.608154,1067.986816 1164.088379,1067.270142 
				C1152.153442,1063.875977 1141.250000,1058.203125 1130.552124,1052.082397 
				C1124.488892,1048.613281 1118.600342,1044.856079 1112.455322,1041.508789 
				C1108.034424,1039.100708 1103.494019,1038.076416 1098.583008,1038.176636 
				C1087.890381,1038.394897 1077.356689,1037.308105 1066.872070,1035.092529 
				C1057.794434,1033.174194 1049.876587,1028.356567 1041.161377,1025.583374 
				C1037.825317,1024.521851 1034.450806,1023.687683 1031.058105,1022.852417 
				C1027.093994,1021.876343 1023.636719,1022.681030 1019.978577,1024.315674 
				C1013.434082,1027.239990 1006.638916,1029.467163 999.273926,1029.097534 
				C991.260803,1028.695557 984.360840,1022.168884 984.000671,1014.092224 
				C983.771423,1008.950989 983.480347,1003.799744 983.678650,998.632996 
				C983.976685,990.864258 979.612610,985.115967 974.898560,979.634583 
				C970.963867,975.059448 966.163086,971.371216 961.230652,968.019958 
				C948.929688,959.662415 943.998962,947.394897 941.881165,933.432983 
				C940.196899,922.330139 940.865173,911.202637 941.219849,900.056763 
				C941.584167,888.606628 938.641846,877.666504 935.344849,866.824646 
				C931.765381,855.054138 927.893188,843.377686 927.788086,830.839966 
				C927.698547,820.164978 931.065918,810.534790 936.092773,801.409973 
				C941.312317,791.935181 947.031982,782.728210 952.951416,773.687134 
				C959.782227,763.253845 962.472778,751.784912 962.716309,739.649048 
				C962.949158,728.041260 965.995178,717.017700 968.422668,705.804993 
				C972.959595,684.848572 981.150208,665.223877 989.119934,645.472351 
				C994.332886,632.552979 996.900269,619.054016 996.873413,605.044922 
				C996.845642,590.597900 995.790405,576.070984 999.815918,561.867493 
				C1001.039490,557.550354 1001.944519,553.122986 1003.515381,548.923218 
				C1008.630798,535.246704 1007.789001,521.334106 1006.645203,507.077423 
				C1005.208496,489.167603 993.197327,479.383057 981.096558,469.024292 
				C966.500732,456.529663 950.534180,446.037048 934.413086,435.720367 
				C929.153076,432.354218 924.919556,427.927399 921.347961,422.821106 
				C915.693604,414.736969 916.007324,404.829803 923.293030,398.312622 
				C930.733887,391.656616 937.584839,384.397247 945.199280,377.912109 
				C952.095581,372.038574 959.777283,371.182892 968.120850,372.224823 
				C969.590576,372.408325 971.106018,372.648956 972.525879,373.060455 
				C991.750793,378.631897 1005.846252,370.668335 1017.358154,356.053864 
				C1022.124207,350.003326 1025.804565,343.314056 1028.430664,336.034210 
				C1030.683472,329.789673 1030.185547,323.929749 1026.526001,318.386444 
				C1024.121582,314.744385 1022.547852,310.738159 1020.942322,306.704437 
				C1016.496460,295.534454 1019.687561,286.155762 1027.515503,277.911102 
				C1037.831421,267.045959 1050.497070,259.338654 1063.295654,251.860504 
				C1067.164551,249.599960 1071.082153,247.390106 1074.980713,245.218826 
				C1083.205933,240.637955 1091.384766,240.320724 1098.605103,247.870178 
				C1105.167969,254.732056 1112.061523,255.654816 1118.092163,250.946030 
				C1121.225342,248.499603 1124.107544,245.687881 1126.331055,242.299988 
				C1128.619141,238.813812 1130.802368,235.247787 1132.312622,231.369644 
				C1136.731079,220.023254 1144.967041,212.425659 1155.634766,207.141068 
				C1158.171997,205.884186 1160.644653,204.464371 1163.299438,203.485123 
				C1165.611938,202.632126 1168.034546,201.593033 1170.699341,203.737732 
				C1171.000000,213.687561 1171.000000,223.375122 1171.000000,233.531342 
				C1169.635986,235.464584 1168.139038,236.641464 1166.262573,237.527832 
				C1159.884033,240.540970 1154.417114,244.499878 1150.402954,250.672821 
				C1145.766724,257.802490 1141.203125,264.920380 1138.145264,272.860291 
				C1135.011841,280.996002 1137.962524,286.067474 1146.357056,287.937988 
				C1151.727417,289.134644 1157.080444,290.151489 1162.626343,289.952026 
				C1165.487183,289.849091 1168.281982,290.324005 1170.732544,292.746277 
				C1171.000000,299.021149 1171.000000,305.042297 1171.000000,311.531738 
				C1169.898315,313.366638 1168.408081,313.923218 1166.739258,314.355743 
				C1164.813232,314.854919 1162.837402,315.168732 1160.961182,315.895538 
				C1157.049194,317.410889 1152.694336,318.683655 1152.190063,323.667603 
				C1151.683350,328.674927 1155.441772,331.401001 1159.023560,333.880493 
				C1161.063232,335.292450 1163.373291,336.321930 1165.761841,337.100525 
				C1167.701782,337.732849 1169.436157,338.661163 1170.756470,340.751526 
			z"/>
			<path onClick={() => this.props.onFill(6)} fill={this.props.fillColors[6]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,203.531342 
				C1154.742065,207.925949 1141.470459,215.726242 1134.572998,231.900360 
				C1131.548706,238.992065 1127.573853,245.393875 1121.843018,250.757263 
				C1114.270752,257.844055 1105.518555,257.954742 1098.076904,250.576935 
				C1090.928955,243.490295 1083.504150,241.667694 1076.713379,246.562881 
				C1072.610107,249.520767 1068.141357,251.550522 1063.883301,254.076904 
				C1051.437378,261.461456 1039.125610,269.053345 1028.962402,279.561401 
				C1025.694092,282.940613 1023.343933,286.874634 1021.891357,291.406769 
				C1019.341492,299.362610 1022.050232,306.815765 1026.199219,312.742798 
				C1034.744019,324.949707 1032.408813,336.058258 1025.712158,347.649628 
				C1018.348389,360.395721 1008.983704,370.793579 994.597473,375.748718 
				C987.503967,378.191986 980.498413,376.918640 973.461121,375.639069 
				C970.522461,375.104706 967.600037,374.462646 964.646423,374.032532 
				C955.475342,372.697052 948.633789,377.217590 942.414307,383.112000 
				C937.105347,388.143524 931.990540,393.355591 926.474548,398.196747 
				C916.978333,406.531372 917.367676,415.988068 926.885254,426.392212 
				C932.935059,433.005554 940.669312,437.335052 948.127747,441.973785 
				C966.566467,453.441559 983.233093,467.082977 998.739685,482.220551 
				C1006.466309,489.763275 1007.499695,499.644775 1009.096130,509.566345 
				C1011.771301,526.192261 1008.521423,541.865479 1003.424622,557.557800 
				C1000.351013,567.020935 998.744690,576.857239 998.911926,586.988708 
				C999.155884,601.763428 999.835754,616.592957 996.081360,631.160583 
				C993.245361,642.164612 988.921936,652.589722 984.502319,662.998474 
				C974.895020,685.624512 968.918823,709.290710 965.234680,733.492554 
				C964.147461,740.634766 964.540955,748.101990 963.292603,755.269165 
				C961.329590,766.539795 954.189819,775.453979 948.591492,785.040222 
				C944.239441,792.492432 939.494812,799.689514 935.594604,807.436829 
				C927.947815,822.626099 929.152588,837.945251 933.690552,853.549255 
				C936.753357,864.080750 940.067932,874.573303 942.374878,885.276184 
				C943.566895,890.806274 943.589233,896.676392 943.258423,902.516846 
				C942.343689,918.666748 942.391968,934.813354 948.664612,950.171631 
				C951.193909,956.364502 955.835693,960.908203 960.974121,964.977783 
				C964.748474,967.967102 968.662903,970.787476 972.339172,973.891235 
				C982.543152,982.506104 987.626648,993.249207 986.082092,1006.867981 
				C985.713684,1010.116333 985.464783,1013.551331 986.507812,1016.791260 
				C988.962341,1024.415894 996.212463,1028.567871 1004.807617,1026.715210 
				C1010.115112,1025.571289 1015.446838,1024.372192 1020.350342,1021.586853 
				C1026.452759,1018.120483 1032.702759,1020.715881 1038.737061,1022.378418 
				C1049.780396,1025.420898 1059.607666,1031.837402 1071.162842,1033.686523 
				C1078.730225,1034.897583 1086.321533,1036.354858 1093.897339,1035.841797 
				C1104.285889,1035.138428 1113.069824,1038.428101 1121.497437,1043.945679 
				C1133.062378,1051.517456 1145.271240,1057.915649 1158.122314,1063.052490 
				C1161.997559,1064.601562 1165.935425,1065.894653 1170.582153,1065.999023 
				C1171.000000,1074.020874 1171.000000,1082.041870 1171.000000,1090.531372 
				C1169.354614,1092.808105 1167.353516,1092.956421 1165.056763,1092.556030 
				C1156.843018,1091.124634 1148.822754,1089.041504 1141.022095,1086.071655 
				C1134.802490,1083.703735 1128.564819,1081.263916 1121.809204,1080.959595 
				C1115.532227,1080.677002 1110.709106,1082.524658 1107.681641,1088.863647 
				C1104.590942,1095.335449 1099.884155,1100.651367 1093.470337,1104.514648 
				C1082.269531,1111.261230 1081.494019,1114.328735 1087.355347,1125.909180 
				C1093.248169,1137.552002 1094.911255,1150.251709 1096.983154,1162.858276 
				C1097.305542,1164.819702 1097.213257,1166.888550 1095.264404,1168.715210 
				C1079.979126,1169.000000 1064.958130,1169.000000 1049.468628,1169.000000 
				C1048.982788,1160.683960 1051.677124,1152.649536 1051.116577,1144.209717 
				C1050.525757,1135.312378 1044.934570,1130.698364 1036.140503,1132.397095 
				C1028.225830,1133.926025 1021.664917,1138.074951 1015.184265,1142.420898 
				C1013.086731,1143.827515 1010.963257,1145.042236 1008.633545,1145.966675 
				C1004.941589,1147.431396 1001.105469,1147.604126 998.520142,1144.449585 
				C995.566772,1140.845947 997.161804,1136.485840 999.427368,1133.358398 
				C1003.912964,1127.166138 1006.344055,1120.607788 1006.013489,1112.971436 
				C1005.594299,1103.288330 1006.552795,1093.763916 1008.619324,1084.254395 
				C1011.322510,1071.814819 1007.512329,1064.973633 996.226746,1059.023804 
				C989.478027,1055.465820 981.843506,1053.574463 975.947571,1048.417603 
				C968.117065,1041.568848 964.459961,1032.310547 961.119019,1022.827698 
				C958.949707,1016.670471 958.209900,1010.231689 957.046631,1003.889404 
				C955.150391,993.550171 950.661926,984.402283 945.354675,975.475586 
				C937.735596,962.660522 934.356934,948.586182 932.869934,933.800110 
				C931.413330,919.315857 932.632446,904.843872 931.940796,890.391907 
				C931.404663,879.189636 928.064514,868.475891 924.994507,857.788818 
				C922.455688,848.950867 920.635864,839.984924 920.150085,830.881409 
				C919.629395,821.124268 922.372986,811.803406 926.091125,802.805847 
				C931.125610,790.622437 937.740662,779.250916 944.111755,767.758179 
				C949.935852,757.251831 952.346619,745.777710 954.552612,734.114380 
				C960.346313,703.482300 969.510254,673.799194 980.181213,644.523682 
				C984.982117,631.352600 987.516235,617.560059 986.971619,603.423157 
				C986.121948,581.370605 989.209290,559.713318 992.635925,538.018188 
				C994.608154,525.531494 993.718689,512.783325 991.431824,500.411774 
				C989.164978,488.148743 980.044250,480.094513 971.221680,472.092285 
				C956.838257,459.046356 939.530579,450.161407 924.059021,438.674622 
				C914.034790,431.232178 906.500610,421.525787 899.647400,411.246918 
				C894.394958,403.368958 890.046204,394.861786 883.772400,387.700165 
				C879.238098,382.524048 873.198547,379.172699 867.571411,375.356293 
				C861.884033,371.499084 862.171265,371.659393 864.702881,365.116913 
				C868.876709,354.330414 875.616455,345.504211 883.832153,337.543671 
				C886.884277,334.586304 890.775330,333.702148 894.330994,332.001282 
				C896.061462,331.173584 902.956909,336.716064 904.054810,339.875549 
				C906.509338,346.939392 908.887939,354.039215 910.969238,361.219452 
				C912.182800,365.406097 914.428955,367.181793 918.862427,367.087280 
				C923.537354,366.987549 927.980835,365.539032 931.643433,363.382416 
				C945.507080,355.219208 959.737610,356.327606 974.613281,359.477203 
				C982.570984,361.162048 990.872986,361.010376 997.713745,354.838196 
				C1004.330994,348.867615 1006.314392,341.492035 1004.473511,333.105499 
				C1001.944397,321.583618 999.286926,310.116028 998.979065,298.241791 
				C998.626648,284.647858 1001.550171,271.523773 1004.369568,258.369720 
				C1005.415039,253.491776 1006.091003,248.586243 1006.044434,243.548431 
				C1005.915283,229.578674 1007.459656,215.757202 1009.652466,201.959808 
				C1010.506226,196.587982 1009.975830,190.988525 1009.991211,185.492508 
				C1010.046875,165.639435 1020.336243,154.172821 1039.996704,150.820068 
				C1055.511841,148.174194 1070.917969,144.808365 1086.264771,141.292633 
				C1092.678223,139.823410 1099.144287,139.574097 1105.539062,139.559906 
				C1110.727661,139.548370 1114.732178,143.043396 1116.834351,148.022079 
				C1118.968506,153.076324 1120.847656,158.238098 1122.968750,163.298126 
				C1125.959961,170.433395 1131.539551,173.170761 1138.719360,170.475708 
				C1149.833252,166.304001 1157.011719,157.880432 1159.251709,146.569397 
				C1161.105225,137.209641 1157.645020,128.015762 1154.458008,119.056007 
				C1152.092285,112.405296 1151.688110,105.562874 1154.998169,98.878716 
				C1158.765381,91.271408 1162.434814,83.612968 1165.991577,75.905174 
				C1168.105225,71.324791 1166.017700,63.229931 1161.851318,60.619961 
				C1154.929932,56.283974 1147.682861,54.056694 1139.112793,55.583076 
				C1128.732910,57.431808 1118.880737,60.945652 1108.778442,63.639263 
				C1097.825684,66.559631 1088.350586,64.435654 1079.572144,57.252018 
				C1075.936035,54.276463 1072.024658,51.509361 1067.526367,49.559498 
				C1060.263428,46.411312 1054.367798,48.391975 1048.570435,52.875233 
				C1043.375488,56.892551 1038.897217,62.043900 1031.798950,63.125301 
				C1025.200195,64.130608 1021.091370,62.138378 1018.076172,56.223801 
				C1014.509155,49.226837 1013.285522,41.625725 1013.143921,34.023743 
				C1012.943176,23.245977 1014.651855,12.538566 1015.977417,1.419334 
				C1021.354919,1.000000 1026.709839,1.000000 1032.532471,1.000000 
				C1034.810913,2.599892 1035.680054,4.647967 1036.366455,6.958744 
				C1038.057983,12.653193 1040.581055,14.809093 1046.439453,14.725029 
				C1057.735352,14.562934 1068.574341,12.379193 1077.890503,5.434139 
				C1080.492798,3.494079 1083.355103,2.331495 1086.696167,1.214939 
				C1090.030640,1.000000 1093.061157,1.000000 1096.545898,1.000000 
				C1100.121948,2.534269 1101.332153,5.274365 1102.128784,8.493041 
				C1103.844971,15.427177 1104.752808,22.505535 1106.160156,29.492056 
				C1107.664185,36.957500 1112.636475,41.161583 1120.345337,41.758957 
				C1127.415039,42.306793 1134.249268,40.948593 1141.018433,39.300922 
				C1148.275269,37.534531 1155.607788,36.138527 1162.880859,34.462437 
				C1165.511475,33.856201 1168.122681,33.342255 1170.699707,35.718712 
				C1171.000000,91.687561 1171.000000,147.375122 1171.000000,203.531342 
			z"/>
			<path onClick={() => this.props.onFill(7)} fill={this.props.fillColors[7]} opacity="1.000000" stroke="black" 
				d="
			M700.680664,1083.733398 
				C701.027954,1098.503418 701.826904,1112.934937 702.259216,1127.366089 
				C702.665344,1140.923218 703.234009,1154.508789 704.000000,1168.534424 
				C594.312439,1169.000000 484.624878,1169.000000 374.468658,1169.000000 
				C372.264008,1167.449097 372.507599,1165.473389 372.703369,1163.440796 
				C373.914764,1150.865234 376.424408,1138.443237 377.347656,1125.844360 
				C378.500702,1110.109985 383.256500,1094.675659 381.711060,1078.751831 
				C380.742523,1068.772339 380.392731,1058.783203 380.940735,1048.835571 
				C381.755280,1034.049683 384.149811,1019.469482 388.596527,1005.257690 
				C391.653290,995.488342 398.021790,989.025269 407.248199,985.083435 
				C417.799561,980.575562 428.359375,976.123840 438.691772,971.080994 
				C446.577087,967.232422 454.948639,964.322998 464.145355,967.764709 
				C474.370667,971.591431 480.492523,981.854492 478.726562,992.712280 
				C477.795532,998.436523 475.911652,1004.113708 476.506714,1009.968872 
				C477.760010,1022.300659 484.674377,1028.916870 500.142883,1032.689087 
				C509.465881,1034.962524 518.935608,1034.176392 528.396667,1033.084839 
				C539.844421,1031.764038 551.301697,1032.320679 562.664917,1034.089233 
				C572.586670,1035.633301 582.186768,1034.234985 591.779480,1031.821411 
				C608.521729,1027.608765 623.143494,1018.992798 637.491455,1009.864929 
				C653.079651,999.947998 669.032166,990.600891 684.231567,980.075562 
				C685.775940,979.006104 687.288452,978.027344 689.659180,978.708618 
				C694.484192,987.767334 695.826721,997.451416 696.923218,1007.117615 
				C698.526306,1021.249146 698.430664,1035.507446 699.379761,1049.666260 
				C700.135132,1060.934448 699.017273,1072.204712 700.680664,1083.733398 
			z"/>
			<path onClick={() => this.props.onFill(8)} fill={this.props.fillColors[8]} opacity="1.000000" stroke="black" 
				d="
			M690.176941,978.568359 
				C673.434265,990.534851 656.012939,1000.905212 638.855042,1011.681274 
				C624.922546,1020.431763 610.838379,1028.742432 594.749084,1033.380737 
				C581.639404,1037.159912 568.647156,1037.766479 555.368286,1035.598145 
				C546.132629,1034.090088 536.935852,1034.688843 527.691895,1035.552734 
				C513.262939,1036.901489 499.027008,1037.385010 485.754822,1029.499268 
				C477.095428,1024.354126 473.089172,1013.441284 474.344696,1004.296265 
				C475.279022,997.490601 477.822968,990.919983 476.079041,983.709717 
				C473.872467,974.586548 464.557709,967.137329 453.016632,968.862854 
				C441.841187,970.533569 432.889069,977.285889 422.700653,981.166565 
				C417.427521,983.175049 412.274567,985.497131 407.044678,987.621887 
				C395.168365,992.447144 390.747559,1002.741516 388.265228,1014.053833 
				C384.340790,1031.938354 381.920532,1050.032837 383.019928,1068.426880 
				C383.227356,1071.897461 383.727692,1075.349609 383.988983,1078.817993 
				C384.934448,1091.366577 382.375580,1103.619141 380.804749,1115.956665 
				C379.173492,1128.768433 377.622162,1141.590332 375.991821,1154.402222 
				C375.406830,1158.999512 374.693481,1163.580566 374.019348,1168.584473 
				C368.311951,1169.000000 362.623932,1169.000000 356.467957,1169.000000 
				C353.711548,1167.202881 353.836487,1164.695679 354.042297,1161.733398 
				C359.745728,1144.122803 362.305267,1126.700562 358.273071,1108.353271 
				C357.050690,1099.590088 358.263092,1091.384399 359.896820,1083.208740 
				C362.837036,1068.495239 363.002289,1053.477783 364.708679,1038.628052 
				C365.031494,1035.818848 365.693542,1033.083252 365.970642,1029.858154 
				C368.556274,1020.841492 369.805847,1011.956299 370.678955,1003.105408 
				C372.020721,989.503235 377.483032,977.911072 385.936310,967.498535 
				C388.883331,963.868469 391.206268,959.827026 393.837250,955.990112 
				C398.998749,948.462585 398.097290,940.580933 394.637695,932.794617 
				C393.251709,929.675232 393.324341,926.729004 393.931335,923.561035 
				C394.593445,920.105286 396.282349,917.542786 399.920044,917.142761 
				C407.420837,916.317871 410.295593,911.231201 411.535248,904.762817 
				C412.983734,897.204773 413.438080,889.599792 411.735291,881.971436 
				C410.554932,876.683533 407.753418,872.862488 402.514954,871.121033 
				C393.588013,868.153320 385.100037,864.469177 378.643280,857.250000 
				C371.453766,849.211609 366.002533,840.401001 366.983856,829.061829 
				C367.830780,819.275208 373.200745,811.686035 379.620056,804.734497 
				C385.901154,797.932434 391.842224,790.860046 392.604340,781.153198 
				C393.308289,772.186890 393.226044,763.153320 392.523407,754.196350 
				C391.979156,747.258240 392.564056,740.551758 394.989471,734.142395 
				C397.105865,728.549744 396.635864,723.805359 393.522339,718.589539 
				C389.136414,711.242432 386.861084,703.091858 386.772919,694.358398 
				C386.694092,686.552979 387.183044,678.755981 386.176331,670.928528 
				C384.811981,660.319702 379.745880,651.798584 372.577881,644.307007 
				C369.574493,641.168091 366.832703,637.827087 364.238800,634.378906 
				C354.357300,621.242981 356.014832,603.449524 365.801788,590.722473 
				C373.668243,580.492981 378.954865,569.570557 377.829437,556.277649 
				C376.690186,542.822205 379.916199,529.836609 382.118134,516.731445 
				C383.448914,508.810913 383.165680,500.805328 382.297791,492.855408 
				C380.376404,475.255737 380.075470,457.696533 383.052856,440.174896 
				C383.313568,438.640778 383.917999,437.315308 385.567383,436.164429 
				C387.221649,430.343231 386.997101,424.653076 387.047119,418.518677 
				C390.937775,417.545654 392.341156,420.643677 393.544067,423.136475 
				C397.391266,431.109131 400.044250,439.443359 400.023438,448.476532 
				C399.981750,466.575134 399.774780,484.671204 401.003021,502.759308 
				C401.496399,510.025177 401.232117,517.326477 400.267059,524.647400 
				C398.613922,537.189026 398.625946,549.800659 399.947388,562.441589 
				C401.315521,575.528870 397.334717,586.990662 388.339172,596.750977 
				C385.316376,600.030762 382.556458,603.561401 379.356995,606.690063 
				C370.978210,614.883545 372.595032,625.620056 378.663971,634.239746 
				C383.278046,640.793152 389.756805,645.127686 395.640228,650.760254 
				C404.322357,658.402832 414.065155,658.217712 424.580322,655.939819 
				C432.044373,654.005615 439.383698,653.314819 446.331268,651.123474 
				C455.496948,648.232361 463.277252,642.723450 470.603027,636.679993 
				C479.883698,629.023865 489.533905,622.216858 501.184662,618.432922 
				C506.672363,616.650696 511.516296,613.226196 516.024780,609.503235 
				C524.633057,602.394531 531.407959,602.178101 540.992615,608.317139 
				C544.784729,610.745911 548.151550,613.721497 551.898804,616.190918 
				C553.333801,617.136658 554.348572,618.433838 554.426086,620.620361 
				C552.936218,622.920715 550.941650,622.842102 548.930054,622.443665 
				C540.569519,620.787109 533.116150,623.129456 526.135620,627.557068 
				C516.106201,633.918518 505.407440,638.706543 494.143799,642.565186 
				C479.039825,647.739258 464.593079,654.652344 449.790558,660.639893 
				C447.790009,661.449036 445.871185,662.484070 444.050873,663.666382 
				C436.871796,668.329041 435.532745,674.396484 440.126556,681.557312 
				C445.127899,689.353333 452.176941,695.040100 460.303162,698.977844 
				C475.407928,706.297180 487.361389,716.980469 496.791870,730.681702 
				C499.050903,733.963806 502.218750,736.050354 505.672302,737.748047 
				C510.521149,740.131531 515.128967,739.943359 519.787476,736.790527 
				C525.354980,733.022278 529.110779,727.835754 532.375427,722.189514 
				C538.150330,712.201599 544.794983,702.766724 552.993835,694.726562 
				C563.580933,684.344299 575.369934,675.330750 588.524109,668.312317 
				C602.100281,661.068665 614.905273,652.945618 625.313110,641.273193 
				C631.629456,634.189331 638.576477,634.342529 646.007263,640.478088 
				C652.289551,645.665283 657.401672,651.892944 660.048584,659.572144 
				C667.739746,681.885437 679.780701,702.107788 690.028259,723.191467 
				C691.110962,725.419006 692.546875,727.574585 691.563843,730.585327 
				C688.256531,732.820801 686.980530,730.166138 685.341553,728.454834 
				C681.730042,724.683716 679.131409,719.807373 673.458374,717.792297 
				C668.968018,714.910706 666.577576,710.504944 662.885803,706.811523 
				C656.002563,698.431030 652.776733,688.518738 648.248657,679.292908 
				C648.102905,678.995789 648.039307,678.659607 647.910767,678.353027 
				C642.016907,664.284302 634.937866,661.977417 621.809692,669.793152 
				C610.374512,676.601074 598.229858,682.143860 587.031738,689.384583 
				C569.446838,700.755188 555.582581,715.387451 546.687866,734.532898 
				C544.309509,739.652161 541.923035,744.797791 538.275757,749.230103 
				C528.440796,761.181885 515.564087,764.242737 500.988373,761.763184 
				C492.164703,760.262207 484.311218,760.128967 476.907379,766.967529 
				C470.436005,772.944702 461.771637,772.085876 453.589600,770.204956 
				C445.809967,768.416504 438.304504,765.611023 430.478516,763.951965 
				C426.217834,763.048706 421.898987,762.434021 419.008698,766.288818 
				C415.982025,770.325562 417.056915,774.864075 419.191711,779.073242 
				C422.665894,785.923401 428.622223,790.564209 434.035675,795.718323 
				C440.987610,802.337158 446.491394,809.902283 449.138519,819.378723 
				C451.245514,826.921387 449.676575,833.476746 443.899689,838.442139 
				C437.160553,844.234619 435.199005,851.258179 437.187866,859.456421 
				C439.740417,869.978149 439.125580,880.626648 439.111053,891.255188 
				C439.095734,902.462463 441.449524,912.998047 446.908630,922.852966 
				C448.711609,926.107788 450.977905,928.989868 453.509674,931.579041 
				C459.475067,937.679688 468.569397,936.903015 477.036163,930.427612 
				C484.082794,925.038391 489.694092,918.171387 496.501617,912.530273 
				C506.730133,904.054321 518.455933,898.769836 531.042236,895.194336 
				C550.901489,889.552673 570.715942,883.783142 589.312744,874.627319 
				C598.559998,870.074707 607.637085,865.168457 616.820557,859.706543 
				C626.705078,855.751587 635.262756,850.081726 644.245605,845.215393 
				C645.259399,844.666077 646.256287,844.075867 647.597046,843.347046 
				C648.379822,842.979736 648.844543,842.812500 649.681763,842.594849 
				C650.286682,842.499023 650.531311,842.488403 651.139160,842.436890 
				C658.379700,840.453735 663.918884,835.978088 670.049561,832.620239 
				C672.126709,831.482544 673.957214,829.399536 677.037354,830.819824 
				C678.745605,834.262085 676.441895,836.083496 674.629456,837.935547 
				C665.411621,847.354492 655.223755,855.634705 644.518982,863.294922 
				C613.781250,885.290588 579.813538,899.949829 543.021667,908.379028 
				C532.794312,910.722168 522.851440,914.115906 513.972229,920.024475 
				C505.712555,925.520813 499.763641,933.050476 495.297394,941.720459 
				C492.384491,947.375061 491.610352,953.457275 494.420410,959.520691 
				C499.863953,971.266357 501.661163,983.542786 500.687897,996.443054 
				C500.047546,1004.929932 508.985016,1010.097595 518.368896,1007.164856 
				C524.026001,1005.396851 528.966125,1002.222168 533.515015,998.636597 
				C546.570312,988.345825 558.866211,977.217041 570.355347,965.148926 
				C580.477478,954.516785 593.531555,947.917480 606.349365,941.223328 
				C610.611206,938.997559 614.885376,936.796631 619.064209,934.414001 
				C619.495178,934.168274 619.850830,933.780518 620.304260,933.576599 
				C623.448242,932.162903 627.016479,929.605957 629.883850,932.913513 
				C632.443237,935.865906 629.340637,938.658447 627.835693,941.375977 
				C623.095459,949.935486 618.898438,958.769470 617.010925,968.438354 
				C615.747131,974.912476 620.219055,979.717163 626.406494,978.407776 
				C644.593628,974.559021 661.886414,968.095337 677.730347,958.373047 
				C689.985535,950.852966 690.980103,938.876465 680.979797,928.580139 
				C677.494202,924.991394 673.830383,921.625671 669.628845,918.859253 
				C663.398132,914.756897 661.955688,910.340515 664.911072,903.493103 
				C667.939819,896.476013 672.989014,890.994019 678.772034,886.084778 
				C692.249390,874.643921 708.293335,866.739258 721.603516,855.124329 
				C735.732117,842.795349 750.821289,831.403137 761.374695,815.435547 
				C765.108765,809.785889 766.557373,803.627686 766.842285,797.048889 
				C767.539673,780.945496 764.276306,765.302917 761.311951,749.622803 
				C756.904236,726.308228 748.050537,704.322632 740.992004,681.805298 
				C736.860962,668.626709 733.283203,655.293213 729.274658,642.082825 
				C728.527222,639.619873 727.517090,636.939087 729.513428,634.099854 
				C732.478210,633.538391 733.212402,635.600647 733.832092,637.444397 
				C743.901489,667.403625 756.244751,696.546082 765.812744,726.686279 
				C773.056885,749.505859 777.694275,772.717041 777.164856,796.794006 
				C776.850891,811.072632 772.196228,823.593628 761.919922,833.612610 
				C743.093567,851.967651 723.561035,869.515442 702.240295,885.003418 
				C695.952148,889.571289 689.644287,894.186646 686.686401,902.076660 
				C684.775208,907.174561 684.476318,912.140869 685.769714,917.208923 
				C687.003113,922.041443 689.224792,926.525818 691.264343,931.093567 
				C696.427612,942.657593 699.551208,954.618530 695.288818,967.671387 
				C693.515442,971.427368 691.918335,974.839966 690.176941,978.568359 
			z"/>
			<path onClick={() => this.props.onFill(9)} fill={this.props.fillColors[9]} opacity="1.000000" stroke="black" 
				d="
			M327.700928,849.408997 
				C328.228455,840.969116 328.121216,832.908020 326.927917,824.450684 
				C330.441193,809.799561 330.713776,795.120544 333.039124,780.734314 
				C334.505737,771.660828 337.367065,762.933594 340.880035,754.510132 
				C346.226715,741.689819 347.573608,729.107239 342.218079,715.850159 
				C337.729523,704.739197 336.812805,692.638062 334.066376,681.031616 
				C331.658936,670.857666 326.319824,662.008301 322.158844,652.614319 
				C318.182709,643.637451 311.560852,636.075867 308.395538,626.731812 
				C305.912018,619.400391 303.950348,611.893616 301.647156,604.498901 
				C300.256653,600.034546 297.066193,596.324585 294.198853,593.116150 
				C283.693726,581.361450 274.241913,568.563538 262.395203,558.109436 
				C253.774567,550.502075 242.711685,547.420349 231.865295,544.094666 
				C214.410797,538.742981 196.400986,537.591553 178.400665,535.831238 
				C166.385010,534.656189 154.473206,532.216309 142.587524,529.960083 
				C122.221527,526.094116 101.625336,523.662170 81.240456,519.996155 
				C54.978870,515.273315 28.583181,513.203369 1.468657,514.000000 
				C1.000000,485.312439 1.000000,456.624878 1.000000,427.468658 
				C2.473453,425.414154 4.242515,425.083069 6.401907,425.178406 
				C17.999550,425.690369 29.585594,426.416718 41.189327,426.852814 
				C54.101524,427.338135 66.635704,425.564148 79.135818,422.595093 
				C86.517097,420.841888 94.006210,421.440186 101.302643,423.527130 
				C105.799416,424.813354 110.377594,425.492615 115.442078,426.034180 
				C122.488747,428.327057 129.361618,427.551575 136.527267,427.912994 
				C150.161545,435.498871 164.882080,439.158051 179.178192,443.946472 
				C183.627014,445.436584 186.872574,445.192810 190.518326,441.089966 
				C194.372360,436.752594 199.712799,433.573151 205.353195,431.323944 
				C207.782532,430.355194 210.429825,429.626862 212.508942,427.202057 
				C224.406570,423.827881 235.341476,419.013428 246.216415,414.025940 
				C257.159943,409.006958 268.441895,409.075378 279.767822,412.375824 
				C288.588593,414.946228 296.903534,413.960693 304.803650,409.501587 
				C312.195160,405.329529 320.099365,404.248444 328.407623,404.898987 
				C331.467346,405.138550 334.192963,406.213654 336.919220,407.499817 
				C341.573212,409.695312 346.089355,412.165985 350.815674,414.221710 
				C359.404724,417.957489 364.279785,426.660217 364.350708,436.097809 
				C364.417999,445.047516 362.390839,453.523987 360.115631,462.004425 
				C359.500580,464.296844 359.556030,466.609039 359.058136,469.314514 
				C355.038239,477.238159 351.293854,478.453156 341.671417,475.000549 
				C339.698029,471.252319 335.884216,471.148376 333.024292,469.878510 
				C322.593140,465.246826 311.970459,461.847534 300.658752,467.683716 
				C288.348969,474.034851 283.286163,489.294464 289.810669,501.999817 
				C292.150970,511.853455 295.686646,520.833252 300.685547,529.645386 
				C303.940216,536.551208 309.779572,540.105103 316.032837,542.596985 
				C321.804199,544.896729 327.921814,546.470459 334.418060,545.081238 
				C337.111298,544.505188 340.001740,544.439514 343.207825,544.809204 
				C351.578461,549.464294 349.416992,556.979187 348.542847,563.662842 
				C347.425201,572.208496 344.462189,580.453308 343.611938,589.089355 
				C341.479095,610.754089 345.830963,630.719482 360.875671,647.024170 
				C372.578003,659.706543 377.604614,673.502625 375.597290,691.043274 
				C373.194214,712.041870 374.301758,733.022461 381.598389,753.468750 
				C387.542816,770.125977 384.636597,785.844116 373.068298,799.760254 
				C367.542633,806.407349 362.780334,813.586243 359.512390,821.756104 
				C356.016357,830.496094 356.844696,838.645081 361.276001,846.680115 
				C365.929169,855.117615 372.057648,862.661499 375.922058,871.600159 
				C379.522827,879.929138 379.427094,887.936157 375.708649,896.177734 
				C372.695404,902.856323 369.747406,909.563232 365.796997,915.736145 
				C360.206848,924.471313 354.287079,925.401672 346.402008,918.875000 
				C338.955078,912.711060 332.417084,905.565918 326.101562,898.324890 
				C319.460388,890.710510 317.461365,881.564636 320.917084,871.738831 
				C323.458374,864.513000 325.138245,857.039368 327.700928,849.408997 
			z"/>
			<path onClick={() => this.props.onFill(10)} fill={this.props.fillColors[10]} opacity="1.000000" stroke="black" 
				d="
			M328.531738,1.000000 
				C329.514130,6.752428 333.434204,10.540336 336.992432,14.435866 
				C341.359955,19.217367 345.916534,23.750341 349.856842,28.987516 
				C355.902710,37.023247 358.932465,45.911472 359.892853,55.665295 
				C360.158600,58.363914 360.868652,60.870132 361.603546,63.427002 
				C365.357208,76.487091 380.530457,80.975288 391.201263,72.480835 
				C396.905151,67.940224 401.870880,62.942356 405.053558,56.392269 
				C410.395294,45.398758 420.140900,39.996986 431.125000,37.367424 
				C449.667114,32.928490 468.495728,29.673870 487.408112,27.157446 
				C495.621918,26.064543 503.659637,28.427263 511.369293,30.905655 
				C518.483704,33.192696 519.821045,41.943050 514.868042,47.696960 
				C508.472748,55.126312 499.738983,58.899277 491.014282,61.694153 
				C476.956207,66.197556 470.098236,76.301895 466.117645,89.262733 
				C461.867798,103.100311 453.528473,112.646614 439.583893,117.460602 
				C434.810455,119.108498 430.120758,121.867607 426.730591,125.853661 
				C420.250793,133.472412 412.134735,138.332382 402.706818,141.239426 
				C391.011871,144.845474 381.842438,152.813309 371.742126,159.170135 
				C368.323090,161.321960 364.623840,162.856949 360.750732,163.798126 
				C347.818878,166.940567 337.316681,173.762054 328.851227,183.976349 
				C324.179382,189.613327 319.173615,194.951035 313.221069,199.326004 
				C302.598816,207.133072 296.849365,217.920731 294.651367,230.684280 
				C292.392456,243.801636 286.670074,254.037140 273.471649,259.095703 
				C264.045074,262.708649 259.000702,271.056549 254.062134,279.140625 
				C251.494797,283.343170 248.299286,284.938019 243.487396,285.076141 
				C230.865906,285.438354 222.481552,279.276367 216.394211,268.830566 
				C208.301514,254.943634 205.531219,240.049911 207.407303,224.192474 
				C208.443192,215.436707 203.784454,209.416260 197.126633,205.170792 
				C185.705734,197.888077 172.913147,193.306122 160.131058,189.158127 
				C142.647644,183.484451 133.416443,196.739441 136.445328,213.612198 
				C137.442688,219.168121 139.362122,224.554520 140.454285,230.098343 
				C142.035980,238.126968 141.032608,245.444824 134.871445,251.775909 
				C128.289520,258.539337 124.113579,266.838593 121.441826,275.940491 
				C120.142143,280.368134 118.836952,285.472229 113.206978,286.161407 
				C107.511299,286.858673 103.999458,283.099274 101.373543,278.564148 
				C94.433350,266.578094 88.695862,253.983734 84.661469,240.780563 
				C82.571533,233.940948 83.338974,226.401031 85.286415,219.536057 
				C89.206367,205.717789 85.907120,193.349091 79.640343,181.106461 
				C73.467934,169.048187 75.331665,156.276276 84.116829,149.673126 
				C89.958138,145.282654 96.132668,141.315811 102.304466,137.389496 
				C110.999664,131.857895 116.955650,124.485855 118.730225,114.138390 
				C119.269745,110.992516 119.267937,107.834610 118.034134,104.370575 
				C116.591370,101.800743 117.571617,99.902321 118.493027,97.896271 
				C120.666397,93.164497 124.383232,89.528236 127.227722,85.278297 
				C130.286331,80.708435 132.672028,75.870087 133.377350,70.311417 
				C133.704727,67.731491 133.169907,65.419533 131.957993,63.169182 
				C129.456375,58.524055 126.768181,57.834061 122.330818,60.608932 
				C120.681931,61.640053 119.434860,63.423195 116.870186,63.406319 
				C114.876038,62.460602 114.239288,60.993259 114.006630,59.265121 
				C113.168617,53.040428 114.627197,47.261097 118.271385,42.300930 
				C122.020363,37.198109 127.335136,35.831444 133.373108,37.812584 
				C142.627869,40.849197 150.970047,44.890858 153.413010,55.668858 
				C154.282364,59.504402 153.623383,63.023750 151.567703,66.148895 
				C149.739166,68.928719 148.303833,71.913795 146.516830,74.711601 
				C143.099274,80.062241 143.642380,85.308777 147.899384,90.186279 
				C149.111771,91.575394 150.169617,93.034393 151.212753,94.984909 
				C152.807266,101.970215 154.306152,108.616249 154.646088,115.429634 
				C154.897034,120.459396 152.797424,124.835190 150.298386,129.023239 
				C148.765091,131.592804 147.045502,134.070297 146.015930,136.894943 
				C144.586197,140.817566 145.151581,141.647125 149.215576,141.866440 
				C151.538864,141.991821 153.856644,141.971130 156.181625,142.312042 
				C163.842148,143.435349 168.212433,148.961761 172.616669,154.418610 
				C180.990158,164.793381 191.266647,172.810379 202.839371,179.304031 
				C204.388916,180.173523 205.869278,180.546555 207.921387,180.453979 
				C212.162933,180.790527 215.891083,182.570877 220.280670,181.602112 
				C224.609970,181.860687 228.159271,180.389297 231.710266,178.983795 
				C236.818649,176.961868 238.252838,172.386688 238.543671,167.688385 
				C238.902893,161.885071 239.662842,156.121445 239.908966,150.306107 
				C240.307892,140.880356 236.440964,133.219910 230.663239,126.245750 
				C226.423843,121.128502 222.529556,115.647697 217.854141,110.987457 
				C210.722458,103.878944 211.621857,96.687851 215.927826,88.855377 
				C219.247513,82.816963 223.179596,77.250008 228.651184,73.008224 
				C234.759277,68.272995 239.949982,62.664268 244.552628,56.551548 
				C251.739700,47.006416 260.490967,40.287296 272.688873,38.478230 
				C276.412628,37.925961 279.965637,39.046917 283.972961,38.471973 
				C286.436920,38.668385 288.246918,39.660503 290.440552,40.667801 
				C296.925079,45.711349 298.398865,52.637547 299.037903,59.854279 
				C299.288361,62.682705 299.797882,65.456497 300.431915,68.184982 
				C302.122406,75.459236 307.837677,77.888138 314.555695,74.370003 
				C324.003235,69.422470 329.923737,52.881100 325.585938,43.210392 
				C324.278870,40.296402 321.816254,38.223850 319.082306,35.967922 
				C313.667999,30.240225 312.803528,22.991341 310.971741,16.221092 
				C309.705566,11.541249 307.620544,6.771163 309.795044,1.357410 
				C316.021149,1.000000 322.042297,1.000000 328.531738,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(11)} fill={this.props.fillColors[11]} opacity="1.000000" stroke="black" 
				d="
			M395.871521,650.698059 
				C382.558350,645.598999 374.022461,636.037842 371.326324,621.749451 
				C369.765167,613.475586 374.560638,607.622192 380.093781,602.665894 
				C392.947906,591.151672 399.662109,577.779785 397.446625,559.833374 
				C395.889893,547.223389 396.159973,534.289551 398.359955,521.623718 
				C399.399261,515.640076 399.327911,509.634735 398.869995,503.735840 
				C397.576141,487.069214 397.675903,470.399353 398.077179,453.731964 
				C398.322296,443.550079 396.121277,434.020142 392.016266,424.863434 
				C390.967682,422.524445 389.123352,420.542175 387.356903,418.226807 
				C386.690033,410.740967 384.908234,403.606750 383.705414,396.435211 
				C382.218872,387.572174 383.804230,379.156860 385.402893,370.763580 
				C387.769012,358.341187 389.143127,345.866577 390.098328,333.281250 
				C390.705200,325.285461 394.598053,318.360168 399.447449,312.102722 
				C408.389038,300.564911 419.773071,291.654938 431.222107,282.796204 
				C436.768677,278.504456 441.498260,273.359558 444.931396,267.133759 
				C448.967285,259.814941 448.216431,252.404922 444.996582,245.182587 
				C441.734650,237.865784 436.799469,231.504166 432.507751,224.774353 
				C426.133789,214.779495 427.013275,212.784546 438.405701,210.688812 
				C443.618774,209.729843 448.903534,209.149353 454.101715,208.123291 
				C463.545807,206.259125 469.441620,199.993805 473.506378,191.731705 
				C474.314545,190.088989 474.793213,188.213028 476.187958,187.011520 
				C478.507935,185.013000 480.678009,184.635803 482.849396,187.635498 
				C489.072357,196.232315 495.389374,204.765671 501.862732,213.175186 
				C507.340240,220.291077 515.014465,220.776001 525.307678,215.115051 
				C532.056580,211.403351 537.501770,205.927917 543.824219,201.647888 
				C546.160400,200.066406 548.438965,198.349411 550.932800,197.059692 
				C562.934998,190.852661 571.370422,193.416031 578.066345,205.163742 
				C579.877625,208.341492 581.404480,211.718643 583.515869,214.680695 
				C588.683167,221.929962 596.230713,223.038803 604.094971,218.634216 
				C613.263916,213.498901 622.059082,207.955994 629.799500,200.759674 
				C635.262756,195.680466 639.490540,189.768738 642.395691,183.014969 
				C645.963501,174.720825 651.674683,168.049545 657.817810,161.746338 
				C670.841309,148.383560 671.735779,132.996490 659.342957,119.016869 
				C650.820007,109.402588 649.193787,98.963837 653.062073,87.062592 
				C656.040527,77.899338 660.135742,69.115974 661.993591,59.564018 
				C664.656982,45.870625 661.652893,32.818165 658.071838,19.779165 
				C656.447083,13.863361 654.274414,8.068274 653.996460,1.422759 
				C657.692932,1.000000 661.385803,1.000000 665.539368,1.000000 
				C667.319275,13.422457 671.035095,25.361807 673.377075,37.578499 
				C675.911377,50.798470 675.131897,64.002068 673.460999,77.238434 
				C672.051453,88.404884 673.789795,98.992058 679.292542,109.002571 
				C684.503540,118.482132 689.105774,128.324509 692.243958,138.681946 
				C695.003357,147.789444 692.882935,156.508575 686.170471,163.115723 
				C674.261719,174.837585 663.675476,187.467499 654.706177,201.598221 
				C647.696045,212.642426 636.308777,219.110718 626.277588,227.021118 
				C618.201172,233.390060 614.364990,242.545914 616.349365,252.728546 
				C618.657654,264.573181 629.385071,266.250977 639.591187,261.937744 
				C646.027832,259.217560 648.967102,253.239532 652.899170,248.165878 
				C653.101685,247.904587 653.230225,247.587311 653.417236,247.312485 
				C662.044800,234.631424 671.447266,233.755676 682.782410,244.206314 
				C690.303894,251.140945 694.887207,259.630371 697.031616,269.436066 
				C701.989563,292.106476 707.371216,295.852020 732.733765,300.970886 
				C746.401611,303.729462 760.250977,305.336609 774.076904,307.017303 
				C786.805359,308.564606 795.267578,315.130859 800.532837,326.851990 
				C809.005432,345.713440 824.290283,357.595520 842.630188,366.025940 
				C860.165100,374.086273 858.062622,373.156677 850.505920,387.680054 
				C840.996582,405.956512 830.861572,423.927368 820.421509,441.692169 
				C814.141785,452.377808 804.125793,459.443634 793.271484,465.776611 
				C790.088989,462.569336 793.076416,459.696228 793.650146,456.635986 
				C794.033813,454.589935 795.742737,453.281860 796.604248,451.075745 
				C799.392578,446.388550 804.086609,444.158325 807.333618,440.399017 
				C814.702454,431.867493 819.199585,421.711243 823.876404,411.655701 
				C826.319153,406.403595 828.832153,401.176361 830.577881,395.621490 
				C832.241577,390.327515 832.297729,385.198181 829.398315,379.886658 
				C828.958740,374.663055 825.636292,371.445618 822.708679,368.127991 
				C819.075562,364.010803 814.960632,360.335815 810.945923,356.587158 
				C803.740845,349.859436 798.651489,341.767120 795.085754,332.651825 
				C794.660706,331.565277 794.132202,330.519196 793.751099,329.423065 
				C789.850769,318.205170 781.550415,313.571014 770.144775,311.791260 
				C754.650024,309.373474 738.968628,309.337402 723.498962,307.050690 
				C707.348877,304.663330 696.401794,295.881378 691.129456,280.337158 
				C688.725342,273.249084 686.644104,266.076508 681.759216,260.106567 
				C676.748779,253.983032 672.259277,253.421249 666.014282,258.820679 
				C665.765320,259.338165 665.680786,259.550446 665.496582,260.088593 
				C665.230652,261.074127 664.930542,261.678894 664.482666,262.583466 
				C663.541748,264.217102 662.596558,265.451233 661.592773,267.065796 
				C661.117554,267.747223 660.801819,268.108154 660.181030,268.698425 
				C659.680298,269.076599 659.460938,269.204895 658.892334,269.493774 
				C648.963684,282.353302 634.950378,288.616547 621.135864,294.970978 
				C610.465210,299.879242 606.861572,308.541931 605.050415,319.372284 
				C602.292236,335.865540 603.104553,352.009583 606.551270,368.252747 
				C607.874878,374.490509 607.757202,380.872437 601.810059,385.758087 
				C600.780334,386.022736 600.047974,386.174896 599.473206,385.863434 
				C590.434509,380.964935 582.198425,375.300690 578.863098,364.781494 
				C576.350281,356.856476 573.687866,348.917816 568.650635,342.191803 
				C560.871887,331.805145 549.299377,333.258148 544.025146,345.244263 
				C541.152832,351.771729 539.695740,358.807465 536.984985,365.452698 
				C531.988281,377.701813 524.257996,388.391846 518.258545,400.059601 
				C510.534973,415.080505 505.272125,430.549774 506.119659,447.706055 
				C506.376678,452.908478 507.581787,457.875977 508.856079,462.866241 
				C509.425446,465.096008 510.294800,467.384430 508.330383,469.787048 
				C502.405640,461.551849 500.536407,451.901978 500.096741,442.098694 
				C499.014099,417.958496 507.362579,396.434937 519.554016,376.141357 
				C525.065430,366.967224 529.192444,357.192230 532.851624,347.168762 
				C535.195251,340.748932 538.266663,334.858002 543.845520,330.323303 
				C550.075134,325.259583 556.508118,325.161957 563.483032,327.796021 
				C566.888916,329.082214 570.232483,330.533234 573.634338,331.830566 
				C578.817261,333.807098 582.349915,332.585205 584.657532,327.578583 
				C588.408020,319.441559 588.225220,310.976990 584.892578,302.887482 
				C579.332397,289.390839 571.234131,278.108276 555.901367,274.510315 
				C550.395935,273.218445 544.943909,274.787750 539.677368,276.044128 
				C522.913269,280.043518 506.573975,278.051666 490.629425,272.589691 
				C477.180054,267.982483 465.309631,269.091064 454.644653,279.136871 
				C447.767120,285.615173 440.009369,291.101135 433.384949,297.908112 
				C424.737610,306.793732 419.358612,317.587341 414.608276,328.796417 
				C408.053253,344.263916 401.347321,359.710876 397.284088,376.052399 
				C394.381195,387.727142 395.781738,399.480957 398.511261,411.095825 
				C400.830139,420.963226 403.116669,431.070648 402.606018,441.077087 
				C402.036835,452.230774 401.435303,463.424713 402.294342,474.456543 
				C403.340759,487.894684 404.056213,501.291382 404.031494,514.756226 
				C404.019226,521.435852 402.581940,527.937622 401.952820,534.538391 
				C401.126862,543.204468 402.379791,551.753418 403.103394,560.313965 
				C404.148010,572.672913 400.743195,583.524841 393.274384,593.258667 
				C390.242645,597.209839 387.434143,601.350159 384.191498,605.118225 
				C376.543518,614.005371 377.920898,623.842346 384.033661,632.531494 
				C388.132019,638.357117 391.919403,644.401550 395.871521,650.698059 
			z"/>
			<path onClick={() => this.props.onFill(12)} fill={this.props.fillColors[12]} opacity="1.000000" stroke="black" 
				d="
			M1.000000,206.468658 
				C10.258429,205.151978 14.987669,200.608871 17.180204,191.913467 
				C18.472187,186.789551 18.869850,181.489471 20.900940,176.483429 
				C25.212271,165.857285 33.781487,161.323502 44.225403,159.184982 
				C46.795071,158.658798 48.476177,160.387161 49.478138,162.264282 
				C56.120861,174.709030 63.385784,186.915695 66.688927,200.822632 
				C68.171249,207.063568 66.010147,213.055817 64.136696,218.899887 
				C60.520550,230.180161 61.296497,240.734818 67.856682,250.882111 
				C73.825974,260.115448 78.284348,270.111084 82.427162,280.330078 
				C88.637070,295.647888 99.251740,306.881439 115.502525,311.992126 
				C128.817245,316.179443 138.766541,311.410278 140.747040,297.745941 
				C143.061844,281.775085 152.234665,273.919647 166.747620,270.147766 
				C172.379547,268.684021 177.945526,267.185089 183.812286,266.947632 
				C189.352066,266.723450 193.698334,268.981049 196.560989,273.411835 
				C202.281952,282.266602 206.040131,292.052795 208.788010,302.196136 
				C209.954834,306.503326 208.267517,310.106720 204.032623,311.390137 
				C193.503784,314.580933 187.374191,322.148621 182.108353,331.206940 
				C175.706360,342.219696 166.866089,351.169250 157.247452,359.565582 
				C149.616791,366.226593 143.514847,374.482544 138.766663,383.560364 
				C136.220901,388.427521 133.597153,393.212402 130.277359,397.630920 
				C123.739586,406.332489 115.558693,408.687347 105.278084,404.711212 
				C99.380417,402.430176 93.357529,400.698334 87.002602,399.947174 
				C77.863655,398.866852 70.637970,403.834442 62.977890,407.158691 
				C52.880009,411.540863 42.506248,411.943878 31.814793,411.261963 
				C21.868141,410.627502 11.894876,410.410370 1.466664,410.004761 
				C1.000000,342.312439 1.000000,274.624878 1.000000,206.468658 
			z"/>
			<path onClick={() => this.props.onFill(13)} fill={this.props.fillColors[13]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,1020.531372 
				C1160.855103,1017.551514 1153.502686,1010.958496 1149.243286,1001.146057 
				C1147.156738,996.339539 1144.339844,992.761292 1139.136719,990.932373 
				C1135.572021,989.679382 1132.498413,990.038696 1129.697510,992.019348 
				C1126.319580,994.408264 1122.711060,996.449158 1119.604004,999.287231 
				C1116.057739,1002.526672 1111.816895,1003.061890 1107.657837,1000.277039 
				C1097.308594,993.347473 1090.008545,984.304016 1088.371460,971.522827 
				C1087.998291,968.610352 1089.494751,966.472168 1091.625854,964.651855 
				C1094.527588,962.173157 1098.132202,960.814148 1101.648315,960.443909 
				C1117.331665,958.792542 1128.283203,950.260620 1136.662476,937.580627 
				C1139.319824,933.559448 1142.141479,929.647095 1144.857178,925.664062 
				C1146.235962,923.641846 1146.981323,921.569519 1145.512939,919.183167 
				C1143.959106,916.657593 1142.301636,919.085083 1140.715210,918.940613 
				C1133.031250,918.240784 1127.799561,924.299988 1120.904053,926.066467 
				C1109.733032,928.928345 1098.543091,930.945129 1087.021606,930.988098 
				C1074.987915,931.032959 1069.512085,927.559326 1065.367432,916.426086 
				C1060.352661,902.955261 1053.772461,890.268005 1046.395874,878.024841 
				C1039.955200,867.335266 1032.400269,857.489807 1022.520508,849.490112 
				C1017.661560,845.555725 1013.174255,840.821228 1009.621643,835.685852 
				C1002.286682,825.082947 1004.976868,808.511719 1021.555420,805.905029 
				C1035.020386,803.787964 1047.458008,799.215332 1059.130981,792.107483 
				C1065.579590,788.180725 1073.120850,786.871399 1080.601807,786.211487 
				C1086.316406,785.707336 1093.394531,791.132568 1096.064331,797.860474 
				C1099.188110,805.732605 1102.082764,813.696228 1105.029663,821.637817 
				C1106.600708,825.871948 1109.730103,828.880554 1113.134277,831.495422 
				C1122.549072,838.727356 1132.166626,833.480225 1136.997803,824.492188 
				C1141.495361,816.124878 1140.141357,807.401917 1138.703491,798.656311 
				C1138.434937,797.023071 1138.076660,795.382812 1138.030273,793.738220 
				C1137.773682,784.673340 1140.618286,781.935486 1149.695435,783.118408 
				C1156.586426,784.016479 1163.404663,785.473022 1170.627686,786.840576 
				C1171.000000,814.687561 1171.000000,842.375122 1171.000000,870.531372 
				C1170.350586,871.575195 1170.291382,872.582336 1169.765991,873.108459 
				C1164.982178,877.897034 1165.691284,882.299316 1170.759277,886.753784 
				C1171.000000,931.354248 1171.000000,975.708435 1171.000000,1020.531372 
			z"/>
			<path onClick={() => this.props.onFill(14)} fill={this.props.fillColors[14]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,676.531372 
				C1161.317505,676.701599 1152.498169,680.816467 1143.191162,682.432495 
				C1136.432251,683.606140 1130.057007,686.035217 1123.630859,688.294922 
				C1113.304565,691.926208 1099.481445,708.251831 1100.911987,721.342041 
				C1101.451782,726.281982 1100.932983,731.331787 1101.012573,736.329712 
				C1101.072876,740.124878 1099.480103,742.699402 1095.908325,744.035645 
				C1094.506104,744.560181 1093.162476,745.330017 1091.717529,745.638550 
				C1077.836914,748.601929 1067.452515,757.188538 1057.101318,766.231750 
				C1047.884155,774.284119 1036.993286,775.575745 1026.009766,769.903687 
				C1015.565674,764.510254 1004.758789,760.798096 993.159546,758.657898 
				C979.187439,756.080017 976.801575,750.959717 975.067932,735.249817 
				C974.388367,729.091309 975.771240,722.999451 976.954773,716.964478 
				C981.370422,694.448669 988.407776,672.954041 1000.669067,653.301453 
				C1004.968628,646.410034 1008.061340,638.790955 1011.166626,631.221497 
				C1015.851196,619.802002 1013.506958,608.282837 1012.381226,596.768250 
				C1011.866028,591.498474 1012.441650,586.457153 1014.717773,581.760315 
				C1016.654419,577.764160 1020.248169,577.058716 1023.859131,579.593567 
				C1025.078491,580.449585 1026.188110,581.485840 1027.249634,582.538818 
				C1036.308228,591.525024 1047.231567,590.855225 1058.481323,588.652832 
				C1073.555420,585.701782 1087.845825,581.204712 1101.330078,573.213318 
				C1113.998901,565.705261 1128.445190,566.192932 1142.516235,570.052795 
				C1151.981689,572.649231 1160.530273,577.916870 1170.621094,579.936646 
				C1171.000000,584.356873 1171.000000,588.713806 1171.000000,593.535339 
				C1168.487305,596.823364 1165.346191,596.004395 1162.182007,595.684692 
				C1153.521729,594.809753 1145.582886,591.038147 1136.978027,589.771301 
				C1125.364868,588.061462 1114.702637,590.209473 1105.060181,596.524109 
				C1097.141479,601.709961 1088.552979,605.253113 1079.618408,607.776123 
				C1067.748413,611.127930 1067.330811,612.480530 1065.537476,625.178284 
				C1064.590576,631.882019 1063.866455,638.638306 1062.725220,645.338806 
				C1061.468750,652.716309 1063.889282,656.548401 1071.441528,656.497437 
				C1083.260864,656.417786 1094.323486,660.372437 1105.856201,661.572144 
				C1118.111694,662.846924 1129.656616,660.438965 1140.703247,655.116394 
				C1147.900757,651.648682 1154.596680,647.225342 1161.944946,644.063965 
				C1164.662476,642.894775 1167.423828,641.354004 1170.700439,643.738403 
				C1171.000000,654.687561 1171.000000,665.375122 1171.000000,676.531372 
			z"/>
			<path onClick={() => this.props.onFill(15)} fill={this.props.fillColors[15]} opacity="1.000000" stroke="black" 
				d="
			M889.531372,1.000002 
				C888.192383,8.596607 886.434082,16.205395 884.563599,23.786503 
				C882.543457,31.974537 884.696350,38.908615 889.893799,45.468258 
				C894.952271,51.852409 894.227783,67.805389 888.899292,73.975266 
				C887.788879,75.260963 888.156311,76.324310 888.027954,77.489754 
				C886.936218,87.404442 889.615417,96.890427 891.306091,106.507622 
				C894.019226,121.940865 895.088806,137.391861 892.338684,153.023712 
				C891.010742,160.571442 892.527588,168.355728 893.502991,175.826309 
				C894.553528,183.872131 893.229858,190.888672 889.865845,197.904282 
				C888.499634,200.753555 886.211365,201.464401 883.291626,201.221863 
				C872.537354,200.328552 864.257996,194.706039 857.175659,187.169128 
				C852.025391,181.688232 848.638367,174.974167 845.052673,168.376251 
				C838.834351,156.934067 828.814575,149.220276 817.969727,142.496872 
				C813.167725,139.519791 808.184998,136.815475 803.516907,133.644974 
				C794.100220,127.249405 784.466003,127.936943 774.587891,132.199646 
				C767.837830,135.112503 760.801453,137.098129 753.517334,137.903946 
				C744.566040,138.894226 736.979065,132.998550 734.129395,124.104530 
				C731.133362,114.753662 731.246460,105.309731 733.217102,96.031166 
				C735.809387,83.825142 734.987305,72.487259 729.045410,61.309006 
				C722.418396,48.841782 720.464294,34.829319 717.014160,21.322052 
				C715.370056,14.885708 713.460510,8.524219 713.001221,1.425871 
				C717.689270,1.000000 722.378479,1.000000 727.533875,1.000000 
				C729.883728,2.543710 730.741272,4.541055 731.317749,6.928379 
				C733.620850,16.465227 736.362122,25.896811 739.423706,35.210941 
				C742.216431,43.707134 747.778259,50.539730 755.254395,55.152668 
				C763.074341,59.977730 770.544128,65.188812 777.500000,71.119232 
				C788.923340,80.858536 798.412415,77.573097 806.097900,66.657440 
				C811.506714,58.975266 814.183289,49.907600 817.954529,41.411667 
				C823.497009,28.925425 828.701416,16.344393 838.599792,6.388797 
				C840.933411,4.041760 843.237427,2.203539 846.701538,1.215148 
				C861.020874,1.000000 875.041809,1.000000 889.531372,1.000002 
			z"/>
			<path onClick={() => this.props.onFill(16)} fill={this.props.fillColors[16]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,551.531372 
				C1160.097412,551.461853 1149.064941,553.424622 1138.349365,549.366211 
				C1131.426270,546.744202 1125.148071,543.360046 1119.181396,538.959534 
				C1111.156738,533.041321 1102.851196,527.499756 1094.770630,521.635010 
				C1086.985718,515.984924 1082.514404,508.658691 1081.024658,499.384155 
				C1080.095947,493.601135 1077.871582,488.831085 1073.419434,484.724579 
				C1068.306030,480.008148 1062.956421,475.414948 1059.011353,469.534607 
				C1056.328613,465.535645 1055.143066,461.137573 1054.982300,456.365112 
				C1054.815063,451.403259 1054.615601,446.436432 1054.206177,441.490326 
				C1053.637939,434.626160 1048.049927,427.368042 1042.213379,425.601837 
				C1036.324585,423.819824 1032.363647,426.235046 1030.405396,432.047638 
				C1028.391235,438.025665 1029.802124,444.346985 1028.032837,450.293640 
				C1027.939331,450.608276 1027.972778,450.958435 1027.906982,451.283936 
				C1024.445190,468.420410 1014.593994,473.064972 998.781006,465.464569 
				C988.374634,460.462830 980.534180,452.216187 971.775513,445.140839 
				C966.971985,441.260529 962.132568,437.494324 956.792847,434.414886 
				C952.004456,431.653442 947.613220,428.371643 943.655334,424.551636 
				C939.023743,420.081421 938.422852,413.252899 942.540894,408.294952 
				C946.036194,404.086853 949.724243,399.924774 954.494019,397.038940 
				C959.408081,394.065826 964.663025,393.036407 970.259094,394.933502 
				C977.998047,397.557068 985.902344,400.065002 993.987732,400.545685 
				C1001.220764,400.975647 1008.156494,400.268311 1013.854980,393.324890 
				C1022.481079,382.814178 1029.414673,371.013702 1039.086060,361.305756 
				C1052.065552,348.277283 1067.798462,344.276428 1085.569702,345.527679 
				C1095.180054,346.204315 1104.791748,346.331238 1114.458984,345.290009 
				C1124.245483,344.235992 1134.064087,344.523590 1143.391357,348.684082 
				C1151.855957,352.459808 1160.788574,354.239227 1170.543945,354.000000 
				C1171.000000,359.354919 1171.000000,364.709869 1171.000000,370.532410 
				C1170.144165,371.782166 1169.379272,372.780670 1168.138672,372.892853 
				C1162.182007,373.431641 1156.392334,375.376526 1150.353149,375.136322 
				C1133.890869,374.481628 1117.433350,375.531952 1100.971069,375.206055 
				C1094.473145,375.077393 1088.067383,374.141663 1081.621704,373.541565 
				C1073.588867,372.793701 1065.566284,372.473480 1058.551514,377.502502 
				C1052.305542,381.980316 1050.772339,387.123108 1053.157837,394.559204 
				C1054.445923,398.574615 1056.438232,402.326050 1058.985229,405.545563 
				C1066.844238,415.479675 1072.165649,426.713928 1076.255737,438.527313 
				C1079.802734,448.771851 1087.444214,452.141663 1097.316895,452.919403 
				C1104.816284,453.510162 1112.122803,455.227295 1118.761719,458.944092 
				C1127.418701,463.790619 1128.616211,470.541748 1122.457397,478.254303 
				C1118.939209,482.659943 1115.350342,486.949677 1113.607666,492.575409 
				C1111.520996,499.311584 1112.610840,504.257721 1118.337402,508.320282 
				C1126.096802,513.825012 1134.916748,516.459595 1144.252319,518.034302 
				C1153.057251,519.519531 1161.677124,518.606995 1170.679443,517.762878 
				C1171.000000,529.020874 1171.000000,540.041809 1171.000000,551.531372 
			z"/>
			<path onClick={() => this.props.onFill(17)} fill={this.props.fillColors[17]} opacity="1.000000" stroke="black" 
				d="
			M290.381348,42.295792 
				C288.044495,42.082573 286.350403,41.249275 284.384888,40.136284 
				C273.127716,38.054382 265.509918,30.934196 258.084808,23.451080 
				C254.340836,19.677858 250.564865,15.928918 246.192978,12.829081 
				C244.574936,11.681813 243.104370,11.245869 241.115021,11.681862 
				C231.220856,13.850292 223.994095,19.642191 217.587051,27.306110 
				C211.332443,34.787720 205.156616,42.553947 196.505890,47.639538 
				C190.772934,51.009827 184.318817,51.889389 177.925308,52.739086 
				C171.145142,53.640160 170.309509,56.261047 171.969467,62.915016 
				C175.748825,78.064728 177.170776,84.013321 161.731049,92.253975 
				C158.281555,94.095078 154.959885,96.619164 150.366180,96.110550 
				C147.839996,92.189545 144.025513,89.978493 142.418137,85.223320 
				C140.837601,80.547531 142.579590,77.291489 144.146912,74.156059 
				C145.394211,71.660866 146.523682,68.728081 148.476791,66.657005 
				C154.014221,60.785091 152.054214,52.999928 147.489227,48.300320 
				C142.375183,43.035477 135.888931,39.273518 128.180573,39.095970 
				C121.678535,38.946209 118.039597,44.502205 116.203766,52.798069 
				C115.544113,55.778915 115.476913,59.092041 116.802361,62.538151 
				C119.733551,73.121269 117.152786,82.154106 109.878693,89.429581 
				C104.302017,95.007317 97.553429,99.087692 89.151688,99.125381 
				C79.327705,99.169449 70.855484,93.940079 69.049278,83.750069 
				C65.960899,66.326385 58.411957,50.547218 53.144928,33.947620 
				C50.115566,24.400276 49.702202,14.340967 49.366119,4.357846 
				C49.331566,3.331327 49.744328,2.408728 50.720779,1.317208 
				C91.354225,1.000000 131.708450,1.000000 172.531342,1.000000 
				C176.002594,2.312556 179.209457,2.962100 182.346069,3.825460 
				C185.804977,4.777530 188.787415,3.556942 191.755692,1.238962 
				C231.020889,1.000000 270.041779,1.000000 309.531342,1.000000 
				C311.740417,7.816032 312.571198,14.814043 314.744263,21.574268 
				C316.160767,25.980947 317.634155,30.281675 319.944702,34.680599 
				C321.798096,40.645485 320.310883,43.582272 314.800018,45.754669 
				C306.157715,49.161488 298.322235,46.836170 290.381348,42.295792 
			z"/>
			<path onClick={() => this.props.onFill(18)} fill={this.props.fillColors[18]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,517.531372 
				C1163.977783,522.235352 1156.372437,521.508423 1148.705566,520.773010 
				C1141.549683,520.086670 1134.613403,518.657654 1127.857910,516.153748 
				C1123.740601,514.627686 1119.899780,512.531799 1116.572876,509.861145 
				C1107.107910,502.263184 1108.637695,494.670807 1115.789429,482.950958 
				C1117.414307,480.288208 1119.758057,478.055725 1121.828613,475.675323 
				C1125.574707,471.368744 1124.841431,465.746429 1120.170532,462.469086 
				C1113.251587,457.614227 1105.245361,455.835724 1097.143311,455.129578 
				C1086.581421,454.209076 1078.094971,451.066803 1074.453613,439.977661 
				C1070.010132,426.446228 1062.964600,414.267242 1054.543091,402.845215 
				C1054.151001,402.313568 1053.892700,401.684418 1053.560547,401.107330 
				C1043.342773,383.362762 1055.512451,371.188782 1071.251587,371.213257 
				C1089.502197,371.241730 1107.713745,373.802307 1126.028320,373.008179 
				C1140.718994,372.371216 1155.474243,373.157043 1170.547974,371.000000 
				C1171.000000,377.687653 1171.000000,384.375275 1171.000000,391.531464 
				C1165.177124,397.017212 1165.177124,397.017212 1170.763062,404.758606 
				C1171.000000,442.354218 1171.000000,479.708466 1171.000000,517.531372 
			z"/>
			<path onClick={() => this.props.onFill(19)} fill={this.props.fillColors[19]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,643.531372 
				C1163.505127,644.406982 1157.562866,648.663391 1151.382324,652.152710 
				C1138.126221,659.636841 1124.421875,665.230713 1108.771729,664.002625 
				C1100.178467,663.328369 1091.760986,661.715210 1083.457275,659.436096 
				C1080.187012,658.538452 1076.803589,658.864258 1073.516357,659.090210 
				C1070.376099,659.306091 1067.511108,658.362854 1064.839111,657.322327 
				C1061.124512,655.875671 1059.353394,652.718933 1059.993164,648.484375 
				C1061.523560,638.354492 1063.116211,628.231140 1064.429810,618.072327 
				C1065.347412,610.975220 1069.974121,607.827454 1076.179932,606.205200 
				C1086.373169,603.540649 1095.893799,599.599304 1104.880737,593.894104 
				C1118.610596,585.177856 1133.539795,585.275574 1148.622681,590.071350 
				C1155.637207,592.301697 1162.696655,594.045959 1170.553223,594.000000 
				C1171.000000,610.354248 1171.000000,626.708435 1171.000000,643.531372 
			z"/>
			<path onClick={() => this.props.onFill(20)} fill={this.props.fillColors[20]} opacity="1.000000" stroke="black" 
				d="
			M1095.468628,1169.000000 
				C1093.963257,1154.227051 1091.733398,1139.738770 1084.897217,1126.336792 
				C1078.916260,1114.611206 1079.762817,1110.478149 1091.010376,1103.516479 
				C1098.237427,1099.043335 1103.320557,1093.167847 1106.812866,1085.567505 
				C1109.030273,1080.741821 1116.141602,1077.872070 1122.129517,1078.504639 
				C1133.537231,1079.709961 1143.573486,1085.434326 1154.548950,1088.025391 
				C1159.709473,1089.243530 1164.846436,1090.556396 1170.576294,1090.998901 
				C1171.000000,1102.354248 1171.000000,1113.708496 1171.000000,1125.531372 
				C1169.731445,1127.883301 1167.911987,1128.750977 1165.759521,1129.460938 
				C1155.639038,1132.799072 1149.585571,1140.063110 1146.617065,1150.080688 
				C1145.236572,1154.739136 1147.267578,1159.295532 1147.245728,1163.922363 
				C1147.238770,1165.396240 1148.017578,1166.850952 1147.178955,1168.671387 
				C1129.979126,1169.000000 1112.958130,1169.000000 1095.468628,1169.000000 
			z"/>
			<path onClick={() => this.props.onFill(21)} fill={this.props.fillColors[21]} opacity="1.000000" stroke="black" 
				d="
			M846.531372,1.000000 
				C831.067749,14.314399 823.816589,32.967228 816.054688,51.308273 
				C812.632263,59.395206 809.657898,67.644783 802.794495,73.830841 
				C796.168335,79.803032 787.953918,81.003616 780.556274,75.993721 
				C772.323242,70.418060 765.238220,63.238731 756.471130,58.407646 
				C747.368103,53.391449 740.712036,46.011143 737.483276,36.342152 
				C733.708801,25.039333 730.128723,13.605743 728.020508,1.402860 
				C767.354248,1.000000 806.708435,1.000000 846.531372,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(22)} fill={this.props.fillColors[22]} opacity="1.000000" stroke="black" 
				d="
			M50.531342,1.000000 
				C51.734596,20.387405 55.512619,39.019176 64.172821,56.563732 
				C67.724182,63.758381 68.633041,71.766945 70.018341,79.594017 
				C72.792305,95.267220 87.143639,101.542992 100.924843,93.540398 
				C111.102188,87.630524 115.957291,79.057854 116.018509,67.481094 
				C116.025444,66.169144 116.294693,64.858566 116.704399,63.228859 
				C119.301102,59.883595 121.758133,56.858536 125.943230,56.706764 
				C131.407303,56.508614 136.323288,62.843056 135.787766,69.064384 
				C135.047745,77.661552 131.022110,84.878021 125.565727,91.158829 
				C122.337807,94.874451 120.279480,99.028648 118.106827,103.650604 
				C108.378494,105.031387 99.696228,109.946457 89.922791,110.253151 
				C74.515060,110.736641 63.213261,103.528427 58.850819,88.477798 
				C56.644932,80.867378 56.628319,72.934952 53.573975,65.374214 
				C49.794079,56.017441 43.192142,49.386307 36.400002,42.599396 
				C32.524082,38.726460 28.778120,35.120670 23.036499,33.657082 
				C18.409237,32.477551 14.518327,32.795692 10.702813,35.229393 
				C7.953335,36.983128 5.105492,38.115219 1.409427,37.998543 
				C1.000000,26.723965 1.132846,15.445287 0.908744,4.173707 
				C0.851058,1.272310 1.661961,0.902762 4.295938,0.928157 
				C19.550333,1.075226 34.806870,1.000000 50.531342,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(23)} fill={this.props.fillColors[23]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,35.531342 
				C1162.440674,35.980175 1154.375244,38.754925 1146.143311,40.512630 
				C1137.551636,42.347137 1129.068726,44.500401 1120.144043,44.044338 
				C1111.621948,43.608845 1105.604248,38.527676 1103.928955,30.103354 
				C1102.030273,20.555651 1101.309570,10.725281 1097.146973,1.334833 
				C1103.021118,1.000000 1109.042358,1.000000 1115.531738,1.000000 
				C1118.951782,3.996015 1118.893799,7.913738 1119.063965,11.666861 
				C1119.223022,15.177284 1119.498535,18.599840 1121.021118,21.827335 
				C1123.172485,26.388088 1127.313477,29.203508 1132.198364,28.392904 
				C1143.467529,26.522882 1152.560669,21.831333 1153.840942,6.578311 
				C1153.994263,4.752692 1153.937866,2.901203 1155.743652,1.276008 
				C1160.955811,1.000000 1165.911621,1.000000 1170.867432,1.000000 
				C1170.911621,6.076847 1170.976685,11.153619 1170.994751,16.230558 
				C1171.016968,22.507862 1171.000000,28.785301 1171.000000,35.531342 
			z"/>
			<path onClick={() => this.props.onFill(24)} fill={this.props.fillColors[24]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,292.531342 
				C1162.701538,291.591492 1154.222656,292.291534 1145.920410,290.185608 
				C1136.636353,287.830658 1132.159546,282.953674 1135.401733,274.342438 
				C1140.640625,260.428070 1146.986938,246.834900 1160.216919,238.187469 
				C1163.312988,236.163834 1166.572998,234.643463 1170.617188,234.018311 
				C1171.000000,253.354233 1171.000000,272.708466 1171.000000,292.531342 
			z"/>
			<path onClick={() => this.props.onFill(25)} fill={this.props.fillColors[25]} opacity="1.000000" stroke="black" 
				d="
			M116.279633,428.000610 
				C110.699738,428.192963 105.656525,427.345978 100.692924,425.801941 
				C91.867332,423.056549 82.959137,423.321594 74.198532,425.865906 
				C58.364136,430.464752 42.302914,429.288391 26.221010,428.257294 
				C24.728340,428.161591 23.234104,428.008453 21.741087,428.014038 
				C15.096722,428.038788 8.448085,428.200226 1.433416,427.001007 
				C1.000000,423.969391 1.000000,420.938812 1.000000,417.454102 
				C11.620614,416.947937 22.177048,418.218048 32.767784,418.596375 
				C46.608898,419.090881 60.288948,418.055237 72.431374,410.250458 
				C82.671745,403.668274 92.751755,404.874146 102.303963,411.332367 
				C108.218437,415.331055 114.271736,419.053131 120.688950,422.874420 
				C121.458252,426.378448 119.435791,427.514832 116.279633,428.000610 
			z"/>
			<path onClick={() => this.props.onFill(26)} fill={this.props.fillColors[26]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,769.531372 
				C1162.034790,768.981201 1155.047485,763.970337 1148.223999,758.662231 
				C1146.007324,756.937744 1143.793823,755.180481 1141.776245,753.233704 
				C1134.226440,745.949097 1133.588135,740.370728 1139.557373,731.777771 
				C1146.935669,721.156189 1157.191772,715.573608 1170.569702,715.000244 
				C1171.000000,733.020874 1171.000000,751.041809 1171.000000,769.531372 
			z"/>
			<path onClick={() => this.props.onFill(27)} fill={this.props.fillColors[27]} opacity="1.000000" stroke="black" 
				d="
			M1155.531494,1.000000 
				C1155.996826,3.485875 1156.079712,5.975354 1155.975952,8.457025 
				C1155.412598,21.920321 1140.067139,33.302235 1127.142456,30.072632 
				C1120.274780,28.356577 1118.268799,22.960163 1117.214600,17.176231 
				C1116.302612,12.172410 1116.367065,6.990623 1116.000000,1.443746 
				C1129.020874,1.000000 1142.041870,1.000000 1155.531494,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(28)} fill={this.props.fillColors[28]} opacity="1.000000" stroke="black" 
				d="
			M1147.468750,1169.000000 
				C1139.933228,1157.097168 1145.729370,1137.186523 1158.130493,1130.396973 
				C1161.960815,1128.299683 1165.990845,1126.956665 1170.617676,1126.064209 
				C1171.000000,1139.411255 1170.937012,1152.822998 1171.061646,1166.233032 
				C1171.082520,1168.482544 1170.751221,1169.161743 1168.299805,1169.079102 
				C1161.519165,1168.850464 1154.725708,1169.000000 1147.468750,1169.000000 
			z"/>
			<path onClick={() => this.props.onFill(29)} fill={this.props.fillColors[29]} opacity="1.000000" stroke="black" 
				d="
			M356.178650,1160.659546 
				C356.057129,1163.369019 356.028564,1165.740234 356.000000,1168.555664 
				C341.979095,1169.000000 327.958221,1169.000000 313.468658,1169.000000 
				C313.319946,1159.108032 319.511078,1153.079956 330.995880,1152.077759 
				C336.742920,1151.576172 342.091827,1150.556641 346.641113,1146.162476 
				C352.194092,1149.360352 354.534668,1154.644043 356.178650,1160.659546 
			z"/>
			<path onClick={() => this.props.onFill(30)} fill={this.props.fillColors[30]} opacity="1.000000" stroke="black" 
				d="
			M1.000000,1155.474121 
				C10.159420,1153.658447 19.343796,1152.664062 28.624842,1153.006958 
				C40.087322,1153.430420 46.480652,1158.440918 48.009708,1168.604492 
				C33.441998,1169.000000 18.883354,1168.930664 4.326598,1169.065430 
				C1.778649,1169.089111 0.596814,1168.871460 0.920146,1165.865601 
				C1.271968,1162.594849 1.000000,1159.256836 1.000000,1155.474121 
			z"/>
			<path onClick={() => this.props.onFill(31)} fill={this.props.fillColors[31]} opacity="1.000000" stroke="black" 
				d="
			M1086.531372,1.000000 
				C1076.842896,11.247059 1064.274780,16.113091 1050.127686,16.931181 
				C1038.040771,17.630140 1034.854980,14.578154 1033.004883,1.405452 
				C1050.687622,1.000000 1068.375122,1.000000 1086.531372,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(32)} fill={this.props.fillColors[32]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,340.531342 
				C1164.866577,339.378387 1158.907227,337.378357 1154.110840,332.961517 
				C1147.189087,326.587555 1148.229370,319.187378 1156.709351,315.179260 
				C1160.932251,313.183228 1165.406616,311.949615 1170.572388,311.982239 
				C1171.000000,321.354218 1171.000000,330.708466 1171.000000,340.531342 
			z"/>
			<path onClick={() => this.props.onFill(33)} fill={this.props.fillColors[33]} opacity="1.000000" stroke="black" 
				d="
			M597.531372,1.000000 
				C596.209717,9.860532 590.939575,16.543001 584.183594,22.046486 
				C580.691650,24.891087 574.363953,21.508490 574.091797,16.799261 
				C573.806213,11.858449 573.981018,6.891029 573.977295,1.467618 
				C581.687561,1.000000 589.375183,1.000000 597.531372,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(34)} fill={this.props.fillColors[34]} opacity="1.000000" stroke="black" 
				d="
			M211.460632,1169.000000 
				C210.430603,1163.997314 213.212677,1160.172729 215.547668,1156.208130 
				C216.035492,1155.379883 216.772507,1154.461426 217.884872,1154.714844 
				C219.139496,1155.000488 218.746124,1156.280151 219.027771,1157.171143 
				C220.207962,1160.904297 221.460403,1164.614746 222.843201,1168.666748 
				C219.307098,1169.000000 215.614182,1169.000000 211.460632,1169.000000 
			z"/>
			<path onClick={() => this.props.onFill(35)} fill={this.props.fillColors[35]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,886.533142 
				C1168.137451,885.972229 1164.796387,885.672913 1164.036011,881.748291 
				C1162.977539,876.284668 1166.507324,873.656128 1170.668701,871.078735 
				C1171.000000,876.022095 1171.000000,881.044189 1171.000000,886.533142 
			z"/>
			<path onClick={() => this.props.onFill(36)} fill={this.props.fillColors[36]} opacity="1.000000" stroke="black" 
				d="
			M1171.000000,404.536804 
				C1169.641968,404.425995 1168.090454,404.100220 1166.966797,403.225250 
				C1164.763428,401.509491 1162.233398,399.649536 1163.244019,396.294800 
				C1164.243896,392.975464 1166.832764,391.741547 1170.598511,392.000732 
				C1171.000000,396.024536 1171.000000,400.049103 1171.000000,404.536804 
			z"/>
			<path onClick={() => this.props.onFill(37)} fill={this.props.fillColors[37]} opacity="1.000000" stroke="black" 
				d="
			M191.531723,1.000000 
				C189.754364,7.033190 183.030197,8.466499 176.688660,4.220702 
				C175.498169,3.423639 174.429977,2.443867 173.152679,1.274204 
				C179.021149,1.000000 185.042297,1.000000 191.531723,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(38)} fill={this.props.fillColors[38]} opacity="1.000000" stroke="black" 
				d="
			M1.000000,826.460632 
				C5.569195,825.866516 7.688576,829.590820 5.273130,833.848022 
				C4.409033,835.370972 2.894585,836.524963 1.338483,837.923645 
				C1.000000,834.307068 1.000000,830.614197 1.000000,826.460632 
			z"/>
			<path onClick={() => this.props.onFill(39)} fill={this.props.fillColors[39]} opacity="1.000000" stroke="black" 
				d="
			M690.600037,1.000000 
				C690.793823,2.403461 691.499207,3.989931 690.126160,5.268836 
				C688.338867,4.709757 687.751648,3.173061 687.059082,1.338398 
				C688.066711,1.000000 689.133362,1.000000 690.600037,1.000000 
			z"/>
			<path onClick={() => this.props.onFill(40)} fill={this.props.fillColors[40]} opacity="1.000000" stroke="black" 
				d="
			M502.414978,505.752686 
				C503.968262,523.870178 506.560852,541.528992 505.895508,559.324646 
				C505.396606,572.667603 501.124054,584.209106 487.231750,589.541382 
				C473.242126,594.911011 464.740387,606.132812 456.973389,618.081238 
				C448.828766,630.610535 436.971802,636.982056 422.440125,637.849121 
				C411.974792,638.473633 406.376678,629.574097 410.546204,619.915588 
				C413.378937,613.353638 416.853058,607.077209 421.123688,601.327576 
				C429.322845,590.288879 433.571350,577.750916 433.750092,564.224304 
				C433.867767,555.319153 431.538208,546.480652 429.761658,537.681702 
				C426.637695,522.209045 425.121735,506.535583 423.813965,490.799591 
				C422.886047,479.634399 422.396515,468.447571 423.210297,457.398773 
				C424.593109,438.624542 420.879272,420.839569 415.585297,403.127472 
				C414.152649,398.334290 413.521729,393.365509 414.169769,388.317169 
				C415.585144,377.291718 423.253174,371.219055 432.599579,368.261139 
				C443.209198,364.903503 451.116241,357.854950 459.546143,351.553497 
				C463.728729,348.426971 467.083313,343.606232 466.027832,337.327087 
				C465.252045,332.711853 462.536407,328.945831 459.649261,325.926117 
				C448.990326,314.777802 450.960968,301.364746 464.679810,294.097473 
				C470.900482,290.802155 477.563507,290.153107 484.118530,291.231628 
				C497.539459,293.439850 510.989929,295.828796 524.119446,299.308350 
				C531.587891,301.287628 532.907349,305.243073 529.303223,311.216705 
				C522.781189,322.026642 515.704590,332.504852 509.285583,343.373230 
				C504.459442,351.544647 501.179626,360.533722 498.124939,369.468597 
				C490.080139,392.999390 484.185822,417.005371 486.064178,442.186340 
				C486.871704,453.011871 489.538208,463.573883 493.209259,473.732178 
				C496.953613,484.093231 500.271698,494.510223 502.414978,505.752686 
			z"/>
			<path onClick={() => this.props.onFill(41)} fill={this.props.fillColors[41]} opacity="1.000000" stroke="black" 
				d="
			M719.431641,179.358994 
				C726.875122,175.277191 734.079590,171.987854 742.298645,170.527496 
				C753.303711,168.572098 763.518677,163.743011 773.424255,158.670486 
				C783.348694,153.588318 793.282349,154.042831 803.440063,157.014572 
				C816.714294,160.898087 827.301147,168.278610 834.166260,180.653748 
				C840.805542,192.622009 850.169739,202.664963 858.049561,213.747238 
				C865.385559,224.064819 868.030945,235.996063 866.320618,248.741776 
				C865.481689,254.994263 865.915283,261.314178 865.144531,267.623718 
				C863.346924,282.339874 864.164429,297.184814 863.966248,311.980408 
				C863.818604,322.996796 859.897888,332.811462 853.227417,341.432800 
				C848.116272,348.038757 841.047180,348.795685 833.736816,344.504364 
				C823.567444,338.534760 818.525818,329.031616 815.082825,318.354218 
				C813.961304,314.876282 812.942078,311.352936 812.114075,307.794952 
				C809.197083,295.260529 800.050354,290.397766 788.494690,289.144653 
				C779.762329,288.197723 771.639160,285.954773 763.774475,282.011353 
				C756.407776,278.317627 749.491394,273.574615 740.845764,272.563446 
				C736.900024,272.101959 733.744385,273.870728 730.164124,274.271393 
				C723.346497,275.034515 719.880920,273.653229 716.451782,267.937286 
				C707.131226,252.400879 699.576355,235.931335 691.153931,219.922119 
				C688.961548,215.754669 689.164795,211.224060 692.334534,206.827988 
				C699.895508,196.341827 709.865967,188.322815 719.431641,179.358994 
			z"/>
			<path onClick={() => this.props.onFill(42)} fill={this.props.fillColors[42]} opacity="1.000000" stroke="black" 
				d="
			M611.960083,451.110291 
				C616.128723,441.597961 623.697876,436.398743 632.914734,434.372101 
				C651.066162,430.380890 669.326843,426.628540 688.090576,427.005768 
				C703.383179,427.313232 714.391479,435.019073 716.239685,451.838440 
				C717.728333,465.386200 715.721069,478.665131 715.237427,492.063446 
				C715.081421,496.388031 715.047119,500.717804 715.007935,505.045624 
				C714.970276,509.210449 715.056641,513.376892 714.986572,517.540894 
				C714.769592,530.444763 708.465027,534.502991 696.865234,528.864014 
				C687.276062,524.202393 677.854919,519.226257 667.151184,517.550171 
				C660.596069,516.523682 654.093323,515.695068 647.415405,517.090515 
				C636.367554,519.399048 628.851746,525.614136 625.099792,536.239624 
				C621.194824,547.298523 619.607056,558.790527 618.992432,570.450867 
				C618.660461,576.750000 618.610046,583.091553 617.800171,589.331909 
				C616.256653,601.225891 605.586914,608.967041 592.102783,610.140137 
				C574.435730,611.677246 561.828247,603.662048 551.210205,590.845703 
				C549.198181,588.417114 547.109741,586.051819 545.066223,583.649170 
				C536.086121,573.091187 534.201538,561.201477 538.452759,548.181641 
				C544.133423,530.783936 552.746887,516.015259 571.477783,509.652557 
				C577.412781,507.636444 583.293335,505.315948 588.642090,501.722198 
				C595.885498,496.855408 600.614441,490.378052 603.435730,482.239929 
				C606.928894,472.163818 607.755371,461.373535 611.960083,451.110291 
			z"/>
			<path onClick={() => this.props.onFill(43)} fill={this.props.fillColors[43]} opacity="1.000000" stroke="black" 
				d="
			M508.006348,470.004059 
				C508.170654,467.962646 507.490479,466.079834 506.914642,464.165405 
				C501.517670,446.221832 503.604126,428.867767 510.433441,411.651123 
				C516.158813,397.217651 525.247803,384.643463 532.010925,370.816132 
				C535.494324,363.694122 538.396912,356.313843 540.414368,348.559631 
				C542.272339,341.418243 545.799500,335.182037 553.823425,333.283295 
				C561.180298,331.542389 570.421509,337.300079 573.757751,345.796173 
				C576.418762,352.572815 579.645386,359.071198 581.564026,366.224701 
				C583.387329,373.023193 589.879883,376.705902 595.096252,381.016571 
				C596.755615,382.387817 598.630859,383.497986 600.698853,384.903442 
				C603.069946,386.272308 603.264282,388.344452 603.088806,390.325104 
				C602.477112,397.228363 604.431458,403.096039 609.808716,408.142639 
				C610.840881,412.089752 609.018127,415.061676 607.476624,417.923065 
				C603.763000,424.816467 600.634460,431.973724 597.537964,439.140289 
				C594.753052,445.585602 594.004517,452.569489 593.033752,459.444946 
				C592.313843,464.542908 591.270874,469.537018 589.914429,474.501068 
				C586.662598,486.401245 577.870178,492.264618 566.994690,495.970886 
				C563.546082,497.146149 560.004456,498.052856 556.317688,499.718628 
				C550.882935,501.808990 545.799438,503.735016 540.700439,505.619446 
				C531.943359,508.855774 528.570496,506.976074 523.251526,499.031311 
				C518.140625,491.397278 514.387756,482.854095 509.964722,474.357910 
				C509.386719,472.832123 509.205719,471.521454 508.191071,470.226990 
				C507.987976,469.975494 507.994598,469.998810 508.006348,470.004059 
			z"/>
			<path onClick={() => this.props.onFill(44)} fill={this.props.fillColors[44]} opacity="1.000000" stroke="black" 
				d="
			M984.000000,180.107483 
				C984.530518,198.236069 982.992188,215.835342 982.742493,233.467560 
				C982.571960,245.502487 980.001404,257.495209 978.660950,269.524292 
				C977.479248,280.127838 977.617615,290.757996 978.607788,301.375519 
				C979.429321,310.185333 979.187012,319.024323 978.813293,327.822083 
				C978.576111,333.404022 971.679565,335.845490 964.235413,333.522400 
				C957.377197,331.382172 953.241272,326.202515 953.063232,318.756500 
				C952.816956,308.457733 952.549316,298.124634 954.797424,287.962921 
				C955.552612,284.549011 954.085388,281.390625 953.975220,278.075317 
				C953.538147,264.922638 954.461060,251.751801 955.449646,238.722351 
				C956.154785,229.428207 956.783142,219.893417 958.835571,210.656570 
				C961.429749,198.981689 959.041199,187.575409 957.328613,176.065277 
				C956.281860,169.030411 952.190979,162.667206 953.009521,155.289337 
				C953.173279,153.813721 952.987305,152.078186 953.702942,150.919998 
				C959.317566,141.834091 960.195312,131.269958 963.363525,121.428413 
				C965.520264,114.728996 968.664673,108.220840 972.513916,102.186279 
				C974.793457,98.612717 975.994568,94.228775 980.253540,92.130928 
				C980.903015,91.811012 981.259644,90.849228 981.693542,90.148331 
				C982.806763,88.350296 980.584290,84.008186 986.129150,85.520782 
				C988.659485,86.211060 990.401794,86.745331 992.365784,88.638107 
				C1005.262329,101.067039 1006.309692,115.901253 1001.055115,131.717285 
				C999.252991,137.141754 996.167786,142.373199 992.691895,146.951141 
				C988.036072,153.083099 986.231323,159.985626 985.114014,167.235123 
				C984.483765,171.323944 984.354858,175.490021 984.000000,180.107483 
			z"/>
			<path onClick={() => this.props.onFill(45)} fill={this.props.fillColors[45]} opacity="1.000000" stroke="black" 
				d="
			M695.390503,967.835815 
				C695.200806,956.863098 695.345093,945.669067 690.838318,935.158936 
				C688.948181,930.750916 686.572205,926.567688 684.817444,922.062622 
				C679.437866,908.250916 684.092224,895.708862 695.489441,887.400391 
				C712.934509,874.683105 729.354248,860.761658 745.354980,846.319763 
				C752.127930,840.206726 759.285645,834.403076 764.850769,827.128174 
				C775.010010,813.847595 776.065918,798.311768 774.871094,782.438477 
				C772.627502,752.632507 763.490723,724.577393 752.994324,696.850403 
				C746.649658,680.090515 740.726074,663.171143 734.629761,646.317505 
				C733.219299,642.418396 731.876038,638.494995 730.234009,634.349854 
				C727.457886,634.205566 726.697083,632.330688 726.019470,630.484680 
				C724.730408,626.973083 723.359192,623.479858 722.729004,619.321411 
				C723.486694,613.889282 721.382385,609.191589 721.148315,604.276978 
				C720.936279,599.823792 720.537354,595.438538 721.913696,591.087891 
				C722.482544,589.289673 723.183716,587.705444 725.470337,586.955078 
				C739.030762,632.192627 754.050415,676.753540 770.620361,720.827576 
				C777.574219,739.323975 782.108398,758.584778 784.171448,778.405457 
				C785.396423,790.174377 786.962402,801.776428 783.695984,813.439026 
				C780.126892,826.181824 771.659058,835.491272 762.253723,844.076050 
				C755.640991,850.111816 748.310852,855.397339 741.985657,861.704468 
				C731.335571,872.324280 719.174133,880.995972 707.317932,890.081726 
				C693.847656,900.404541 691.901245,907.890015 699.150940,923.190735 
				C701.012695,927.119934 702.441223,931.176514 703.949585,935.229065 
				C708.698608,947.988281 703.447510,958.130981 695.390503,967.835815 
			z"/>
			<path onClick={() => this.props.onFill(46)} fill={this.props.fillColors[46]} opacity="1.000000" stroke="black" 
				d="
			M797.823425,450.773560 
				C796.358337,455.792664 794.686157,460.470947 792.981201,465.519440 
				C789.558777,469.836334 787.073792,474.471649 785.571899,479.268616 
				C782.363403,489.515961 777.176270,498.608154 771.040039,507.672180 
				C767.775024,509.884186 764.422913,509.129486 761.150879,508.815613 
				C756.586548,508.377686 752.285522,508.937103 748.116455,511.024933 
				C744.987183,512.592102 741.566833,513.494812 738.020264,513.500610 
				C735.751465,513.504272 733.770386,512.725525 732.367798,510.289978 
				C731.213135,494.501038 732.819397,479.133392 734.758972,463.387634 
				C733.425659,453.529388 735.870728,444.604980 738.970459,435.424408 
				C740.810120,434.044128 742.245789,434.677277 743.264221,435.808289 
				C747.149963,440.123474 752.362915,441.022766 757.688843,441.616699 
				C764.326538,442.356903 770.687256,444.068451 776.955872,446.363098 
				C782.546509,448.409546 788.185974,450.598206 794.398193,449.365295 
				C795.552856,449.136139 796.690857,449.628174 797.823425,450.773560 
			z"/>
			<path onClick={() => this.props.onFill(47)} fill={this.props.fillColors[47]} opacity="1.000000" stroke="black" 
				d="
			M553.868286,621.485168 
				C547.667175,615.328796 541.066650,610.110962 533.296082,606.713379 
				C528.411072,604.577576 523.364441,606.096436 519.656860,609.108521 
				C509.871124,617.058472 498.056549,621.176514 487.301605,627.261047 
				C479.300385,631.787537 472.658539,638.063232 465.351929,643.473694 
				C453.581238,652.189819 440.622223,656.723755 425.455994,655.925049 
				C431.537048,651.830505 438.838654,649.460632 446.011780,646.960510 
				C455.970428,643.489624 464.207611,637.360840 472.340332,631.054688 
				C478.559540,626.232300 485.093842,621.908752 492.499359,619.441040 
				C501.581940,616.414551 509.937469,612.543823 517.506714,606.469482 
				C527.710266,598.281006 535.372253,598.665405 547.228760,605.707520 
				C555.773071,610.782532 564.235229,616.035767 574.205139,618.330933 
				C587.996277,621.505615 601.397461,619.767456 614.306213,615.100647 
				C624.515869,611.409546 627.640930,602.202881 627.929871,592.187134 
				C628.283936,579.910645 627.263550,567.557007 630.036621,555.440674 
				C631.827393,547.616821 634.288574,540.122375 640.241577,534.167786 
				C645.926758,528.481201 652.914734,527.234558 660.353638,527.666992 
				C676.572449,528.609802 689.003479,537.324158 700.369385,547.924133 
				C704.627014,551.894958 708.584656,556.187500 712.860168,560.607300 
				C709.225098,563.849121 703.252686,563.072876 697.797119,558.874390 
				C693.467651,555.542480 689.961853,551.305237 685.556519,548.011169 
				C681.833252,545.227051 677.997864,542.699768 674.033875,540.287537 
				C668.077026,536.662537 661.621155,537.337219 655.203247,537.634888 
				C652.946411,537.739624 651.310852,539.316589 649.801270,541.402832 
				C649.381226,542.136292 649.102722,542.531677 648.574707,543.196167 
				C647.713867,544.220947 647.052979,544.938354 646.232605,546.030945 
				C640.955627,554.011108 639.680908,562.740295 639.290039,571.594788 
				C638.908203,580.246033 639.380615,588.922058 639.030823,597.993225 
				C634.426453,612.577637 624.234741,621.196838 610.799438,626.304016 
				C607.572754,627.530518 604.393738,628.978699 600.622803,629.965698 
				C586.405273,630.450806 572.575134,631.218262 559.390015,625.765381 
				C557.261536,624.885132 554.864929,624.419983 553.868286,621.485168 
			z"/>
			<path onClick={() => this.props.onFill(48)} fill={this.props.fillColors[48]} opacity="1.000000" stroke="black" 
				d="
			M740.406860,434.744202 
				C739.656311,444.295532 735.827576,452.950836 734.932129,462.583252 
				C732.029053,452.449829 729.444641,441.865265 725.015808,431.749115 
				C718.419861,416.682892 701.607361,410.314301 687.860901,412.296692 
				C666.202454,415.420074 644.685608,419.617798 623.204590,423.847626 
				C607.892883,426.862640 598.223328,435.625214 595.604431,451.888885 
				C594.270081,460.175507 593.020203,468.554260 590.308655,476.684845 
				C586.475647,488.178467 577.583191,493.202576 567.304016,496.905182 
				C563.875427,498.140167 560.267456,498.877197 556.357483,499.871765 
				C557.906555,497.182617 561.180298,496.869781 563.902100,496.171509 
				C583.162476,491.230743 590.530823,477.481140 591.912231,459.159241 
				C592.950623,445.387787 596.930969,432.557953 603.697693,420.529633 
				C605.641418,417.074524 607.151306,413.375336 608.916260,409.439026 
				C610.757690,406.218292 613.829895,406.454498 616.536438,406.221283 
				C621.503662,405.793243 626.519897,405.804932 631.492859,405.909637 
				C645.699402,406.208954 659.191772,402.807739 672.606384,398.724640 
				C677.532959,397.225067 682.512512,395.762268 687.615173,395.090820 
				C694.162659,394.229248 700.562988,395.125427 706.962769,398.164246 
				C708.331360,398.594452 709.243042,399.096344 710.568359,399.576660 
				C711.140564,399.817657 711.353333,399.945770 711.824585,400.335846 
				C713.973083,403.442383 717.166138,404.618774 719.832520,406.396240 
				C723.743286,409.003174 727.817871,411.399170 732.285706,413.843262 
				C738.176819,419.722687 741.236389,426.393799 740.406860,434.744202 
			z"/>
			<path onClick={() => this.props.onFill(49)} fill={this.props.fillColors[49]} opacity="1.000000" stroke="black" 
				d="
			M732.043152,510.391602 
				C737.934509,512.659180 743.186829,511.076172 748.561890,508.381897 
				C752.389648,506.463257 756.631165,505.783661 761.044006,506.651794 
				C764.083130,507.249725 767.214172,507.380493 770.588013,507.847351 
				C766.554077,524.110596 759.464172,538.989380 750.405945,552.965149 
				C746.588013,558.855652 741.668884,563.722778 735.870361,567.625732 
				C733.105103,569.486938 729.688110,570.354187 726.659424,568.839600 
				C723.716309,567.367798 723.683289,564.101257 723.824890,561.067871 
				C724.512878,546.328308 727.866089,531.988464 730.333679,517.516968 
				C730.721008,515.245117 731.402893,513.023376 732.043152,510.391602 
			z"/>
			<path onClick={() => this.props.onFill(50)} fill={this.props.fillColors[50]} opacity="1.000000" stroke="black" 
				d="
			M648.047546,541.447998 
				C650.137024,536.490051 654.516968,535.797546 659.089966,535.532471 
				C669.391541,534.935425 677.481384,539.826355 685.335144,545.731750 
				C691.507263,550.372681 696.001648,557.190857 703.927734,559.634705 
				C706.693359,560.487427 709.323120,561.138794 712.608887,560.917114 
				C721.464661,565.805847 723.831848,574.016052 725.001221,582.886902 
				C725.149902,584.014709 725.427368,585.125549 725.773804,586.614136 
				C721.924011,594.634033 722.744568,602.714905 723.769958,610.811951 
				C724.081787,613.274353 725.300659,615.591919 724.924133,618.577393 
				C722.201538,619.149109 721.727051,616.975525 721.065857,615.170959 
				C715.686829,600.490479 709.872559,586.003723 701.761108,572.571777 
				C695.726196,562.578430 688.948425,553.231201 678.983826,546.631165 
				C670.705139,541.147766 662.089233,538.140442 652.198364,541.790222 
				C650.935303,542.256287 649.599243,542.374939 648.047546,541.447998 
			z"/>
			<path onClick={() => this.props.onFill(51)} fill={this.props.fillColors[51]} opacity="1.000000" stroke="black" 
				d="
			M970.181946,37.137650 
				C971.866028,32.634464 972.757996,28.173716 976.744995,25.323151 
				C977.629028,24.691133 978.031616,23.189322 979.436096,23.574772 
				C980.816772,23.953726 981.728943,25.098328 981.817444,26.507540 
				C982.485901,37.149021 985.756409,47.687382 982.982666,58.475914 
				C982.302612,61.121056 980.918030,62.266766 978.347961,61.447601 
				C971.827820,59.369366 966.233704,43.242687 970.181946,37.137650 
			z"/>
			<path onClick={() => this.props.onFill(52)} fill={this.props.fillColors[52]} opacity="1.000000" stroke="black" 
				d="
			M896.792725,268.588562 
				C898.569641,274.295105 900.341797,279.614868 901.630066,285.106201 
				C902.832947,290.233459 900.398560,294.500854 899.112671,298.992126 
				C898.495972,301.146057 896.790649,301.916229 894.193237,299.750519 
				C891.011353,297.097504 888.682495,294.543121 888.206177,290.239899 
				C887.497192,283.835022 885.812866,277.426666 888.265381,271.114960 
				C889.765503,267.254547 894.719116,265.753448 896.792725,268.588562 
			z"/>
			<path onClick={() => this.props.onFill(53)} fill={this.props.fillColors[53]} opacity="1.000000" stroke="black" 
				d="
			M508.035400,470.300781 
				C509.336121,470.681946 510.005127,471.783997 509.912811,473.661194 
				C509.288483,472.878998 508.685638,471.752533 508.035400,470.300781 
			z"/>
			<path onClick={() => this.props.onFill(54)} fill={this.props.fillColors[54]} opacity="1.000000" stroke="black" 
				d="
			M775.582886,608.757874 
				C779.343933,593.077881 785.969482,579.960876 800.871216,572.541382 
				C821.931885,562.055298 842.106812,570.778931 848.880493,593.274963 
				C855.283508,614.539673 851.882507,635.359131 842.245361,654.159119 
				C831.572998,674.978699 805.869507,676.515808 790.484497,661.832214 
				C777.066589,649.026123 772.436829,632.870850 774.834656,614.600281 
				C775.072021,612.791199 775.248230,610.974121 775.582886,608.757874 
			z"/>
			<path onClick={() => this.props.onFill(55)} fill={this.props.fillColors[55]} opacity="1.000000" stroke="black" 
				d="
			M848.934937,718.242065 
				C853.681519,707.338440 860.631897,699.081604 871.356079,694.291870 
				C879.012573,690.872314 888.703003,692.671875 894.789917,698.461121 
				C899.098755,702.559204 903.977844,706.058899 908.632080,709.739258 
				C912.148132,712.519653 913.972229,716.088074 915.715637,719.653076 
				C918.700806,725.757263 915.786133,731.578857 911.576721,735.450012 
				C906.097290,740.489197 900.335022,745.648682 892.087708,746.631470 
				C887.273987,747.205017 882.666077,746.546387 878.378479,745.255798 
				C873.665833,743.837158 868.870728,742.600342 864.184875,741.027222 
				C854.385193,737.737488 845.125854,728.441772 848.934937,718.242065 
			z"/>
			<path onClick={() => this.props.onFill(56)} fill={this.props.fillColors[56]} opacity="1.000000" stroke="black" 
				d="
			M35.528393,810.550049 
				C37.617886,806.197205 41.598034,804.692322 45.264015,803.586426 
				C56.995232,800.047607 68.886520,799.961792 80.441780,804.383057 
				C90.723961,808.317078 92.416595,817.254944 84.073555,827.113098 
				C72.922165,840.289307 50.757904,844.321289 36.540432,832.493713 
				C30.025326,827.073792 29.556908,817.261963 35.528393,810.550049 
			z"/>
			<path onClick={() => this.props.onFill(57)} fill={this.props.fillColors[57]} opacity="1.000000" stroke="black" 
				d="
			M116.207779,428.268524 
				C117.457939,426.523193 119.012703,425.031860 120.776711,423.296631 
				C133.958191,419.493317 141.304367,409.927582 147.181671,398.697754 
				C151.797897,389.877441 155.128723,380.275574 162.138428,372.873199 
				C165.438446,369.388336 168.973328,366.101532 172.605087,362.960052 
				C181.397400,355.354675 189.190170,346.624023 198.713623,339.842773 
				C202.673889,337.022827 206.856674,334.365021 211.413559,332.957428 
				C227.824493,327.888245 239.584183,317.348877 247.206451,302.395233 
				C252.211426,292.576294 260.418945,287.881622 270.201172,284.814545 
				C274.846924,283.357971 279.397552,281.448822 283.284973,278.686035 
				C292.630920,272.043884 303.081116,270.978424 313.831573,272.205078 
				C323.935394,273.357971 334.106995,272.638275 344.172333,274.004547 
				C354.900269,275.460754 364.883789,278.721588 373.235352,285.923920 
				C375.654877,288.010468 375.514618,290.793030 374.243073,293.258698 
				C368.117554,305.136597 361.573090,316.691772 349.240967,323.489044 
				C341.229370,327.904907 335.054596,334.368073 329.790527,341.731537 
				C324.825714,348.676331 317.614136,352.159515 309.698700,353.716217 
				C298.934875,355.833069 291.267181,361.296295 286.210297,371.045166 
				C282.560577,378.081177 276.304321,382.538574 269.238617,385.924622 
				C267.742828,386.641418 265.451385,386.851898 265.772522,389.485138 
				C266.052368,391.780029 267.560242,392.875092 269.530701,393.699982 
				C277.323700,396.962311 285.119202,396.986603 292.795990,393.520477 
				C301.823029,389.444733 311.267761,386.934113 321.072174,385.982330 
				C331.585876,384.961609 336.128754,377.022644 340.716858,369.344727 
				C343.426575,364.810211 345.285309,359.775574 348.119446,355.240509 
				C350.161072,351.973572 353.088837,349.785217 356.242340,348.068481 
				C359.788513,346.137939 363.802368,346.220947 367.080750,348.848846 
				C370.701050,351.750854 371.645996,355.854523 371.394562,360.427856 
				C370.596741,374.939667 367.314728,389.437836 370.988281,403.990509 
				C371.514923,406.076721 372.095947,408.149231 372.811096,410.565369 
				C374.363953,414.143677 373.671814,417.382233 373.126007,420.682983 
				C370.874969,434.296204 368.487549,447.884003 364.259003,461.047455 
				C363.176666,464.416931 362.227295,468.272064 357.484985,469.110443 
				C358.868225,457.739594 362.713470,446.871338 362.180939,435.185669 
				C361.840240,427.710632 357.108063,418.739105 350.052338,416.324005 
				C345.608856,414.803070 341.502655,412.570587 337.504578,410.400909 
				C327.951782,405.216827 318.279602,405.328247 309.337891,410.116516 
				C298.019287,416.177673 286.802887,417.491119 274.755676,413.748779 
				C264.113007,410.442749 254.136993,412.797760 244.405930,417.288422 
				C234.464630,421.876068 224.556671,426.554077 213.370514,428.899963 
				C205.916275,431.297760 198.770889,432.946503 191.183716,432.360626 
				C182.434662,431.685059 175.919983,427.257935 170.565033,420.811157 
				C165.219070,414.375183 163.370087,414.096161 156.947693,419.725433 
				C153.075806,423.119232 149.018967,426.231293 144.498138,428.714233 
				C142.706467,429.698334 140.839981,430.267487 138.401886,429.828491 
				C130.823441,429.947113 123.576050,430.679443 116.207779,428.268524 
			z"/>
			<path onClick={() => this.props.onFill(58)} fill={this.props.fillColors[58]} opacity="1.000000" stroke="black" 
				d="
			M356.952393,469.299133 
				C362.996918,463.305359 363.872894,455.511353 365.473236,448.186066 
				C368.127136,436.038239 370.183868,423.759949 372.731262,411.219666 
				C380.378357,417.389862 383.891876,425.866211 385.674683,435.635254 
				C384.295593,448.440643 382.283203,460.933228 383.038147,473.605774 
				C383.501404,481.381470 384.318207,489.136505 385.001892,496.898560 
				C386.245148,511.013611 383.126953,524.678284 380.650848,538.395508 
				C379.393036,545.363647 379.826141,552.372986 380.117371,559.313538 
				C380.632599,571.593567 375.711853,581.710449 368.485840,590.893921 
				C357.752319,604.535095 356.346741,622.966125 368.452332,636.024658 
				C372.170502,640.035522 375.577118,644.337585 379.081268,648.543945 
				C386.108948,656.979919 388.844025,666.886841 389.004120,677.674866 
				C389.178589,689.427979 386.842743,701.850586 393.238586,712.400635 
				C399.011444,721.923157 399.164886,730.478149 395.602997,740.739563 
				C392.506042,749.661499 394.636139,759.549744 395.250336,768.899475 
				C396.236267,783.908386 391.878662,796.271484 380.996765,806.603516 
				C376.519440,810.854614 373.212891,816.092957 370.902466,821.875366 
				C365.115204,836.359741 373.642700,850.367432 384.456085,859.599976 
				C388.765259,863.279175 393.802856,865.725586 399.347321,867.391418 
				C410.617493,870.777344 414.113800,875.365295 414.895203,887.617737 
				C415.370850,895.075867 414.566040,902.402405 412.457550,909.741455 
				C410.709869,915.824768 406.575958,918.103638 401.392365,919.481018 
				C396.239960,920.850159 394.537842,925.792419 396.155701,931.057800 
				C397.526093,935.517944 400.548676,939.352051 400.084503,944.727905 
				C399.565094,950.742859 396.884216,955.421387 393.843964,960.063538 
				C385.978699,972.072754 375.847992,982.804565 373.531342,997.827332 
				C372.166351,1006.678772 370.521362,1015.487244 368.977783,1024.310669 
				C368.749847,1025.613525 368.386108,1026.892700 367.997314,1028.574707 
				C363.913696,1026.856934 367.384460,1023.500610 366.744934,1020.440796 
				C368.291779,1007.702515 369.155457,995.305298 371.134094,983.088684 
				C373.576721,968.007568 366.287231,957.318542 356.632599,947.469482 
				C352.957642,943.720337 349.443207,939.704773 344.391785,937.052124 
				C341.383209,936.392639 340.415985,934.102234 339.631927,931.997131 
				C335.142151,919.942444 327.289001,910.123718 319.015808,900.580322 
				C315.368286,896.372803 313.050537,891.444153 310.912750,886.398804 
				C310.256409,884.849854 309.870056,883.238281 310.805969,881.288696 
				C314.183624,879.317871 315.034393,876.025269 315.537384,873.116821 
				C317.120575,863.962036 320.551178,855.885925 327.609100,849.218872 
				C328.768341,855.726013 326.955170,861.998901 324.434265,867.977844 
				C318.771515,881.408447 321.963623,892.496033 332.492859,902.024475 
				C338.145569,907.139893 342.780243,913.299561 348.849854,918.007812 
				C354.778259,922.606384 358.815948,922.193054 362.906097,916.118042 
				C367.837311,908.793823 371.375061,900.713623 374.819305,892.637024 
				C378.453094,884.115906 376.163025,876.023376 371.859192,868.412048 
				C368.837585,863.068359 365.695190,857.751587 362.126465,852.766602 
				C352.361633,839.126587 353.126984,825.478027 361.800323,811.862122 
				C365.804535,805.576111 370.307068,799.601562 374.699493,793.570068 
				C382.888641,782.325256 384.060577,769.792358 380.326447,756.984558 
				C376.416199,743.572876 372.367981,730.233215 372.177368,716.180359 
				C372.053711,707.065430 372.241943,697.859619 373.391510,688.834839 
				C375.036621,675.919556 372.961639,664.387207 364.040955,654.553772 
				C354.331207,643.850525 346.176086,632.272095 342.972687,617.839905 
				C339.553406,602.434937 340.568573,587.187134 344.475464,572.015686 
				C345.840088,566.716553 347.048889,561.417542 347.106934,555.844666 
				C347.146698,552.029297 346.218964,549.023132 343.097473,546.340637 
				C340.630646,544.750305 338.451691,543.339233 336.296722,541.817627 
				C331.089325,538.140686 324.751953,538.054443 318.729675,537.897522 
				C311.598114,537.711792 304.954742,536.882080 299.419739,531.213928 
				C293.856079,522.532410 290.224365,513.425110 288.036621,503.319275 
				C285.348969,492.656189 290.505035,485.065613 296.738586,477.935791 
				C298.097748,476.381287 300.124359,474.423035 302.268799,476.340851 
				C304.044098,477.928558 302.784149,480.022644 301.670197,481.765106 
				C298.034393,487.451965 296.603302,493.210358 298.626129,500.147339 
				C301.006989,508.312073 302.860413,516.551086 307.775482,523.706177 
				C315.454437,534.884521 325.378052,532.956604 333.149719,524.726196 
				C342.550964,514.770081 339.105316,501.576508 333.706421,491.303192 
				C329.890808,484.042664 324.331635,478.397797 315.791046,476.620697 
				C314.509674,476.354065 312.988770,475.960938 312.850159,474.352203 
				C312.705780,472.676880 313.895630,471.606628 315.272461,470.875153 
				C317.555389,469.662262 320.139801,469.483582 322.575562,469.619232 
				C328.858765,469.969147 335.181885,470.336914 341.448517,472.910187 
				C350.830750,476.152679 353.048370,475.644287 356.952393,469.299133 
			z"/>
			<path onClick={() => this.props.onFill(59)} fill={this.props.fillColors[59]} opacity="1.000000" stroke="black" 
				d="
			M649.389893,57.878517 
				C645.282166,62.510960 642.735901,67.818260 638.166382,71.597855 
				C635.454285,73.841156 632.674622,75.877739 629.494629,77.356422 
				C624.763916,79.556229 620.494141,78.807365 616.647278,75.313232 
				C601.314636,61.386398 600.753723,40.627312 615.376282,25.980352 
				C617.942566,23.409838 620.257446,20.570410 623.710938,18.956711 
				C632.451843,14.872365 639.944092,16.819889 645.377197,24.766468 
				C646.028503,25.719069 646.739136,26.631065 647.702515,27.735250 
				C648.999756,28.220282 649.801880,28.880714 650.170593,29.825962 
				C653.807129,39.148285 654.853577,48.452881 649.389893,57.878517 
			z"/>
			<path onClick={() => this.props.onFill(60)} fill={this.props.fillColors[60]} opacity="1.000000" stroke="black" 
				d="
			M398.752502,264.736450 
				C404.195038,261.114258 408.588715,256.252563 415.755981,256.916077 
				C419.313690,257.245422 422.496490,257.973785 424.223785,261.133270 
				C425.972260,264.331482 424.355804,267.845215 422.249084,269.806610 
				C413.653748,277.809143 403.989441,284.233368 391.773682,284.898407 
				C386.007568,285.212372 385.170105,283.186584 387.930847,278.019348 
				C390.626984,272.972992 394.240997,268.731964 398.752502,264.736450 
			z"/>
			<path onClick={() => this.props.onFill(61)} fill={this.props.fillColors[61]} opacity="1.000000" stroke="black" 
				d="
			M342.692444,233.938522 
				C335.982544,233.567764 333.180389,231.141602 330.893433,224.093384 
				C329.283447,219.131439 330.898865,213.017578 334.621216,209.984726 
				C338.937683,206.467773 346.478882,207.233749 350.492340,211.596756 
				C354.931305,216.422318 355.259888,224.756180 350.900818,228.841873 
				C348.646729,230.954605 346.571350,233.586472 342.692444,233.938522 
			z"/>
			<path onClick={() => this.props.onFill(62)} fill={this.props.fillColors[62]} opacity="1.000000" stroke="black" 
				d="
			M389.150452,205.303070 
				C389.056824,203.414566 388.729858,201.844894 389.090790,200.453690 
				C390.041962,196.787460 391.043427,192.923157 395.189850,191.504807 
				C399.188751,190.136902 401.716339,192.594696 403.965302,195.430069 
				C404.169800,195.687866 404.432373,195.898773 404.657867,196.140884 
				C410.186310,202.077347 411.348297,206.141113 408.296478,208.858719 
				C403.022675,213.554916 395.034454,212.873520 390.352661,207.314514 
				C389.932831,206.816010 389.602509,206.242126 389.150452,205.303070 
			z"/>
			<path onClick={() => this.props.onFill(63)} fill={this.props.fillColors[63]} opacity="1.000000" stroke="black" 
				d="
			M648.984131,58.473755 
				C649.751099,50.847439 652.452332,43.709278 650.009644,36.291370 
				C649.194824,33.817131 648.676331,31.245302 648.002563,28.313354 
				C647.982849,26.430252 647.982849,24.951895 647.982849,23.245281 
				C655.930786,29.264118 659.365967,51.523064 649.463684,63.590004 
				C648.526184,62.051758 649.199524,60.401413 648.984131,58.473755 
			z"/>
			<path onClick={() => this.props.onFill(64)} fill={this.props.fillColors[64]} opacity="1.000000" stroke="black" 
				d="
			M434.991089,158.111893 
				C436.488434,156.143143 437.562256,153.765503 440.146088,154.437164 
				C442.934021,155.161880 442.848480,157.782898 443.112915,160.124939 
				C443.369629,162.398376 444.231445,164.540527 443.556915,166.961121 
				C442.620117,170.322617 441.886353,173.709244 437.236755,172.749146 
				C434.093750,172.100143 431.658173,167.480225 432.597992,163.954010 
				C433.104401,162.053909 434.063873,160.274551 434.991089,158.111893 
			z"/>
			<path onClick={() => this.props.onFill(65)} fill={this.props.fillColors[65]} opacity="1.000000" stroke="black" 
				d="
			M632.238647,112.286201 
				C632.088745,110.807289 630.678223,109.550964 632.150940,108.487282 
				C632.682007,108.103691 633.547852,108.570770 633.661377,109.236160 
				C633.910095,110.694412 634.726257,112.265846 633.248474,113.552437 
				C633.237732,113.561760 632.661377,112.921425 632.238647,112.286201 
			z"/>
			<path onClick={() => this.props.onFill(66)} fill={this.props.fillColors[66]} opacity="1.000000" stroke="black" 
				d="
			M557.746643,83.162239 
				C557.592285,84.725128 556.804932,85.131378 555.791016,84.549767 
				C555.433838,84.344894 555.161011,83.305351 555.367371,82.936378 
				C555.960632,81.875443 556.853577,81.979370 557.746643,83.162239 
			z"/>
			<path onClick={() => this.props.onFill(67)} fill={this.props.fillColors[67]} opacity="1.000000" stroke="black" 
				d="
			M310.877258,880.716431 
				C314.255280,890.428528 319.469971,898.568237 326.257721,905.843262 
				C334.437897,914.610779 339.413391,925.327026 343.978821,936.636536 
				C342.136353,945.544250 336.847778,950.344177 328.258331,952.233582 
				C319.473785,954.165894 310.547211,955.485352 302.318024,951.641418 
				C295.324982,948.374939 289.151398,943.344116 282.643433,939.051331 
				C271.338593,931.594604 265.870209,920.458618 263.201172,907.679932 
				C262.085266,902.337341 260.673981,897.061096 260.927216,891.442993 
				C261.389191,881.195557 269.084290,872.317078 279.246460,870.988464 
				C288.635345,869.760803 298.084503,867.883423 306.284058,875.076660 
				C308.099609,876.669495 309.198730,878.673584 310.877258,880.716431 
			z"/>
			<path onClick={() => this.props.onFill(68)} fill={this.props.fillColors[68]} opacity="1.000000" stroke="black" 
				d="
			M366.707214,1020.305542 
				C367.106781,1022.788269 367.279968,1025.556885 367.681274,1028.646118 
				C366.739258,1041.753906 365.339783,1054.525269 364.462280,1067.332642 
				C363.549103,1080.660522 358.961121,1093.555420 359.920258,1107.550537 
				C357.804230,1114.240234 353.325287,1118.207397 347.269775,1121.018311 
				C338.854004,1116.536255 331.702362,1121.185181 324.506165,1123.605713 
				C321.472504,1124.626099 318.913239,1125.168335 315.911652,1124.149780 
				C311.495941,1122.651367 307.098511,1121.172241 302.937714,1118.999878 
				C291.313934,1112.930908 288.501434,1101.372925 297.052002,1091.692749 
				C305.979004,1081.586304 316.329834,1073.370117 330.382111,1071.333130 
				C334.888824,1070.679932 339.276276,1071.937500 343.762512,1071.505981 
				C352.115784,1070.702515 356.083740,1067.794556 357.833618,1059.484985 
				C360.194641,1048.273438 361.871918,1036.917725 365.017761,1025.872192 
				C365.518036,1024.115601 365.993774,1022.351868 366.707214,1020.305542 
			z"/>
			<path onClick={() => this.props.onFill(69)} fill={this.props.fillColors[69]} opacity="1.000000" stroke="black" 
				d="
			M221.386902,1112.376709 
				C227.692184,1107.964111 234.099335,1105.234253 241.683685,1105.974243 
				C246.883255,1106.481567 249.314316,1109.563477 248.289764,1114.718018 
				C247.827621,1117.042969 246.902100,1119.070679 245.633865,1121.081909 
				C241.080887,1128.302124 234.864212,1134.106567 229.352859,1140.506470 
				C228.183517,1141.864258 226.551666,1142.764526 224.633102,1143.092407 
				C221.484131,1143.630615 218.824707,1144.864136 218.615204,1148.902710 
				C214.598282,1138.412231 210.269211,1128.288696 216.088104,1117.244019 
				C217.293884,1114.955322 219.343552,1114.046021 221.386902,1112.376709 
			z"/>
			<path onClick={() => this.props.onFill(70)} fill={this.props.fillColors[70]} opacity="1.000000" stroke="black" 
				d="
			M332.115479,1037.242432 
				C326.239899,1040.360596 320.620514,1042.776611 314.390411,1039.173096 
				C310.509125,1036.928101 308.004730,1033.721313 308.138336,1029.137207 
				C308.263824,1024.833740 311.028442,1022.248779 314.796082,1020.604919 
				C318.903595,1018.812744 322.965576,1017.030762 327.505798,1016.380493 
				C332.284851,1015.695984 336.131622,1016.501648 338.351685,1021.170288 
				C340.187103,1025.029907 338.763672,1031.718262 335.563354,1034.581421 
				C334.575989,1035.464722 333.463043,1036.207642 332.115479,1037.242432 
			z"/>
			<path onClick={() => this.props.onFill(71)} fill={this.props.fillColors[71]} opacity="1.000000" stroke="black" 
				d="
			M347.009216,1121.420166 
				C351.055176,1116.792969 355.197479,1112.573975 359.615112,1108.186035 
				C362.310974,1116.143433 362.824219,1124.375732 362.380280,1132.868042 
				C361.879822,1142.440674 359.051666,1151.372437 356.316559,1160.719360 
				C353.055634,1156.219238 350.025604,1151.440674 346.961121,1146.323242 
				C347.418518,1140.066650 348.453125,1134.190674 348.035828,1128.204468 
				C347.885071,1126.041748 347.965729,1123.875854 347.009216,1121.420166 
			z"/>
			<path onClick={() => this.props.onFill(72)} fill={this.props.fillColors[72]} opacity="1.000000" stroke="black" 
				d="
			M998.744751,878.244751 
				C1001.721985,881.176758 1004.408997,883.860291 1007.518433,886.155823 
				C1016.157104,892.533264 1016.841492,901.577148 1015.741638,911.290405 
				C1014.735168,920.179077 1012.812317,928.940796 1012.902039,937.992249 
				C1013.003418,948.214600 1007.501465,952.931335 997.595642,950.736938 
				C987.034973,948.397461 977.052368,943.886353 967.609070,938.803406 
				C957.293457,933.250977 953.954041,921.909973 956.387512,908.213013 
				C958.072937,898.726624 959.767151,889.106689 955.829285,879.732666 
				C952.287659,871.301819 948.210754,863.086914 944.894287,854.572510 
				C940.292419,842.757751 937.461548,830.646423 942.807922,818.225281 
				C947.423889,807.500916 955.253967,799.903076 966.083069,795.588501 
				C970.469604,793.840698 976.212280,797.429260 977.878357,802.220337 
				C980.656982,810.210754 980.747192,818.446289 981.163696,826.786499 
				C981.894348,841.416809 984.149231,855.862915 991.472473,868.980286 
				C993.356995,872.355652 995.616882,875.405762 998.744751,878.244751 
			z"/>
			<path onClick={() => this.props.onFill(73)} fill={this.props.fillColors[73]} opacity="1.000000" stroke="black" 
				d="
			M1045.020630,535.003296 
				C1057.451172,521.271790 1083.296631,531.969177 1086.315918,546.866699 
				C1087.500732,552.712891 1085.007202,558.023804 1080.685059,562.039734 
				C1073.402100,568.806641 1064.824585,572.110229 1054.691528,571.005676 
				C1047.184937,570.187439 1041.938110,564.993713 1039.063232,555.575256 
				C1036.590088,547.472595 1038.971680,540.930847 1045.020630,535.003296 
			z"/>
			<path onClick={() => this.props.onFill(74)} fill={this.props.fillColors[74]} opacity="1.000000" stroke="black" 
				d="
			M1057.648926,988.073853 
				C1060.269653,986.335022 1062.754517,986.810974 1065.127686,987.597290 
				C1073.749878,990.454041 1080.195312,995.946777 1084.031006,1004.193542 
				C1087.662231,1012.000793 1083.295288,1018.570374 1074.744751,1017.900452 
				C1062.291138,1016.924622 1052.409302,1003.492065 1053.912964,993.071411 
				C1054.250732,990.730652 1054.882812,988.884033 1057.648926,988.073853 
			z"/>
			<path onClick={() => this.props.onFill(75)} fill={this.props.fillColors[75]} opacity="1.000000" stroke="black" 
				d="
			M1061.876709,185.060196 
				C1060.936523,179.026794 1063.706909,174.322189 1068.575928,173.138962 
				C1073.871582,171.852066 1079.285278,174.046768 1080.793091,178.091751 
				C1082.469604,182.589279 1079.831543,189.943100 1075.784424,192.053726 
				C1070.820801,194.642288 1064.729858,191.717712 1061.876709,185.060196 
			z"/>
			<path onClick={() => this.props.onFill(76)} fill={this.props.fillColors[76]} opacity="1.000000" stroke="black" 
				d="
			M1060.652222,1077.259033 
				C1060.939453,1078.461060 1060.909912,1079.391113 1059.711182,1079.673828 
				C1059.481445,1079.728027 1058.947998,1079.471558 1058.939575,1079.334717 
				C1058.870361,1078.200806 1058.950073,1077.101562 1060.652222,1077.259033 
			z"/>
			<path onClick={() => this.props.onFill(77)} fill={this.props.fillColors[77]} opacity="1.000000" stroke="black" 
				d="
			M647.699097,541.322876 
				C662.747070,532.886292 679.550537,541.647339 690.525818,553.736206 
				C707.475891,572.406128 716.195801,595.280579 724.666504,618.725220 
				C725.773560,624.058472 727.854675,628.746094 729.714905,633.849121 
				C731.802002,644.964844 735.536255,655.307373 738.477661,665.858032 
				C745.399048,690.684692 755.518311,714.536194 761.330872,739.691895 
				C765.516541,757.806580 768.985168,776.013184 769.153748,794.787537 
				C769.268616,807.572083 764.213562,817.787170 755.841797,826.532593 
				C737.044250,846.168945 716.381592,863.570007 693.232483,878.010620 
				C684.665710,883.354614 676.587891,889.578674 670.528564,897.957214 
				C668.457520,900.820923 666.851562,903.843689 665.936768,907.276428 
				C664.843445,911.379333 666.066956,914.157837 669.754395,916.451782 
				C677.002319,920.960571 683.626953,926.289734 687.544128,934.149597 
				C692.317139,943.726257 689.756165,952.789612 680.949097,959.057007 
				C674.516174,963.634888 667.466187,966.962708 660.158936,969.788391 
				C649.804688,973.792175 639.622131,978.238037 628.599915,980.379089 
				C623.930237,981.286133 619.389160,981.076233 616.714539,977.540222 
				C614.262329,974.298401 614.323792,970.028687 615.336060,965.402222 
				C617.104004,957.322388 621.750977,950.575256 623.995117,942.836853 
				C624.748779,940.237976 627.377075,939.227417 628.396790,936.926025 
				C628.871277,935.855286 629.612000,934.632568 628.918396,933.652954 
				C628.107056,932.507019 626.823547,933.417908 625.703369,933.591248 
				C622.401184,934.102356 620.214417,936.609863 617.437134,938.052734 
				C605.083252,944.471069 592.570862,950.580322 581.206665,958.785522 
				C575.747437,962.727295 571.303955,967.534302 566.669373,972.309814 
				C560.102295,979.076721 553.202271,985.622498 545.804688,991.450623 
				C539.443359,996.462341 533.565491,1002.159424 526.379761,1006.145020 
				C520.160339,1009.594604 513.817566,1011.356628 506.818451,1009.076172 
				C501.603027,1007.376892 497.509796,1001.769348 498.208588,996.473328 
				C499.839569,984.112427 497.902130,972.401428 492.624756,961.175415 
				C487.396667,950.054260 492.251404,940.905212 498.444855,932.108887 
				C509.439117,916.494324 525.887634,910.285645 543.512146,905.956421 
				C561.775757,901.470154 579.667725,895.901611 596.899658,888.154541 
				C615.059143,879.990601 631.926636,869.761536 647.881531,857.992981 
				C658.425293,850.215759 668.751526,842.107605 677.042847,831.075134 
				C677.185608,830.036133 677.295837,829.650452 677.583130,829.011108 
				C678.191040,828.104736 678.797974,827.610901 679.630005,826.951782 
				C680.139099,826.570068 680.431396,826.362915 680.917725,825.980896 
				C681.231934,825.676331 681.354004,825.549377 681.649170,825.219177 
				C682.169006,824.574036 682.511780,824.129150 682.958679,823.368164 
				C686.941711,818.741455 691.627991,815.279419 695.330139,810.759460 
				C699.791870,805.312195 703.365723,799.440857 706.082336,792.584106 
				C710.519409,784.210388 708.920410,776.534790 704.532715,768.731934 
				C699.795593,757.984985 696.352539,747.093994 691.131714,736.530151 
				C690.739685,734.957092 690.885620,733.710999 690.841675,731.771912 
				C687.389832,721.336975 682.462158,712.228088 677.460693,703.248779 
				C670.224060,690.256104 664.208435,676.785156 659.128296,662.860657 
				C656.026672,654.359436 650.937683,647.337524 644.040649,641.552979 
				C638.212830,636.665222 632.262939,636.504456 627.373291,642.285706 
				C615.560486,656.252625 599.701904,664.353577 584.368713,673.369202 
				C563.378723,685.711060 546.238647,702.040039 534.214355,723.452820 
				C531.854919,727.654419 529.019226,731.514221 525.583374,735.000793 
				C515.040833,745.699097 503.772217,743.381653 496.079376,732.957886 
				C485.086121,718.061951 471.701294,706.918579 455.415985,698.610718 
				C448.406250,695.034668 442.709473,689.596741 438.323761,682.933350 
				C432.820953,674.572693 434.370056,667.011719 442.932953,661.717590 
				C450.481201,657.050781 459.172913,654.879395 467.101166,651.058044 
				C482.406006,643.681091 499.061584,639.658752 514.181763,631.880371 
				C518.018921,629.906433 521.756958,627.699951 525.372192,625.342041 
				C533.984985,619.724304 543.225098,618.561462 553.474121,621.017944 
				C565.849426,626.617126 578.426697,628.852356 591.590698,628.023804 
				C594.407715,627.846497 597.243774,627.971558 600.539429,627.929138 
				C608.000610,626.774902 614.965393,625.373291 622.111267,625.748718 
				C625.599182,625.932068 628.322815,624.605652 629.770081,621.384216 
				C632.884705,614.451965 635.103577,607.220703 636.726562,599.382141 
				C636.933899,589.395386 636.653992,579.769165 637.091125,570.175598 
				C637.505737,561.074890 638.974792,552.096008 644.714478,544.128296 
				C645.538086,543.334656 645.930969,542.587952 646.962402,542.080017 
				C647.358887,541.763855 647.498169,541.614624 647.699097,541.322876 
			z"/>
			<path onClick={() => this.props.onFill(78)} fill={this.props.fillColors[78]} opacity="1.000000" stroke="black" 
				d="
			M618.371216,860.696838 
				C603.953247,870.384338 588.785217,877.827271 573.030090,884.121948 
				C559.420959,889.559143 545.306702,893.416931 531.239380,897.413818 
				C514.899292,902.056458 500.504669,909.954773 488.929749,922.583740 
				C485.205444,926.647339 481.255432,930.613525 476.635925,933.471924 
				C462.050842,942.496948 454.010132,938.475464 445.680328,925.146545 
				C439.373444,915.054688 436.782501,903.789429 436.978210,891.859924 
				C437.177277,879.727112 436.938416,867.686890 434.398041,855.653320 
				C433.145874,849.721985 434.770538,843.329956 440.133942,839.033752 
				C450.193695,830.975647 449.472260,823.375793 444.131531,812.454224 
				C440.214111,804.443298 434.054016,798.200012 427.261749,792.538452 
				C422.201447,788.320557 418.224091,783.235046 415.908081,777.024597 
				C414.248840,772.575317 414.280701,767.984985 417.426544,764.268188 
				C420.691803,760.410278 425.368011,760.377197 429.894409,761.504883 
				C439.234283,763.831665 448.449585,766.704346 457.851257,768.723206 
				C465.696777,770.407898 472.950806,768.823608 478.877747,762.703308 
				C485.109528,756.268311 493.022797,758.082275 500.395874,759.404480 
				C519.876770,762.898010 533.344788,756.537354 542.067078,738.752380 
				C549.059814,724.494080 556.959534,711.011536 569.084106,700.305969 
				C579.633179,690.991516 591.318054,683.481628 603.785767,677.171326 
				C612.085083,672.970764 619.741821,667.569153 628.486328,664.211182 
				C635.689697,661.445068 641.841675,663.565552 646.071533,670.114807 
				C650.156494,676.439636 652.452942,683.597961 655.764709,690.280640 
				C658.277588,695.351379 661.075928,700.247314 663.841919,705.553711 
				C664.283264,712.433044 660.352295,717.720459 658.487244,723.640198 
				C658.199036,724.554932 657.630676,725.406982 657.472473,726.790161 
				C650.226013,742.720947 645.915405,759.160339 644.332336,776.100342 
				C642.676758,793.815552 636.796692,810.109741 629.944397,826.196228 
				C628.727966,829.052185 628.383850,831.935181 627.766724,835.240112 
				C625.791931,844.295532 624.699219,853.267212 618.371216,860.696838 
			z"/>
			<path onClick={() => this.props.onFill(79)} fill={this.props.fillColors[79]} opacity="1.000000" stroke="black" 
				d="
			M656.319336,726.354492 
				C657.395874,718.965820 660.383240,712.545959 663.747559,706.012817 
				C667.821411,708.494141 670.271729,712.434998 673.672424,715.925964 
				C684.870239,727.247559 689.547058,741.784912 695.313721,755.684448 
				C699.259216,765.194336 703.007324,774.793518 702.177490,785.513855 
				C701.727112,791.331909 700.259338,796.764893 696.877991,801.375366 
				C686.184082,815.956909 673.060547,828.178772 658.842346,839.240601 
				C656.651978,840.944641 654.005371,842.099304 651.255371,843.721436 
				C650.954285,843.958069 650.973694,844.003418 650.977783,843.979431 
				C650.587036,844.425903 650.020081,844.488953 649.231934,844.743042 
				C639.061951,850.460388 629.625977,856.961609 618.469482,860.935425 
				C620.736145,852.877747 623.360168,844.777344 626.066711,836.323853 
				C630.559265,831.875427 636.639160,831.199646 641.697815,828.355835 
				C656.256836,820.171387 670.514526,811.630554 681.068542,798.287109 
				C686.203796,791.794556 689.120911,784.597717 686.921204,776.010193 
				C682.858093,760.148743 676.737854,745.415283 664.991821,733.510620 
				C662.397156,730.880859 658.681519,729.742126 656.319336,726.354492 
			z"/>
			<path onClick={() => this.props.onFill(80)} fill={this.props.fillColors[80]} opacity="1.000000" stroke="black" 
				d="
			M651.337708,843.954224 
				C656.181458,837.694946 663.170959,833.433533 669.117554,827.979248 
				C678.807129,819.091675 688.515381,810.127563 695.728516,799.109497 
				C701.522705,790.258728 701.369507,780.168518 698.187927,770.256836 
				C692.483704,752.486633 685.123779,735.443298 675.326111,719.505310 
				C674.809631,718.665161 674.503418,717.695679 674.030151,716.445068 
				C681.510193,718.719788 685.257202,725.529724 690.630005,730.983643 
				C690.963074,731.053467 690.997620,731.025391 690.978638,731.016663 
				C691.725464,732.047729 691.701172,733.457153 692.732910,734.726685 
				C697.526428,745.350525 702.166321,755.692810 705.713501,766.832642 
				C709.353699,775.547302 710.374512,784.224121 708.200439,793.544556 
				C703.092224,806.434082 694.541504,816.103455 684.072388,824.709167 
				C683.517517,825.455872 683.516052,826.045532 683.148438,826.781433 
				C682.961121,826.991821 683.005188,827.005432 682.998535,826.983337 
				C682.589661,827.420715 682.025024,827.489746 681.237915,827.745605 
				C680.402649,828.417419 679.804016,828.936218 679.055359,829.714539 
				C678.539673,830.487122 677.939453,830.483093 677.213074,830.844604 
				C677.009705,831.039917 677.027100,831.002625 677.042725,831.017883 
				C671.724182,833.579102 667.196228,837.472229 661.770142,839.937317 
				C658.465393,841.438599 655.255676,843.065552 651.337708,843.954224 
			z"/>
			<path onClick={() => this.props.onFill(81)} fill={this.props.fillColors[81]} opacity="1.000000" stroke="black" 
				d="
			M425.870300,725.943237 
				C425.163483,730.693542 421.601288,729.613708 418.882568,729.798035 
				C415.924164,729.998474 413.555054,728.455505 411.757812,726.207520 
				C410.999298,725.258789 410.998932,724.174805 412.190125,723.214233 
				C416.006104,720.136902 423.307343,721.350159 425.870300,725.943237 
			z"/>
			<path onClick={() => this.props.onFill(82)} fill={this.props.fillColors[82]} opacity="1.000000" stroke="black" 
				d="
			M649.283386,844.880493 
				C649.466003,844.595764 649.938904,844.298828 650.696899,843.978760 
				C650.512512,844.259766 650.043091,844.564026 649.283386,844.880493 
			z"/>
			<path onClick={() => this.props.onFill(83)} fill={this.props.fillColors[83]} opacity="1.000000" stroke="black" 
				d="
			M341.642365,472.740143 
				C335.838531,473.578369 330.059540,471.960052 324.181885,471.815979 
				C320.670410,471.729919 316.944092,470.993164 313.842407,474.269928 
				C325.773499,475.299561 332.046936,483.180115 336.906372,492.493011 
				C340.084503,498.583801 341.165863,505.367188 341.313416,512.202515 
				C341.532349,522.343262 328.874908,534.606201 318.681122,533.872131 
				C313.636139,533.508789 309.826233,530.242798 306.892700,526.284302 
				C301.457916,518.950562 299.336639,510.299225 296.741516,501.693024 
				C294.605316,494.608795 295.462494,488.538330 298.502869,482.304474 
				C299.382263,480.501282 301.887085,479.625366 301.730042,476.151520 
				C293.016449,483.336945 289.330719,492.153992 287.999969,502.584534 
				C284.782257,495.216644 283.632874,487.451660 286.923065,479.410583 
				C290.626831,470.358795 297.663361,465.388367 306.809570,463.207184 
				C317.226990,460.722870 326.579041,464.227844 335.513214,469.149475 
				C337.509064,470.248962 340.056122,470.365234 341.642365,472.740143 
			z"/>
			<path onClick={() => this.props.onFill(84)} fill={this.props.fillColors[84]} opacity="1.000000" stroke="black" 
				d="
			M154.811615,469.768341 
				C161.522034,464.707611 169.786636,462.746033 175.150360,456.020355 
				C176.746140,454.019379 180.646179,453.953705 180.067719,449.876038 
				C179.943604,449.001190 181.333008,449.070343 182.126114,449.023285 
				C184.937515,448.856506 187.786148,449.320526 190.558731,448.386292 
				C191.101486,448.203369 192.117630,448.306061 192.413528,448.677185 
				C197.076630,454.525879 204.993988,456.191467 209.405884,462.668488 
				C214.399948,470.000214 218.184570,477.421631 218.003860,486.471527 
				C217.873123,493.018066 213.590256,497.444794 207.020599,498.436279 
				C193.280853,500.509735 180.329834,496.556671 167.253189,493.554596 
				C161.417557,492.214935 155.639374,490.550751 150.529816,487.277252 
				C145.115036,483.808258 144.669098,480.210571 148.946762,475.565613 
				C150.728378,473.631042 151.950302,471.128571 154.811615,469.768341 
			z"/>
			<path onClick={() => this.props.onFill(85)} fill={this.props.fillColors[85]} opacity="1.000000" stroke="black" 
				d="
			M138.314911,430.081055 
				C145.375443,425.987274 151.945251,421.000183 158.465622,415.853271 
				C164.150757,411.365631 166.824554,411.937714 171.231018,417.987305 
				C173.940765,421.707489 177.220016,424.560974 181.031723,427.018433 
				C188.355042,431.739716 196.362427,430.315521 204.214035,429.605621 
				C206.849030,429.367340 209.470230,429.018463 212.574615,429.048065 
				C208.768143,431.494354 204.566269,434.023254 200.236008,436.308929 
				C197.266052,437.876556 194.532791,440.025146 191.991669,441.932922 
				C184.400894,447.631744 184.662384,448.313782 175.137421,444.831116 
				C164.947083,441.105164 154.029739,439.426239 144.463226,433.972656 
				C142.457855,432.829437 140.553116,431.509766 138.314911,430.081055 
			z"/>
			<path onClick={() => this.props.onFill(86)} fill={this.props.fillColors[86]} opacity="1.000000" stroke="black" 
				d="
			M299.232666,531.292480 
				C303.566620,531.513000 307.026276,534.791321 311.673492,535.153137 
				C317.678528,535.620667 323.702728,535.855530 329.706635,536.811462 
				C335.612244,537.751709 339.729279,540.462158 342.798431,545.719604 
				C329.924255,549.034058 317.592896,548.302856 306.573669,539.780273 
				C303.628998,537.502808 301.337006,534.701416 299.232666,531.292480 
			z"/>
			<path onClick={() => this.props.onFill(87)} fill={this.props.fillColors[87]} opacity="1.000000" stroke="black" 
				d="
			M150.045685,96.372963 
				C155.039841,92.294525 161.476654,91.274246 166.119537,87.101997 
				C169.637344,83.940788 174.116760,80.973328 171.950150,75.141747 
				C170.363617,70.871483 173.266800,65.841759 168.805267,61.767818 
				C167.358154,60.446415 168.943878,55.775223 169.198303,52.640789 
				C169.254761,51.945374 169.885468,51.115364 170.455429,51.386002 
				C173.446320,52.806198 176.285568,50.597569 178.989487,50.354988 
				C186.769775,49.656982 195.055893,48.088963 201.018295,41.820366 
				C208.319427,34.144249 215.749603,26.576853 222.747330,18.630735 
				C228.065231,12.592113 235.467453,11.367841 242.413788,9.129563 
				C247.377533,7.530124 249.798416,12.512639 252.311523,14.983147 
				C261.853973,24.363779 271.308624,33.668144 283.835571,39.724438 
				C280.639862,40.468414 277.201874,39.967693 273.708832,40.484520 
				C261.717804,42.258686 252.930405,48.594006 246.072937,58.273415 
				C240.358398,66.339569 232.576904,72.423767 225.424133,79.107094 
				C219.807709,84.354897 217.336121,91.524628 214.280853,98.280411 
				C213.372696,100.288559 214.150803,102.406731 215.691132,104.273476 
				C221.179962,110.925545 226.238556,117.953819 231.994873,124.359016 
				C240.777161,134.131287 243.730209,145.342865 241.422195,158.025803 
				C240.671387,162.151688 241.123199,166.277802 240.497803,170.422348 
				C239.320541,178.224167 234.155655,181.226959 227.463028,182.863434 
				C225.537018,183.334381 223.528564,183.468201 221.259186,183.841309 
				C219.413589,182.218384 220.517776,180.497498 220.951370,178.792175 
				C222.825851,171.419724 218.985779,165.821762 214.569183,160.937866 
				C211.001877,156.993134 204.892838,159.513321 203.869278,164.695587 
				C202.932114,169.440475 204.825729,173.428116 206.908585,177.381012 
				C207.744370,178.967178 209.174164,180.307800 208.235596,182.640472 
				C192.490875,178.538788 181.037659,168.160110 171.345276,156.107086 
				C164.655045,147.787354 157.442307,142.626785 146.564758,144.057510 
				C142.604462,144.578415 142.075012,143.058517 142.818939,139.539200 
				C143.672897,135.499359 145.540543,131.986862 147.870483,128.817673 
				C153.300613,121.431557 153.275177,113.430611 151.130920,105.144859 
				C150.414673,102.377136 149.825119,99.668755 150.045685,96.372963 
			z"/>
			<path onClick={() => this.props.onFill(88)} fill={this.props.fillColors[88]} opacity="1.000000" stroke="black" 
				d="
			M264.271423,237.667374 
				C256.514740,240.519302 249.000793,243.154373 241.453445,245.690063 
				C236.532379,247.343414 234.214386,245.561218 234.881058,240.176498 
				C235.592834,234.427444 236.578201,228.671494 238.000900,223.059708 
				C240.942535,211.456619 243.388031,199.777557 244.209625,187.829651 
				C244.557236,182.774551 247.412781,179.892746 251.674973,178.448410 
				C261.872437,174.992752 271.956665,175.294342 281.462402,180.812042 
				C285.142517,182.948212 286.465027,186.597458 285.785583,190.666138 
				C284.146759,200.479507 282.650787,210.323929 280.048737,219.957687 
				C279.164093,223.233032 277.991455,226.258041 275.260742,228.871628 
				C272.641754,229.616989 271.359253,227.801239 269.775604,226.727600 
				C268.444946,225.825516 267.168060,225.351013 265.647064,226.264145 
				C264.043213,227.226990 264.246155,228.730270 264.353699,230.225922 
				C264.525726,232.617523 266.220917,234.978226 264.271423,237.667374 
			z"/>
			<path onClick={() => this.props.onFill(89)} fill={this.props.fillColors[89]} opacity="1.000000" stroke="black" 
				d="
			M259.970795,113.878647 
				C257.687225,109.944702 257.035645,106.421448 260.140381,102.973442 
				C263.282288,99.484184 267.133484,99.163284 271.075989,100.929398 
				C281.681000,105.680107 292.250153,110.521637 302.700775,115.600388 
				C306.578278,117.484779 306.830383,120.902374 303.510315,123.583244 
				C298.731354,127.442131 293.856659,131.205978 288.812347,134.707138 
				C281.731842,139.621597 275.448456,138.433609 270.561523,131.174164 
				C266.860596,125.676567 263.601471,119.881592 259.970795,113.878647 
			z"/>
			<path onClick={() => this.props.onFill(90)} fill={this.props.fillColors[90]} opacity="1.000000" stroke="black" 
				d="
			M290.191040,42.384022 
				C293.832703,42.116840 297.631531,42.821480 301.028473,44.313229 
				C305.623657,46.331154 309.735016,44.759033 314.016571,43.767990 
				C318.306671,42.774971 319.131561,39.296284 319.933838,35.339691 
				C327.555786,37.896927 330.649261,46.217258 329.726807,52.502663 
				C328.416779,61.429306 325.031006,69.808250 317.100342,75.429184 
				C308.571472,81.474106 299.947784,78.126030 298.062195,67.860451 
				C297.223602,63.294907 296.766479,58.659176 295.923096,54.094681 
				C295.131500,49.810699 293.864685,45.673714 290.191040,42.384022 
			z"/>
			<path onClick={() => this.props.onFill(91)} fill={this.props.fillColors[91]} opacity="1.000000" stroke="black" 
				d="
			M122.632736,175.602646 
				C119.876404,179.802383 117.161209,183.534363 113.775650,186.817108 
				C110.500267,189.993011 107.250313,190.028137 104.035126,187.298447 
				C98.804924,182.857971 95.218178,177.152527 94.250687,170.382507 
				C93.550179,165.480682 96.728920,161.738861 100.674957,159.309143 
				C108.863213,154.267349 118.047791,151.785324 127.462318,150.368668 
				C132.482178,149.613312 134.473785,152.251541 132.401947,156.691360 
				C129.453857,163.008942 126.809059,169.503815 122.632736,175.602646 
			z"/>
			<path onClick={() => this.props.onFill(92)} fill={this.props.fillColors[92]} opacity="1.000000" stroke="black" 
				d="
			M208.355377,182.859955 
				C206.751938,179.927765 204.237564,177.737503 202.974762,174.627838 
				C201.003601,169.773926 201.023514,165.045853 202.500076,160.205551 
				C203.066803,158.347870 204.213715,157.508209 206.470856,157.294754 
				C214.716537,156.514938 219.108154,159.526825 222.423447,168.850922 
				C224.209167,173.873230 224.526733,178.747711 221.025360,183.603851 
				C216.779129,184.469162 212.731491,183.562424 208.355377,182.859955 
			z"/>
			<path onClick={() => this.props.onFill(93)} fill={this.props.fillColors[93]} opacity="1.000000" stroke="black" 
				d="
			M264.397888,237.898026 
				C264.367920,234.897400 261.916260,232.596893 262.003723,229.468781 
				C262.059448,227.475449 260.424438,225.790909 263.601776,224.489014 
				C266.400665,223.342163 268.738983,221.852539 271.226807,224.668976 
				C272.385010,225.980179 273.465759,227.359818 274.774658,228.883423 
				C273.395477,234.120071 269.297424,236.253647 264.397888,237.898026 
			z"/>
			<path onClick={() => this.props.onFill(94)} fill={this.props.fillColors[94]} opacity="1.000000" stroke="black" 
				d="
			M740.342407,435.223785 
				C737.950439,428.167603 736.901917,420.829956 731.071411,415.300812 
				C726.975891,409.794434 720.968201,407.458527 715.730469,404.075134 
				C714.347168,403.181580 712.859619,402.426117 711.222168,401.263306 
				C710.958008,401.030701 710.991272,400.994720 710.988892,401.018982 
				C710.633667,400.718231 710.227051,400.487671 709.446716,400.190979 
				C698.626770,395.513000 693.040344,386.834717 688.890503,376.714325 
				C685.845337,369.288025 681.403076,362.711487 674.864624,357.776794 
				C670.879272,354.769012 667.342285,351.279327 664.081787,347.513641 
				C662.230774,345.375854 660.518799,343.126526 660.077332,339.822388 
				C658.058472,334.783783 659.641968,330.019257 659.718628,325.296722 
				C659.808838,319.739929 660.682922,314.212769 658.982117,308.671753 
				C657.989502,305.437927 656.148499,302.764709 654.418091,299.533386 
				C653.946350,298.776306 653.520447,298.357666 653.511597,297.891083 
				C653.321289,287.824371 652.107971,277.578461 658.673340,268.268494 
				C658.935730,267.939941 658.983704,267.942261 658.963989,267.954926 
				C659.437500,267.582642 659.488220,267.004822 659.756897,266.215820 
				C660.767151,264.755219 661.624023,263.533844 662.727417,262.109283 
				C663.482910,261.543915 663.483093,260.943512 663.852783,260.219055 
				C664.039917,260.009583 663.992188,260.014923 664.010498,260.030457 
				C671.058411,254.173340 677.921997,255.370148 682.548950,263.284332 
				C685.804016,268.851959 687.148987,275.027283 687.987366,281.300873 
				C689.317322,291.253204 694.524414,298.007690 703.734436,302.041199 
				C713.933472,306.507904 724.821411,309.269043 734.619751,314.710724 
				C745.506348,320.756744 756.306824,326.941772 767.883911,331.681122 
				C777.539124,335.633667 780.032288,345.209625 783.978271,353.551910 
				C784.261841,354.151398 784.051025,353.387482 784.097290,354.030701 
				C784.835510,364.290314 791.340576,366.939819 800.384216,366.855652 
				C808.478516,366.780273 816.092773,369.312500 823.551697,372.240387 
				C826.593201,373.434235 829.007446,375.383179 830.715393,378.668701 
				C835.321350,384.461334 834.807678,390.670807 832.562500,396.492157 
				C826.360657,412.572571 819.925293,428.638153 808.791931,442.119843 
				C806.003052,445.496979 802.032715,447.898468 798.317932,450.931366 
				C788.429932,453.476013 779.843018,449.956390 771.138672,446.685516 
				C765.547668,444.584595 759.565613,443.927948 753.652222,443.340973 
				C747.946472,442.774628 743.631104,440.242767 740.342407,435.223785 
			z"/>
			<path onClick={() => this.props.onFill(95)} fill={this.props.fillColors[95]} opacity="1.000000" stroke="black" 
				d="
			M658.598633,267.997131 
				C656.755249,276.329590 655.564087,284.853149 655.260376,293.520386 
				C655.202393,295.174683 654.942749,296.771851 655.790527,298.684814 
				C655.502441,301.053497 653.907104,301.902557 652.340271,302.915924 
				C645.499329,307.340485 638.499023,311.540161 631.155151,315.008606 
				C616.242188,322.051849 616.446411,344.880310 628.048523,353.307220 
				C634.681335,358.124786 639.150269,357.457397 643.064392,350.358063 
				C644.851685,347.116394 647.129578,344.220703 649.737610,341.719727 
				C652.898560,338.688568 656.565308,336.218689 661.637817,338.777985 
				C662.883850,345.096405 667.536072,348.827637 671.760437,352.196411 
				C680.749939,359.365265 687.955872,367.536743 692.033875,378.438293 
				C695.384338,387.395264 701.750488,394.049835 709.762817,399.652191 
				C709.660522,400.297607 709.232910,400.485260 708.383301,400.263245 
				C696.071106,395.309662 684.375732,397.314880 672.551208,401.105286 
				C660.211853,405.060730 647.668152,408.152740 634.506470,408.073456 
				C626.268066,408.023804 618.025269,408.690063 609.378479,409.062561 
				C602.327148,405.442566 600.434021,399.544617 600.974854,392.422577 
				C601.136658,390.291809 600.997437,388.138153 600.993286,385.537109 
				C606.260071,378.487793 605.497253,370.982910 603.613953,363.672180 
				C599.089844,346.110352 601.222229,328.762177 604.359863,311.350159 
				C606.087708,301.761963 612.675171,296.595673 620.715149,292.713806 
				C633.731384,286.429169 646.952942,280.495453 656.561523,269.051025 
				C656.959412,268.577118 657.687317,268.380249 658.598633,267.997131 
			z"/>
			<path onClick={() => this.props.onFill(96)} fill={this.props.fillColors[96]} opacity="1.000000" stroke="black" 
				d="
			M830.911621,378.632446 
				C821.951599,372.713470 811.809753,369.933563 801.081665,369.045258 
				C798.146729,368.802246 795.215515,368.452026 792.246765,368.350800 
				C788.077881,368.208710 784.646179,366.317719 783.854919,361.393036 
				C782.617188,353.690216 778.482971,347.127411 775.112488,340.271606 
				C773.181946,336.344788 769.331543,334.432983 765.797791,333.131592 
				C753.534912,328.615570 742.715210,321.393402 731.201782,315.499908 
				C722.851074,311.225403 713.942383,308.499756 705.242188,305.248871 
				C692.495483,300.486053 686.280823,291.326233 685.146851,278.251251 
				C684.867126,275.026306 683.921204,272.050415 682.851746,269.096558 
				C679.112244,258.768066 675.161987,256.829956 664.416260,260.042236 
				C664.833313,255.738037 667.876099,253.113007 671.719299,252.525604 
				C675.982483,251.874008 679.770447,254.126175 682.620728,257.420563 
				C687.841614,263.454834 690.565063,270.770874 692.835083,278.249298 
				C697.631775,294.051483 708.597717,302.961975 725.061218,305.123779 
				C739.677612,307.043060 754.532654,306.489716 769.016846,309.612946 
				C772.414185,310.345551 775.832458,310.986542 779.252686,311.606018 
				C787.655273,313.127930 792.339966,319.325836 794.653748,326.383667 
				C800.082825,342.944305 811.494812,354.682648 823.790771,365.991638 
				C827.384155,369.296692 829.962585,373.346924 830.911621,378.632446 
			z"/>
			<path onClick={() => this.props.onFill(97)} fill={this.props.fillColors[97]} opacity="1.000000" stroke="black" 
				d="
			M508.320770,249.552612 
				C502.198853,248.154770 502.992065,242.672150 501.349854,238.821243 
				C500.758148,237.433716 502.074585,236.185562 503.581116,236.190033 
				C506.223785,236.197861 508.960907,236.261276 510.923737,238.514359 
				C513.123169,241.039062 511.990540,244.492493 513.142212,247.356888 
				C513.224487,247.561417 510.767303,249.928833 508.320770,249.552612 
			z"/>
			<path onClick={() => this.props.onFill(98)} fill={this.props.fillColors[98]} opacity="1.000000" stroke="black" 
				d="
			M659.705811,266.175690 
				C659.739136,266.518524 659.567932,267.060486 659.170532,267.785034 
				C659.129944,267.436646 659.315552,266.905731 659.705811,266.175690 
			z"/>
			<path onClick={() => this.props.onFill(99)} fill={this.props.fillColors[99]} opacity="1.000000" stroke="black" 
				d="
			M663.813965,260.180878 
				C663.845886,260.537567 663.651794,261.065552 663.215820,261.749817 
				C663.178589,261.388123 663.383240,260.870148 663.813965,260.180878 
			z"/>
			<path onClick={() => this.props.onFill(100)} fill={this.props.fillColors[100]} opacity="1.000000" stroke="black" 
				d="
			M96.952393,335.544312 
				C107.929283,334.216797 116.233505,339.227295 123.876556,345.576538 
				C126.670334,347.897369 127.461922,351.393372 126.941826,355.120056 
				C125.602043,364.720367 115.997025,373.400848 106.284492,372.910645 
				C95.182655,372.350342 84.731682,363.442322 87.192131,350.253876 
				C88.330460,344.152252 89.526657,338.018860 96.952393,335.544312 
			z"/>
			<path onClick={() => this.props.onFill(101)} fill={this.props.fillColors[101]} opacity="1.000000" stroke="black" 
				d="
			M1020.087769,724.918945 
				C1015.662048,735.412598 1000.799377,741.131653 992.505249,736.105896 
				C988.664246,733.778503 987.122131,730.216858 987.392090,726.484070 
				C988.481323,711.423096 991.742126,696.879150 1000.343628,684.090942 
				C1005.446106,676.504761 1011.939026,676.360657 1015.425964,684.488342 
				C1020.431396,696.155701 1022.837341,708.614075 1021.783569,721.474731 
				C1021.696167,722.540833 1020.792725,723.540039 1020.087769,724.918945 
			z"/>
			<path onClick={() => this.props.onFill(102)} fill={this.props.fillColors[102]} opacity="1.000000" stroke="black" 
				d="
			M860.536255,44.367271 
				C864.056824,45.663345 866.087830,47.851303 867.196777,51.070053 
				C868.344299,54.400818 870.791199,57.042503 872.424561,60.082542 
				C875.276428,65.390282 878.014771,70.868935 877.999207,77.171944 
				C877.994934,78.911880 878.277832,80.018120 875.392151,79.648880 
				C871.844299,79.194893 869.293884,83.112129 865.455322,83.369736 
				C855.249573,84.054626 845.254761,83.273125 835.837524,79.099899 
				C831.890930,77.351013 830.727661,73.341637 831.231445,69.380371 
				C832.403137,60.166168 837.058228,52.512665 844.538696,47.396896 
				C848.859009,44.442287 854.489502,42.897728 860.536255,44.367271 
			z"/>
			<path onClick={() => this.props.onFill(103)} fill={this.props.fillColors[103]} opacity="1.000000" stroke="black" 
				d="
			M848.586182,109.029221 
				C857.710022,113.491608 862.129272,121.800476 859.877502,129.646301 
				C858.371582,134.893707 854.805237,137.838440 849.568481,136.855225 
				C841.178711,135.280060 833.816345,131.673782 829.752441,123.466606 
				C826.099426,116.089165 829.634277,109.223358 837.868347,108.157005 
				C841.288147,107.714111 844.843323,107.538536 848.586182,109.029221 
			z"/>
			<path onClick={() => this.props.onFill(104)} fill={this.props.fillColors[104]} opacity="1.000000" stroke="black" 
				d="
			M885.707947,80.578583 
				C885.865479,81.679794 885.865479,82.593193 885.865479,84.591011 
				C884.916321,82.485229 884.620483,81.454193 885.707947,80.578583 
			z"/>
			<path onClick={() => this.props.onFill(105)} fill={this.props.fillColors[105]} opacity="1.000000" stroke="black" 
				d="
			M1146.417969,458.361755 
				C1147.070312,458.423065 1147.397827,458.472900 1147.727295,458.499481 
				C1150.589355,458.730286 1152.743774,460.220062 1154.216919,462.549438 
				C1155.020508,463.819946 1153.732422,464.613068 1152.912476,465.403107 
				C1150.328369,467.893066 1147.091309,467.330353 1144.053223,467.039276 
				C1142.714355,466.911011 1142.122314,465.345795 1142.080444,463.914001 
				C1141.996704,461.042511 1143.515625,459.304504 1146.417969,458.361755 
			z"/>
			<path onClick={() => this.props.onFill(106)} fill={this.props.fillColors[106]} opacity="1.000000" stroke="black" 
				d="
			M800.041870,48.850624 
				C792.692810,59.401814 784.878540,57.404556 775.134216,51.356388 
				C771.762024,49.263340 768.780151,46.545956 766.059692,43.579609 
				C759.543152,36.474087 760.713013,28.584211 769.364990,24.562737 
				C776.587036,21.205948 784.444092,20.324579 792.314392,21.201147 
				C798.810974,21.924711 802.838135,27.016102 803.013062,33.539272 
				C803.152710,38.746262 803.114685,43.892162 800.041870,48.850624 
			z"/>
			<path onClick={() => this.props.onFill(107)} fill={this.props.fillColors[107]} opacity="1.000000" stroke="black" 
				d="
			M559.320068,420.578156 
				C557.530762,433.841949 554.819214,446.490906 550.741211,458.771576 
				C548.003906,467.014862 533.600281,469.340149 525.698242,463.077484 
				C516.353943,455.671722 513.333069,445.737610 513.661377,434.105682 
				C513.928589,424.638214 515.488647,415.670502 519.876099,407.234772 
				C522.209595,402.748230 525.323608,398.889374 529.034119,395.518280 
				C533.398682,391.553009 538.956482,390.473022 543.925293,392.765533 
				C552.046570,396.512604 558.066528,402.746307 558.880127,412.308502 
				C559.101074,414.905762 560.337097,417.483582 559.320068,420.578156 
			z"/>
			<path onClick={() => this.props.onFill(108)} fill={this.props.fillColors[108]} opacity="1.000000" stroke="black" 
				d="
			M636.677002,599.272095 
				C637.156433,607.784302 635.466248,616.099670 631.113037,623.808777 
				C629.388550,626.862793 626.948425,628.163269 623.360474,628.057434 
				C616.225830,627.846985 609.081238,627.974670 601.474243,627.931763 
				C610.677917,624.703979 619.787598,620.578735 627.368225,613.462097 
				C631.585571,609.502869 634.692932,605.006775 636.677002,599.272095 
			z"/>
			<path onClick={() => this.props.onFill(109)} fill={this.props.fillColors[109]} opacity="1.000000" stroke="black" 
				d="
			M646.874634,541.946899 
				C646.650818,542.494019 646.094360,543.086426 645.256897,543.790344 
				C645.168213,542.994995 645.572632,542.261963 646.874634,541.946899 
			z"/>
			<path onClick={() => this.props.onFill(110)} fill={this.props.fillColors[110]} opacity="1.000000" stroke="black" 
				d="
			M711.003662,401.376526 
				C718.982422,403.375793 726.087769,407.096527 730.873535,414.664673 
				C724.399841,410.400024 716.645447,407.673248 711.003662,401.376526 
			z"/>
			<path onClick={() => this.props.onFill(111)} fill={this.props.fillColors[111]} opacity="1.000000" stroke="black" 
				d="
			M708.282715,400.303131 
				C708.488525,400.039734 708.918152,400.003326 709.669067,399.927185 
				C710.241272,400.121155 710.492188,400.354889 710.864807,400.815979 
				C710.159851,400.872223 709.333191,400.701172 708.282715,400.303131 
			z"/>
			<path onClick={() => this.props.onFill(112)} fill={this.props.fillColors[112]} opacity="1.000000" stroke="black" 
				d="
			M829.751282,602.781067 
				C831.353821,606.294312 832.718689,609.594055 833.081299,613.612244 
				C830.425598,622.424500 828.006897,630.921021 825.280273,639.688477 
				C816.223816,650.456238 800.832214,648.700500 793.785034,636.528625 
				C787.611389,625.865601 788.872437,615.330078 794.019043,604.409668 
				C801.208984,594.491516 810.818542,593.336487 821.473389,596.084412 
				C824.149292,596.774597 826.892212,597.688965 828.673157,600.730103 
				C828.940796,601.058167 828.992920,601.048767 828.969116,601.037598 
				C829.432312,601.415100 829.488037,601.990112 829.751282,602.781067 
			z"/>
			<path onClick={() => this.props.onFill(113)} fill={this.props.fillColors[113]} opacity="1.000000" stroke="black" 
				d="
			M828.953369,600.667236 
				C821.505310,598.541260 814.253235,595.366882 806.006348,597.900696 
				C801.785522,599.197510 798.602661,602.086548 794.428223,603.975098 
				C798.628540,591.849731 804.929626,587.250549 816.054077,588.019165 
				C822.593689,588.471008 827.988281,593.592041 828.953369,600.667236 
			z"/>
			<path onClick={() => this.props.onFill(114)} fill={this.props.fillColors[114]} opacity="1.000000" stroke="black" 
				d="
			M825.407104,639.853821 
				C824.814209,630.681274 826.501648,621.960083 832.944214,614.316223 
				C837.611389,623.912842 834.617981,634.288025 825.407104,639.853821 
			z"/>
			<path onClick={() => this.props.onFill(115)} fill={this.props.fillColors[115]} opacity="1.000000" stroke="black" 
				d="
			M829.885254,602.730286 
				C829.596130,602.548462 829.289734,602.075623 828.964355,601.314636 
				C829.252930,601.497375 829.560486,601.968323 829.885254,602.730286 
			z"/>
			<path onClick={() => this.props.onFill(116)} fill={this.props.fillColors[116]} opacity="1.000000" stroke="black" 
				d="
			M302.793152,325.821259 
				C299.097137,329.174347 294.775665,330.590942 290.584595,332.140350 
				C279.984558,336.059235 271.053467,342.553650 262.094757,349.275269 
				C242.794174,363.756256 222.476059,376.230682 197.248215,377.089935 
				C195.109894,377.162750 192.984375,377.883911 190.854630,377.875305 
				C187.278763,377.860870 185.807358,376.079407 187.869263,372.795105 
				C195.543640,360.571045 205.082977,350.460144 219.116547,345.429596 
				C227.209793,342.528412 235.889816,341.014862 242.777328,335.198242 
				C245.461472,332.931366 247.935379,330.565369 250.143936,327.824585 
				C261.447357,313.797211 274.937469,302.739502 292.911835,298.673157 
				C299.691223,297.139496 306.756897,297.031250 313.447357,300.180847 
				C317.426819,302.054230 318.487000,304.768341 316.596313,308.639526 
				C313.349945,315.286713 309.113464,321.163727 302.793152,325.821259 
			z"/>
			<path onClick={() => this.props.onFill(117)} fill={this.props.fillColors[117]} opacity="1.000000" stroke="black" 
				d="
			M296.440155,898.124756 
				C305.819916,902.144348 312.326843,908.935242 317.801361,916.802368 
				C319.806488,919.683716 320.174896,922.942017 318.317871,926.214600 
				C316.585785,929.266968 313.670105,928.982239 310.845306,928.997314 
				C299.753052,929.056641 288.518829,920.040222 286.293701,909.300598 
				C284.836304,902.266418 288.550262,897.985535 296.440155,898.124756 
			z"/>
			<path onClick={() => this.props.onFill(118)} fill={this.props.fillColors[118]} opacity="1.000000" stroke="black" 
				d="
			M960.432190,839.163818 
				C960.391541,842.490173 957.900513,844.175720 956.883728,846.576111 
				C956.314331,847.920349 955.458984,847.366516 954.462830,846.989380 
				C948.023132,844.551514 945.599426,830.798645 950.870422,826.385620 
				C954.086792,823.692871 957.404358,824.316284 959.391052,828.142334 
				C961.134033,831.498962 961.471924,835.088623 960.432190,839.163818 
			z"/>
			<path onClick={() => this.props.onFill(119)} fill={this.props.fillColors[119]} opacity="1.000000" stroke="black" 
				d="
			M1057.990723,988.012390 
				C1058.598511,988.174683 1059.212891,988.321411 1059.827393,988.468140 
				C1059.413574,988.673767 1058.999756,988.879395 1058.275879,989.030640 
				C1057.965820,988.976135 1057.995972,988.480042 1058.012207,988.231873 
				C1058.028442,987.983704 1057.997314,987.996826 1057.990723,988.012390 
			z"/>
			<path onClick={() => this.props.onFill(120)} fill={this.props.fillColors[120]} opacity="1.000000" stroke="black" 
				d="
			M1058.041504,989.040039 
				C1057.880371,989.355713 1057.694702,989.649292 1057.509033,989.942871 
				C1057.559326,989.699646 1057.609619,989.456421 1057.812744,989.094666 
				C1057.965820,988.976135 1058.016968,989.017944 1058.041504,989.040039 
			z"/>
			<path onClick={() => this.props.onFill(121)} fill={this.props.fillColors[121]} opacity="1.000000" stroke="black" 
				d="
			M712.150269,655.729980 
				C716.670227,680.440308 723.135071,704.320190 726.861206,728.748779 
				C727.233704,731.190430 727.107605,733.756775 726.850586,736.227600 
				C726.696045,737.714233 726.794922,739.672485 724.690186,740.231201 
				C722.681030,740.764526 721.049438,740.592041 719.324463,738.948303 
				C715.864807,735.651611 713.647339,731.535034 711.388916,727.519226 
				C701.942200,710.720703 692.303040,694.005859 683.607544,676.818604 
				C678.303528,666.334717 674.813354,654.948181 670.078491,644.155762 
				C667.157776,637.498474 663.304993,631.256592 660.094971,624.718018 
				C656.671326,617.744385 655.566956,610.060181 655.211792,602.501221 
				C654.806885,593.880493 653.389404,585.299255 653.965637,576.622437 
				C654.199463,573.101440 654.759216,569.813599 656.563477,566.663574 
				C661.388245,558.239807 667.880859,560.278870 672.752625,564.381897 
				C678.385620,569.126099 682.835205,574.879883 686.274902,581.487183 
				C695.341736,598.903503 703.997620,616.476990 708.374878,635.777039 
				C709.839539,642.234619 710.858948,648.793091 712.150269,655.729980 
			z"/>
			<path onClick={() => this.props.onFill(122)} fill={this.props.fillColors[122]} opacity="1.000000" stroke="black" 
				d="
			M580.801941,655.693604 
				C573.109131,662.028198 565.718994,668.162720 558.220947,674.162354 
				C548.559570,681.892944 541.076355,691.647766 533.037964,700.895142 
				C528.906677,705.647766 524.865356,710.503052 520.431274,714.961853 
				C514.985046,720.438477 508.978088,720.335632 503.394012,714.838074 
				C495.730133,707.293030 488.538422,699.269470 480.088196,692.534363 
				C476.852875,689.955750 473.712677,687.209045 470.834381,684.242676 
				C466.522491,679.798889 467.032898,675.676697 472.156372,672.303040 
				C475.900299,669.837708 479.907013,668.007812 484.272369,666.675598 
				C500.614624,661.688721 516.785095,656.224915 531.927063,648.086914 
				C538.380310,644.618530 545.320496,641.476318 552.593323,641.333374 
				C561.286255,641.162537 570.190002,641.664307 578.518433,644.860107 
				C585.241943,647.440002 585.648315,649.553650 580.801941,655.693604 
			z"/>
			<path onClick={() => this.props.onFill(123)} fill={this.props.fillColors[123]} opacity="1.000000" stroke="black" 
				d="
			M574.814453,919.081543 
				C577.422119,924.912354 572.953918,928.177979 570.048096,931.141663 
				C561.943848,939.407776 553.238525,947.105469 543.267151,953.182739 
				C537.944275,956.426819 532.219666,958.205994 526.008057,959.096252 
				C516.868408,960.406189 506.806458,948.652222 512.356934,937.373230 
				C517.399719,927.125916 526.606628,922.486633 537.180603,920.066711 
				C545.759338,918.103455 554.590149,918.133057 563.287415,917.080078 
				C567.122253,916.615723 570.846008,917.645752 574.814453,919.081543 
			z"/>
			<path onClick={() => this.props.onFill(124)} fill={this.props.fillColors[124]} opacity="1.000000" stroke="black" 
				d="
			M708.247681,793.654053 
				C709.616333,785.063538 708.042114,776.462891 705.983582,767.495667 
				C711.042358,775.452087 711.143616,784.293701 708.247681,793.654053 
			z"/>
			<path onClick={() => this.props.onFill(125)} fill={this.props.fillColors[125]} opacity="1.000000" stroke="black" 
				d="
			M692.883301,734.674866 
				C691.612915,734.272522 691.020264,733.132935 690.949951,731.349304 
				C692.063171,731.853210 692.581543,733.033508 692.883301,734.674866 
			z"/>
			<path onClick={() => this.props.onFill(126)} fill={this.props.fillColors[126]} opacity="1.000000" stroke="black" 
				d="
			M677.299316,831.022827 
				C677.456909,830.720520 677.904175,830.401184 678.628418,830.027954 
				C678.466553,830.317932 678.027771,830.661804 677.299316,831.022827 
			z"/>
			<path onClick={() => this.props.onFill(127)} fill={this.props.fillColors[127]} opacity="1.000000" stroke="black" 
				d="
			M681.291260,827.885010 
				C681.472900,827.594910 681.944458,827.291077 682.703918,826.974243 
				C682.521606,827.264648 682.051453,827.567993 681.291260,827.885010 
			z"/>
			<path onClick={() => this.props.onFill(128)} fill={this.props.fillColors[128]} opacity="1.000000" stroke="black" 
				d="
			M683.188477,826.811646 
				C683.133179,826.457214 683.305176,825.922546 683.688110,825.195312 
				C683.737976,825.545593 683.576843,826.088562 683.188477,826.811646 
			z"/>
			<path onClick={() => this.props.onFill(129)} fill={this.props.fillColors[129]} opacity="1.000000" stroke="black" 
				d="
			M655.963013,726.328369 
				C662.910767,726.889099 667.021729,731.839722 670.776123,736.532471 
				C680.935852,749.231384 687.267456,763.850464 689.822876,779.916016 
				C690.930054,786.876831 687.778931,793.041321 683.837585,798.492493 
				C676.714966,808.343384 666.993347,815.405212 656.943909,821.935059 
				C648.449036,827.454956 639.806763,832.807434 629.879089,835.520630 
				C628.926941,835.780823 627.916626,835.828491 626.541260,835.972778 
				C624.556091,828.342407 628.986877,822.210815 631.758423,815.927917 
				C638.462097,800.731140 640.714966,784.670288 643.140076,768.487000 
				C645.310059,754.006165 648.289734,739.587952 655.963013,726.328369 
			z"/>
			<path onClick={() => this.props.onFill(130)} fill={this.props.fillColors[130]} opacity="1.000000" stroke="black" 
				d="
			M305.651855,475.320496 
				C305.253021,475.705566 304.711090,475.965515 304.145721,476.155792 
				C304.088715,476.174957 303.890015,475.773102 303.757080,475.566650 
				C304.338776,475.436310 304.920441,475.305969 305.651855,475.320496 
			z"/>
			<path onClick={() => this.props.onFill(131)} fill={this.props.fillColors[131]} opacity="1.000000" stroke="black" 
				d="
			M199.671249,102.019661 
				C204.619400,103.335068 204.544617,105.812157 201.897369,108.990143 
				C193.653381,118.886902 182.435333,123.557419 170.401947,126.576233 
				C166.705414,127.503586 160.932968,122.815918 160.335922,119.170540 
				C160.100983,117.736145 161.220200,117.193291 161.928940,116.308113 
				C167.716797,109.079422 175.733078,105.732796 184.260803,103.091492 
				C189.232376,101.551643 194.093918,100.070160 199.671249,102.019661 
			z"/>
			<path onClick={() => this.props.onFill(132)} fill={this.props.fillColors[132]} opacity="1.000000" stroke="black" 
				d="
			M661.798706,338.639282 
				C653.316833,338.826355 648.740967,344.627747 645.232056,351.123108 
				C640.711548,359.490875 634.866455,361.007812 627.088318,355.470551 
				C613.214661,345.593903 614.025940,319.598114 630.901062,312.713440 
				C639.468445,309.218170 646.746399,303.055115 655.602051,299.179474 
				C660.965210,304.145325 662.225342,310.384857 662.271545,317.382782 
				C662.317749,324.374786 660.613220,331.296753 661.798706,338.639282 
			z"/>
			<path onClick={() => this.props.onFill(133)} fill={this.props.fillColors[133]} opacity="1.000000" stroke="black" 
				d="
			M799.641602,420.067566 
				C800.422241,422.015015 801.044189,423.836182 798.731689,424.750763 
				C797.670471,425.170502 795.961853,425.082916 795.916260,423.808960 
				C795.841248,421.711823 797.608887,420.602173 799.641602,420.067566 
			z"/>
			</svg>
		)
	}
}